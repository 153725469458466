import {
    useState, useEffect
} from 'react'
import base64 from 'react-native-base64'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker2';
import Usuario from '../models/wirecard/usuario'
import { criaUsuario, getUsuarios } from '../service/wirecard'
import { buscaEndereco } from '../service/WsConect'
import { cpf } from 'cpf-cnpj-validator';

export default function Evento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes")
    })
    const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])

    useEffect(() => {
        servicos.equipes.onSnapshot((snap) => {
            const data = snap.docs.map((doc) => doc.data());
            setEquipes(data)
        })
    }, [])

    const [dataAtual] = useState(moment().format('YYYYMMDD'))
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [numeroCpf, setCpf] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState(moment())
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    const [tipoUsuario, setTipoUsuario] = useState('')
    const [equipe, setEquipe] = useState('')
    const [professor, setProfessor] = useState('')
    const [inputEquipe, setInputEquipe] = useState('')
    const [inputProfessor, setInputProfessor] = useState('')

    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')
    const [dica, setDica] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')
    const [cadastro, setCadastro] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)

    function cadastraAvatar(id) {
        Swal.close();
        mensagemLoading('Salvando imagem do seu avatar')
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('usuarios/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    setCadastro(true)
                    mensagemSucesso('Você foi cadastrado com sucesso! Você será enviado para página de administração')
                })
            });
        });
    }

    function cadastraUsuario() {
        setLoading(true)
        if (avatar === '') {
            mensagemAlerta('Favor esolha seu avatar')
            setLoading(false)
        } else if (nome === '') {
            mensagemAlerta('Favor digitar seu primeiro nome')
            setLoading(false)
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar seu sobrenome')
            setLoading(false)
        } else if (numeroCpf === '' || numeroCpf.length < 11) {
            mensagemAlerta('CPF vazio ou incompleto')
            setLoading(false)
        } else if (moment(dataNascimento).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
            mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
            setLoading(false)
        } else if (ddd === '' || ddd.length < 2) {
            mensagemAlerta('DDD vazio ou incompleto')
            setLoading(false)
        } else if (celular === '' || celular.length < 9) {
            mensagemAlerta('Celular vazio ou incompleto! Não esqueça de acrescentar o 9 no começo do número')
            setLoading(false)
        } else if (email === '') {
            mensagemAlerta('Favor digite o seu melhor email. Ele será importante para seu login e receber todas as notificações do evento')
            setLoading(false)
        } else if (sexo === '') {
            mensagemAlerta('Favor escolha seu sexo')
            setLoading(false)
        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
            setLoading(false)
        } else if (numero === '') {
            mensagemAlerta('Favor digite o N°')
            setLoading(false)
        } else if (tipoUsuario === '') {
            mensagemAlerta('Favor configure os dados de equipe / responsável')
            setLoading(false)
        } else if (senha === '' || senha.length < 8) {
            mensagemAlerta('Senha vazia ou não atende os termos! Favor digite uma senha forte e no mínimo 8 dígitos')
            setLoading(false)
        } else if (confirmaSenha === '') {
            mensagemAlerta('Confirme sua senha')
            setLoading(false)
        } else if (senha !== confirmaSenha) {
            mensagemAlerta('As senhas não são iguais. Favor tente novamente')
            setLoading(false)
        } else if (dica === '') {
            mensagemAlerta('Digite a dica da senha para ser melhor lembrado')
            setLoading(false)
        } else {
            if (tipoUsuario === 'competidor' || tipoUsuario === 'professor') {
                if (equipe === '0') {
                    if (inputEquipe === '' || inputProfessor === '') {
                        mensagemAlerta('Favor diga o nome de sua equipe e o professor que o acompanha! Os dados serão salvos no sistema')
                    } else {
                        mensagemLoading('Cadastrando equipe e professor...')
                        servicos.equipes.add({
                            equipe: inputEquipe,
                            dataCadastro: moment().format('DD/MM/YYYY'),
                            dataAtualizacao: moment().format('DD/MM/YYYY'),
                            status: parseInt(1),
                            urlImagem: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
                        }).then((docRef) => {
                            setEquipe(docRef.id)
                            servicos.equipes.doc(docRef.id).update({
                                id: docRef.id
                            }).then(() => {
                                servicos.equipes.doc(docRef.id).collection('professores').add({
                                    professor: inputProfessor
                                }).then((refProfessor) => {
                                    setProfessor(refProfessor.id)
                                    servicos.equipes.doc(docRef.id).collection('professores').doc(refProfessor.id).update({
                                        id: refProfessor.id
                                    }).then(() => {
                                        Swal.close()
                                        cadastraEmail(docRef.id, refProfessor.id)
                                    }).catch(() => {
                                        console.log('Erro ao atualizar professor')
                                    })
                                }).catch(() => {
                                    console.log('Erro ao cadastrar professor')
                                })
                            }).catch(() => {
                                console.log('Erro ao atualizar equipe')
                            })
                        }).catch(() => {
                            console.log('Erro ao cadastrar Equipe')
                        })
                    }
                } else if (professor === '0') {
                    if (inputProfessor === '') {
                        mensagemAlerta('Favor diga o nome do seu professor! Caso você seja o professor é só digitar seu nome')
                    } else {
                        mensagemLoading('Cadastrando professor...')
                        servicos.equipes.doc(equipe).collection('professores').add({
                            professor: inputProfessor
                        }).then((refProfessor) => {
                            setProfessor(refProfessor.id)
                            servicos.equipes.doc(equipe).collection('professores').doc(refProfessor.id).update({
                                id: refProfessor.id
                            }).then(() => {
                                Swal.close()
                                cadastraEmail('', refProfessor.id)
                            }).catch(() => {
                                console.log('Erro ao atualizar professor')
                            })
                        }).catch(() => {
                            console.log('Erro ao cadastrar professor')
                        })
                    }
                } else {
                    if (equipe === '' || professor === '') {
                        mensagemAlerta('Favor selecione sua equipe e professor')
                    } else {
                        cadastraEmail('', '')
                    }
                }
            } else {
                cadastraEmail('', '')
            }

        }

    }

    function cadastraEmail(idEquipe, idProfessor) {
        mensagemLoading('Preparando seu cadastro...')
        servicos.auth.createUserWithEmailAndPassword(email, confirmaSenha).then((user) => {
            servicos.usuarios.doc(user.user.uid).set({
                id: user.user.uid,
                nome: nome,
                sobrenome: sobrenome,
                cpf: numeroCpf,
                ddd: ddd,
                celular: celular,
                email: email,
                biografica: '',
                dataNascimento: moment(dataNascimento).format('DD/MM/YYYY'),
                endereco: {
                    endereco: endereco,
                    bairro: bairro,
                    numero: numero,
                    complemento: complemento,
                    uf: uf,
                    cidade: cidade,
                    cep: cep
                },
                dica: dica,
                tipoUsuario: tipoUsuario,
                equipe: idEquipe ? idEquipe : equipe,
                professor: idProfessor ? idProfessor : professor,
                status: parseInt(1),
                sexo: sexo,
                pass: base64.encode(confirmaSenha + user.user.uid),
                dataCadastro: moment().format('DD/MM/YYYY HH:mm'),
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
                usuario: parseInt(2)
            }).then(() => {
                mensagemSucesso('Usuário cadastrado com sucesso! Aproveite o Procompetidor')
            }).catch(() => {
                mensagemErro('Erro ao cadastrar o evento')
            })
        }).catch((erro) => {
            var errorCode = erro.code;
            var errorMessage = erro.message;
            if (errorCode == "auth/invalid-email") {
                mensagemAlerta('O formato do Email é inválido!')
                setLoading(false)
            } else if (errorCode == "auth/email-already-in-use") {
                mensagemErro('O email já está em uso pelo Desapeguei')
                setLoading(false)
            } else if (errorCode == "auth/weak-password") {
                mensagemErro('Escolha uma senha mais forte!')
                setLoading(false)
            }
        })
    }



    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            if (cpf.isValid(event.target.value) === false) {
                mensagemAlerta('CPF incorreto! Verifique novamente')
            } else {
                mensagemLoading('Aguarde, verificando o seu CPF...')
                servicos.usuarios.where('cpf', '==', event.target.value).get().then((snap) => {
                    if (snap.size === 0) {
                        Swal.close();
                        mensagemSucesso('Seu CPF está disponível')
                    } else {
                        Swal.close();
                        mensagemAlerta('CPF já cadastrado no sistema! Verifique novamente ou faça login')
                    }
                })
            }
        }
    }
    const handleDataNascimmento = (event) => {
        event.persist();
        setDataNascimento(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleSexo = (event) => {
        event.persist();
        setSexo(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            buscaCep(event.target.value);
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleTipoUsuario = (event) => {
        setEquipe('')
        setProfessor('')
        setInputEquipe('')
        setInputProfessor('')
        event.persist();
        setTipoUsuario(event.target.value);
    }
    const handleEquipe = (event) => {
        setProfessor('')
        setInputEquipe('')
        setInputProfessor('')
        event.persist();
        setEquipe(event.target.value);
        if (event.target.value !== '') {
            mensagemLoading('Carregando professores..')
            servicos.equipes.doc(event.target.value).collection('professores').onSnapshot((snap) => {
                const data = snap.docs.map((doc) => doc.data());
                setProfessores(data)
                Swal.close();
            })
        }
    }
    const handleProfessor = (event) => {
        event.persist();
        setProfessor(event.target.value);
    }
    const handleInputEquipe = (event) => {
        event.persist();
        setInputEquipe(event.target.value);
    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value);
    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }
    const handleDica = (event) => {
        event.persist();
        setDica(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep(c) {
        mensagemLoading('Aguarde procurando CEP...')
        buscaEndereco(c).then((doc) => {
            if (doc.erro === true) {
                setLoadingCep(false)
                mensagemErro('Não achamos seu CEP! Verifique novamente ou digite manualmente')
            } else {
                Swal.close();
                setEndereco(doc.logradouro)
                setBairro(doc.bairro)
                setCidade(doc.localidade)
                setUf(doc.uf)
                setLoadingCep(false)
            }
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Novo cadastro</h1>
                            </div>
                            <div class="headline__description">
                                <span>Cadastre-se com o Procompetidor como competidor, responsável ou professor</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados Pessoais</h3>
                                                <p><b>Se você for responsável ou professor, cadastre suas credenciais para que possa cadastrar seus alunos ou filhos(as). A aba abaixo de Dados de responsavel você complementa com o seu tipo de usuário no sistema. Nesse tipo de usuário é destinado a competidores menores que 18 anos</b></p>
                                                <p><b>Caso seja um competidor acima de 18 anos, cadastre suas credenciais</b></p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="package-style-1 pnchr-package clearfix vc_col-sm-4">
                                                <div >
                                                    {upload ?
                                                        <img src={upload} alt="" title="image-here-1" />
                                                        :
                                                        <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" alt="" title="image-here-1" />
                                                    }
                                                </div>
                                                <input onChange={handleAvatar} accept=".png, .jpg, .jpeg" type="file" name="name" placeholder="Escolha a imagem " />
                                            </div>
                                            <div role="form" class="wpcf7 row " id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNome}
                                                            value={nome}
                                                            placeholder="Nome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sobrenome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleSobrenome}
                                                            value={sobrenome}
                                                            placeholder="Sobrenome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CPF</h6>
                                                        <input
                                                            max={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCpf}
                                                            value={numeroCpf}
                                                            placeholder="CPF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Data Nascimento</h6>
                                                        <input
                                                            type="date" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDataNascimmento}
                                                            value={dataNascimento} />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>DDD</h6>
                                                        <input
                                                            max={2}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDDD}
                                                            value={ddd}
                                                            maxLength={2}
                                                            placeholder="DDD *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Celular</h6>
                                                        <input
                                                            max={9}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCelular}
                                                            value={celular}
                                                            placeholder="9 + Celular *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sexo</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleSexo} >
                                                            <option value="">Escolha o Sexo </option>
                                                            <option value="masculino" >Masculino </option>
                                                            <option value="feminino" >Feminino </option>
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Email</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEmail}
                                                            value={email}
                                                            placeholder="Email *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <p>Guarde bem o seu email, ele será importante para receber as notificações e alterar sua senha</p>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Endereço</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CEP</h6>
                                                        <input
                                                            max={8}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCep}
                                                            value={cep}
                                                            placeholder="CEP *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Endereço</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEndereco}
                                                            value={endereco}
                                                            placeholder="Endereço *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Bairro</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleBairro}
                                                            value={bairro}
                                                            placeholder="Bairro *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Cidade</h6>
                                                        <input
                                                            disabled
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCidade}
                                                            value={cidade}
                                                            placeholder="Cidade *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Estado</h6>
                                                        <input
                                                            disabled
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleUf}
                                                            value={uf}
                                                            placeholder="UF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Complemento</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleComplemento}
                                                            value={complemento}
                                                            placeholder="Complemento *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>N°</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNumero}
                                                            value={numero}
                                                            placeholder="N° *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Equipe / Responsável</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Tipo de Usuário</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleTipoUsuario} >
                                                            <option value="">Escolha aqui o tipo </option>
                                                            <option value="competidor" >Competidor </option>
                                                            <option value="responsavel" >Responsável </option>
                                                            <option value="professor" >Professor </option>
                                                        </select>
                                                    </span>
                                                </p>
                                                {tipoUsuario === "professor" || tipoUsuario === "competidor" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Sua Equipe</h6>
                                                            <select name="select" id="select" class="form-control" onChange={handleEquipe} >
                                                                <option value="">Escolha a Equipe </option>
                                                                <option value="0" >Não está na lista </option>
                                                                {equipes.map((equipe) =>
                                                                    <option value={equipe.id} >{equipe.equipe}</option>
                                                                )}
                                                            </select>
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }
                                                {tipoUsuario === "competidor" && equipe !== "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Seu Professor</h6>
                                                            <select name="select" id="select" class="form-control" onChange={handleProfessor}>
                                                                <option value="">Escolha o Professor </option>
                                                                <option value="0" >Não está na lista </option>
                                                                {professores.map((professor) =>
                                                                    <option value={professor.id} >{professor.professor}</option>
                                                                )}
                                                            </select>
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }
                                                {equipe === "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Nome da Equipe</h6>
                                                            <input
                                                                type="text" name="your-name" size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false"
                                                                onChange={handleInputEquipe}
                                                                value={inputEquipe}
                                                                placeholder="Digite o nome da equipe *" />
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }
                                                {equipe === "0" || professor === "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Nome do(a) Professor(a)</h6>
                                                            <input
                                                                type="text" name="your-name" size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false"
                                                                onChange={handleInputProfessor}
                                                                value={inputProfessor}
                                                                placeholder="Digite o nome do professor *" />
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }

                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de acesso</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleSenha}
                                                            value={senha}
                                                            placeholder="Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Confirma Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleConfirmaSenha}
                                                            value={confirmaSenha}
                                                            placeholder="Confirma Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Uma dica para lembrar de sua senha</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDica}
                                                            value={dica}
                                                            placeholder="Dica de senha *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                    <input type="submit" onClick={() => cadastraUsuario()} value="Cadastrar" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
