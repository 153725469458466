import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'

export default function PaginaErro() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('external_reference'))
    const [idStatus] = useState(queryParams.get('collection_status'))
    const [idPagamento] = useState(queryParams.get('payment_id'))
    const [tipoPagamento] = useState(queryParams.get('payment_type'))
    const [idPedido] = useState(queryParams.get('preference_id'))

    const [inscricao, setIncricao] = useState({})
    const [status, setStatus] = useState()
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [pagamento, setPagamento] = useState({})

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                })
                servicos.inscricoes.doc(id).get().then((snap) => {
                    const idChecagem = snap.data().categoria.categoria.sexo + snap.data().categoria.categoria.id + snap.data().categoria.peso.id + snap.data().categoria.faixa.id
                    const idAbsoluto = snap.data().categoria.absoluto.id + snap.data().categoria.categoria.sexo
                    const idEquipe = snap.data().equipe.equipe
                    if (idPagamento) {
                        servicos.eventos.doc(snap.data().evento.id).collection('checagem').doc(idChecagem).get().then((doc) => {
                            servicos.eventos.doc(snap.data().evento.id).collection('checagem').doc(idChecagem).set({
                                id: idChecagem,
                                categoriaId: snap.data().categoria.categoria.id,
                                sexo: snap.data().categoria.categoria.sexo,
                                pesoId: snap.data().categoria.peso.id,
                                faixaId: snap.data().categoria.faixa.id,
                                filtro: [
                                    snap.data().categoria.categoria.id,
                                    snap.data().categoria.categoria.sexo,
                                    snap.data().categoria.peso.id,
                                    snap.data().categoria.faixa.id,
                                ],
                                nomeCategoria: snap.data().categoria.categoria.tipoIdade === 0 ? snap.data().categoria.categoria.categoria + " ( Até " + snap.data().categoria.categoria.idade + " anos )" : snap.data().categoria.categoria.tipoIdade === 1 ? snap.data().categoria.categoria.categoria + "( De " + snap.data().categoria.categoria.minIdade + " até " + snap.data().categoria.categoria.maxIdade + " anos )" : snap.data().categoria.categoria.categoria + "( Acima de " + snap.data().categoria.categoria.idade + " anos )",
                                nomePeso: snap.data().categoria.peso.tipoPeso === 0 ? snap.data().categoria.peso.nome + " ( Até " + snap.data().categoria.peso.peso + " Kg )" : snap.data().categoria.peso.nome + " ( Acima " + snap.data().categoria.peso.peso + " Kg )",
                                nomeFaixa: snap.data().categoria.faixa.faixa,
                                categoria: true
                            }).then(() => {
                                servicos.eventos.doc(snap.data().evento.id).collection('checagem').doc(idEquipe).set({
                                    id: idEquipe,
                                    idEquipe: snap.data().equipe.equipe,
                                    idProfessor: snap.data().equipe.professor,
                                    nomeEquipe: snap.data().equipe.nomeEquipe,
                                    nomeProfessor: snap.data().equipe.nomeProfessor,
                                    avatarEquipe: snap.data().equipe.avatar,
                                    equipe: true
                                }).then(() => {
                                    if (snap.data().categoria.absoluto.id) {
                                        servicos.eventos.doc(snap.data().evento.id).collection('checagem').doc(idAbsoluto).set({
                                            id: idAbsoluto,
                                            sexo: snap.data().categoria.categoria.sexo,
                                            nomeAbsoluto: snap.data().categoria.absoluto.absoluto,
                                            idAbsoluto: snap.data().categoria.absoluto.id,
                                            absoluto: true
                                        }).then(() => {
                                            servicos.inscricoes.doc(id).update({
                                                checagem: idChecagem,
                                            }).then(() => {
                                                servicos.eventos.doc(snap.data().evento.id).get().then((snap) => {
                                                    setEvento(snap.data())
                                                })
                                                setStatus(parseInt(snap.data().status))
                                                setIncricao(snap.data())
                                            })
                                        }).catch(() => {
                                            console.log('Erro')
                                        })
                                    } else {
                                        servicos.inscricoes.doc(id).update({
                                            checagem: idChecagem,
                                        }).then(() => {
                                            servicos.eventos.doc(snap.data().evento.id).get().then((snap) => {
                                                setEvento(snap.data())
                                            })
                                            setStatus(parseInt(snap.data().status))
                                            setIncricao(snap.data())
                                        })
                                    }
                                }).catch(() => {
                                    console.log('Erro')
                                })

                            }).catch(() => {
                                console.log('Erro')
                            })
                        })
                    } else {
                        servicos.eventos.doc(snap.data().evento.id).get().then((snap) => {
                            setEvento(snap.data())
                        })
                        setStatus(parseInt(snap.data().status))
                        setIncricao(snap.data())
                    }
                })
            }
        })
    }, [])


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class=" vc_col-sm-6">
                                            <div class="wpb_single_image wpb_content_element vc_align_left">
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={evento.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome} </h3>
                                                        <div class="package-style-1__text">
                                                            {evento.inicioEvento === evento.fimEvento ?
                                                                <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                                :
                                                                <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                            }
                                                            <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                        </div>
                                                    </div>
                                                </figure>
                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                    <h3>Categorias</h3>
                                                    {inscricao.categoria ?
                                                        <table style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr >
                                                                    <th class="schedule-table__day">Categoria</th>
                                                                    <th class="schedule-table__day">Peso</th>
                                                                    <th class="schedule-table__day">Faixa</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                    <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                    <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        null
                                                    }
                                                    {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                            <thead>
                                                                <tr >
                                                                    <th class="schedule-table__day">Absoluto</th>
                                                                    <th class="schedule-table__day">Idade</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                    <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div class=" vc_col-sm-6" >
                                            <div class="headline " style={{ textAlign: "center", backgroundColor: "red" }}>
                                                <i class="fa fa-exclamation-circle" aria-hidden="true" style={{ color: "white", fontSize: 150 }}></i>
                                                <h3 style={{ color: "white" }}>Erro no Pagamento</h3>
                                                <h4 style={{ color: "white" }}>Não foi possível realizar o pagamento! Por favor tente novamente</h4>
                                            </div>

                                            <table style={{ width: "100%", marginTop: 20 }}>
                                                <thead>
                                                    <tr >
                                                        <th class="schedule-table__day">Extrato</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ height: 50 }}>Valor da Categoria</td>
                                                        <td>R$ {inscricao.pagamento ? inscricao.pagamento.valorCategoria : null}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ height: 50 }}>Valor Absoluto</td>
                                                        <td>R$ {inscricao.pagamento ? inscricao.pagamento.valorAbsoluto : null}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ height: 50 }}><h6>Valor Total</h6></td>
                                                        <td><h6>R$ {inscricao.valorTotal ? inscricao.valorTotal : null}</h6></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table style={{ width: "100%", marginTop: 20 }}>
                                                <thead>
                                                    <tr >
                                                        <th class="schedule-table__day">Pagamento</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ height: 50 }}>Forma de Pagamento</td>
                                                        <td>{tipoPagamento === "credit_card" ? "Cartão de Crédito" : "BOLETO"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <a href="/" style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Ir para página inicial</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div>
    );
}
