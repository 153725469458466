import { useState, useEffect } from 'react'
import firebase from 'firebase'
// import moment from 'moment'
import logo from './Imagens/logo.png'
import profile from './Imagens/profile.jpg';
import './avatar.css'
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { Link } from '@material-ui/core';
// import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom';



const pagina = window.location.pathname;


export default function Header() {
    const [servicos] = useState({
        user: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        configuracoes: firebase.firestore().collection("configuracoes")

    })
    const [verificaUsuario, setVerificaUsuario] = useState(false)
    const [usuario, setUsuario] = useState({})
    const [menu, setMenu] = useState(false)
    // const [id] = useState(window.location.pathname.replace('/mudaEquipe/', ''))
    // const [configuracoes, setConfiguracoes] = useState({});


    useEffect(() => {
        let id = JSON.parse(localStorage.getItem('usuarioId'))
        servicos.user.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((snap) => {
                    setUsuario(snap.data())
                    // if (snap.data().usuario === 2) {
                    //     servicos.equipes.doc(snap.data().equipe).get().then((eq) => {
                    //         servicos.inscricoes
                    //             .where('usuario.id', '==', snap.data().id)
                    //             .get().then((us) => {
                    //                 if (us.size > 0) {
                    //                     us.forEach((d) => {
                    //                         servicos.eventos.doc(d.data().evento.id).get().then((e) => {
                    //                             if (e.data().status !== 1) {
                    //                                 servicos.inscricoes.doc(d.id).update({
                    //                                     // equipe: {
                    //                                     //     // avatar: eq.data().avatar ? eq.data().avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                    //                                     //     equipe: eq.data().id,
                    //                                     //     professor: snap.data().professor,
                    //                                     //     nomeEquipe: eq.data().equipe,
                    //                                     //     // nomeProfessor: dadosProfessor.professor
                    //                                     // }
                    //                                 })
                    //                             }
                    //                         })
                    //                     })
                    //                 } else {
                    //                     if (id && id.length < 20) {
                    //                         if (!snap.data().editaEquipe) {
                    //                             window.location = '/mudaEquipe/' + snap.data().id
                    //                         }
                    //                     }
                    //                 }
                    //             })
                    //     })
                    // }
                })
                setVerificaUsuario(true)
            } else if (id) {
                servicos.usuarios.doc(id).get().then((snap) => {
                    setUsuario(snap.data())
                    // if (snap.data().usuario === 2) {
                    //     servicos.equipes.doc(snap.data().equipe).get().then((eq) => {
                    //         servicos.inscricoes
                    //             .where('usuario.id', '==', snap.data().id)
                    //             .get().then((us) => {
                    //                 if (us.size > 0) {
                    //                     us.forEach((d) => {
                    //                         servicos.eventos.doc(d.data().evento.id).get().then((e) => {
                    //                             if (e.data().status !== 1) {
                    //                                 servicos.inscricoes.doc(d.id).update({
                    //                                     // equipe: {
                    //                                     //     // avatar: eq.data().avatar ? eq.data().avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                    //                                     //     equipe: eq.data().id,
                    //                                     //     professor: snap.data().professor,
                    //                                     //     nomeEquipe: eq.data().equipe,
                    //                                     //     // nomeProfessor: dadosProfessor.professor
                    //                                     // }
                    //                                 })
                    //                             }
                    //                         })
                    //                     })
                    //                 } else {
                    //                     if (id.length < 20) {
                    //                         if (!snap.data().editaEquipe) {
                    //                             window.location = '/mudaEquipe/' + snap.data().id
                    //                         }
                    //                     }
                    //                 }
                    //             })
                    //     })
                    // }
                })
                setVerificaUsuario(true)
            }
        })
        // servicos.configuracoes.get().then((snap =>{
        //     const config = snap.docs.map(c => c.data())
        //     if(config[0]){
        //         setConfiguracoes(config[0]);
        //     }
        // }))
    }, [])

    function logout() {
        servicos.user.signOut().then(() => {
            localStorage.setItem('usuarioId', null)
            window.location = '/'
        })
    }

    return (
        <header class="header">
            {/* <div class="header-search">
                <div class="section clearfix">
                    <form role="search" action="http://bagrov-dev.com/puncher-demo-1/" method="get"
                        class="clearfix header-search__form">
                        <i class="fa fa-search header-search__loupe-ico"></i>
                        <input type="text" placeholder="Search" name="s" autoComplete="off"
                            class="header-search__input" />
                        <div class="header-search__close">
                            <a href="#">
                                <i class="fa fa-times js-header-search-close header-search__close-ico"></i>
                            </a>
                        </div>
                    </form>
                </div>
            </div> */}
            {/* <div class="header-top ">
                <div class="section clearfix">
                    <div class="header-top-contact">
                        <ul>
                            <li class="header-top-contact__tel">
                                <i class="fa fa-phone" aria-hidden="true"></i>{formataNumero()} </li>
                            <li class="header-top-contact__hours">
                                <i class="fa fa-clock-o" aria-hidden="true"></i>Dia {moment().format('DD/MM/YYYY')} </li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div style={{ backgroundColor: "#333", width: "100%", height: menu ? 379 : 80 }} class="header-bottom ">
                <div class="section clearfix">
                    <div class="header-bottom__inner">
                        <div class="header-bottom__logo">
                            <a href="/" class="header-bottom__logo-link">
                                <img src={logo} style={{ height: 40, width: 250, color: "#fff", position: "relative", top: "-7px" }} alt="Procompetidor" />
                            </a>
                            <div class="header-bottom__mobile-icon js-toggle-mob-menu">
                                {/* <i class="fa fa-bars" onClick={() => setMenu(true)}></i> */}
                                {menu ?
                                    <div style={{ marginTop: '335px' }}>
                                        <div id="mobile-menu-item-267" style={{ marginTop: '10px', textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                            <a href="/" style={{ color: "white", fontSize: 15 }}>Início <i class="fa fa-angle-right"></i></a>
                                        </div>
                                        <div id="mobile-menu-item-267" style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                            <a href="/eventos?p=andamento" style={{ color: "white", fontSize: 15 }}>Eventos Andamento<i class="fa fa-angle-right"></i></a>
                                        </div>
                                        <div id="mobile-menu-item-267" style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                            <a href="/eventos?p=realizados" style={{ color: "white", fontSize: 15 }}>Eventos Realizados<i class="fa fa-angle-right"></i></a>
                                        </div>
                                        <div id="mobile-menu-item-267" style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                            <a href="/federacoes" style={{ color: "white", fontSize: 15 }}>Filiações <i class="fa fa-angle-right"></i></a>
                                        </div>
                                        {/* <div id="mobile-menu-item-267" style={{ marginTop: 10, textAlign: "start" }}>
                                            <a href="/ranking" style={{ color: "white", fontSize: 15 }}>Ranking <i class="fa fa-angle-right"></i></a>
                                        </div> */}
                                        <div id="mobile-menu-item-267" style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                            <a href="/contato" style={{ color: "white", fontSize: 15 }}>Contato <i class="fa fa-angle-right"></i></a>
                                        </div>
                                        <div id="mobile-menu-item-267" style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                            <a href="/sobre" style={{ color: "white", fontSize: 15 }}>Sobre <i class="fa fa-angle-right"></i></a>
                                        </div>
                                        {verificaUsuario ?
                                            <>
                                                <div style={{ marginTop: 20, textAlign: "start" }}>
                                                    <a href="javascript:void(0)" style={{ color: "red", fontSize: 15 }}>Olá, {usuario.nome}</a>
                                                </div>
                                                <div style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                                    {usuario.usuario === 3 ?
                                                        <a href="/perfilResponsavel" style={{ color: "white", fontSize: 15 }}>Meu Perfil <i class="fa fa-angle-right"></i></a>
                                                        :
                                                        <a href="/perfil" style={{ color: "white", fontSize: 15 }}>Meu Perfil <i class="fa fa-angle-right"></i></a>
                                                    }
                                                </div>
                                                <div onClick={() => logout()} style={{ marginTop: 10, textAlign: "start", borderBottom: "#fff dotted 1px", paddingBottom: '10px' }}>
                                                    <a href="javascript:void(0)" style={{ color: "white", fontSize: 15 }}>Sair</a>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ marginTop: 10, textAlign: "start" }}>
                                                    <a href="/login" style={{ color: "white", fontSize: 15 }}>Login Competidor <i class="fa fa-angle-right"></i></a>
                                                </div>
                                                <div style={{ marginTop: 10, textAlign: "start" }}>
                                                    <a href="/loginResponsavel" style={{ color: "white", fontSize: 15 }}>Login Responsável <i class="fa fa-angle-right"></i></a>
                                                </div>
                                            </>

                                        }
                                        <div id="mobile-menu-item-267" onClick={() => setMenu(false)} style={{ marginTop: 20, textAlign: "start" }}>
                                            <i class="fa fa-close" ></i>
                                        </div>
                                    </div>
                                    :
                                    <i class="fa fa-bars" onClick={() => setMenu(true)}></i>
                                }
                                {/* <div class="header-bottom-menu__mobile">
                                    <ul id="menu-top-menu-mob-1" class="top-nav-menu-mobile">
                                        <li id="mobile-menu-item-267"
                                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                            <a href="/" style={{ color: "white" }}>INÍCIO<i class="fa fa-angle-right"></i></a>
                                        </li>
                                        <li id="mobile-menu-item-267"
                                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                            <a href="/sobre">SOBRE<i class="fa fa-angle-right"></i></a>
                                        </li>
                                        <li id="mobile-menu-item-267"
                                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                            <a href="/eventos">EVENTOS<i class="fa fa-angle-right"></i></a>
                                        </li>
                                        <li id="mobile-menu-item-267"
                                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                            <a href="/ranking">RANKING<i class="fa fa-angle-right"></i></a>
                                        </li>
                                        <li id="mobile-menu-item-267"
                                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                            <a href="/contato">CONTATO<i class="fa fa-angle-right"></i></a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div class="header-bottom-menu ">
                        <div class="header-bottom-menu__desctop">
                            <ul id="menu-top-menu-1" class="top-nav-menu">
                                <li id="menu-item-342"
                                    className={`menu-item menu-item-type-custom menu-item-object-custom  current_page_item menu-item-home left_position ${pagina === '/' && 'current-menu-item'}`}>
                                    <a href="/">Início</a></li>
                                <li id="menu-item-40"
                                    class={`menu-item menu-item-type-post_type menu-item-object-page left_position ${pagina.includes('evento') && 'current-menu-item'}`}><a
                                        href="javascript:void(0)">EVENTOS</a>
                                    <ul class="sub-menu">
                                        <li id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                            <a href="/eventos?p=andamento">EM ANDAMENTO</a>
                                        </li>
                                        <li id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                            <a href="/eventos?p=realizados">REALIZADOS</a>
                                        </li>
                                        <li id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                            <a href="/eventos?p=resultados">RESULTADOS</a>
                                        </li>
                                    </ul>
                                </li>
                                <li id="menu-item-342"
                                    className={`menu-item menu-item-type-custom menu-item-object-custom  current_page_item menu-item-home left_position ${pagina === '/federacoes' && 'current-menu-item'}`}>
                                    <a href='/federacoes'  >Filiações</a></li>
                                {/* <li id="menu-item-41"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children left_position">
                                    <a href="/ranking">RANKINGS</a>
                                </li> */}
                                <li id="menu-item-40"
                                    className={`menu-item menu-item-type-custom menu-item-object-custom  current_page_item menu-item-home left_position ${pagina.includes('contato') && 'current-menu-item'}`}><a
                                        href="/contato" >Contato</a></li>
                                <li id="menu-item-41"
                                    className={`menu-item menu-item-type-custom menu-item-object-custom  current_page_item menu-item-home left_position ${pagina.includes('sobre') && 'current-menu-item'}`}>
                                    <a href="/sobre">Sobre</a>
                                </li>
                                {verificaUsuario ?
                                    <>
                                        <li id="menu-item-40"
                                            class="menu-item menu-item-type-post_type menu-item-object-page left_position"><a
                                                href="javascript:void(0)">Olá, {usuario.nome}</a>
                                            <ul class="sub-menu">
                                                <li id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                                    {usuario.usuario === 3 ?
                                                        <a href="/perfilResponsavel">MEU PERFIL</a>
                                                        :
                                                        <a href="/perfil">MEU PERFIL</a>
                                                    }
                                                </li>
                                                <li onClick={() => logout()} id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                                    <a href="javascript:void(0)">SAIR</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <div className='avatar'>
                                            <img src={usuario.avatar ? usuario.avatar : profile} onError={(e) => e.target.src = `${profile}`}></img>
                                        </div>
                                    </>
                                    :
                                    <li id="menu-item-40"
                                        class="menu-item menu-item-type-post_type menu-item-object-page left_position"><a
                                            href="javascript:void(0)">ACESSO SUA CONTA</a>
                                        <ul class="sub-menu">
                                            <li id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                                <a href="/login">COMPETIDOR</a>
                                            </li>
                                            <li id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                                <a href="/loginResponsavel">RESPONŚAVEL</a>
                                            </li>
                                            <li onClick={() => logout()} id="menu-item-214" class="menu-item menu-item-type-post_type menu-item-object-pnchr-trainer left_position">
                                                <a href="https://admin-procompetidor.web.app">ADMINISTRADOR</a>
                                            </li>
                                        </ul>
                                    </li>

                                }
                            </ul>
                        </div>
                        <div class="header-bottom-menu__mobile">
                            <ul id="menu-top-menu-mob-1" class="top-nav-menu-mobile">
                                <li id="mobile-menu-item-267"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                    <a href="/">INÍCIO<i class="fa fa-angle-right"></i></a>
                                </li>
                                <li id="mobile-menu-item-267"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                    <a href="/eventos">EVENTOS<i class="fa fa-angle-right"></i></a>
                                </li>
                                <li id="mobile-menu-item-267"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                    <a href="/">FILIAÇÕES<i class="fa fa-angle-right"></i></a>
                                </li>
                                <li id="mobile-menu-item-267"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                    <a href="/sobre">SOBRE<i class="fa fa-angle-right"></i></a>
                                </li>

                                {/* <li id="mobile-menu-item-267"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                    <a href="/ranking">RANKING<i class="fa fa-angle-right"></i></a>
                                </li> */}
                                <li id="mobile-menu-item-267"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children js-has-child mobile-menu-item">
                                    <a href="/contato">CONTATO<i class="fa fa-angle-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
