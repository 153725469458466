import { useState, useEffect } from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'

const mediaQuery = window.matchMedia('(max-width: 450px)');

export default function Federacoes() {
    const [servicos] = useState({
        usuarios: firebase.firestore().collection("usuarios"),
        eventos: firebase.firestore().collection("eventos"),
        federacoes: firebase.firestore().collection("federacoes"),
        auth: firebase.auth(),
        configuracoes: firebase.firestore().collection('configuracoes')
    })
    const [loading, setLoading] = useState(true)
    let [federacoes, setFederacoes] = useState([])
    const [federacoesBkp, setFederacoesBkp] = useState([])
    const [minhasFiliacoes, setMinhasFiliacoes] = useState([])
    const [usuario, setUsuario] = useState({})
    const [modalidades, setModalidades] = useState([])
    const [nomePesquisa, setNomePesquisa] = useState('')
    const [cidadePesquisa, setCidadePesquisa] = useState('')
    const [modalidadeSelecionada, setModalidadeSelecionada] = useState('')
    const [ufSelecionada, setUfSelecionada] = useState('')
    const UFs = [
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MS', 'MT', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
    ]


    useEffect(() => {

        servicos.auth.onAuthStateChanged(async (user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))

            if (user || idUser) {

                servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then((snap) => {
                    setUsuario(snap.data())

                    if (snap.data()?.filiacoes?.length > 0) {

                    }
                })

            }

        })

        // carregar Federação, Modalidade e Graduações

        servicos.configuracoes.get().then(snap => {
            const idConfiguracoes = snap.docs.map(d => d.id)[0];

            servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').get().then(snap => {
                const data = snap.docs.map(d => d.data())
                setModalidades(data)
            })

        })


        servicos.federacoes.where('status', '==', 1).get().then(snap => {
            if (snap.size > 0) {
                let data = snap.docs.map(d => d.data());

                setFederacoes(data);
                setFederacoesBkp(data);
                setLoading(false);
            }
        })



        setLoading(false);

    }, [])

    useEffect(async () => {
        const federacoesVinculadas = []
        if (usuario.id) {
            if(usuario?.filiacoes?.length > 0){
                federacoesVinculadas.push(...usuario.filiacoes)
            }

            if(usuario.usuario === 3){
                const snapAlunos = await servicos.usuarios.doc(usuario.id).collection
                ('competidores').get()

                if(!snapAlunos.empty){
                    const dadosAnlunos = snapAlunos.docs.map(d => d.data())
                    dadosAnlunos.forEach(aluno =>{
                        if(aluno?.filiacoes?.length > 0){
                            federacoesVinculadas.push(...aluno?.filiacoes) 
                        }
                    })
                }

            }
            
        }
        setMinhasFiliacoes(federacoes.filter(federacao => federacoesVinculadas.some(fv => fv === federacao.id)))
    }, [usuario])

    async function handleFiltroGeral(filtro, valor) {

        federacoes = federacoesBkp

        if (filtro === 'uf') {

            if (modalidadeSelecionada) {
                federacoes = federacoes.filter(federacao => federacao.modalidade === modalidadeSelecionada)
            }

            if (valor) {
                federacoes = federacoes.filter(federacao => federacao.uf === valor)
            }

            setUfSelecionada(valor)
        }

        if (filtro === 'modalidade') {

            if (ufSelecionada) {
                federacoes = federacoes.filter(federacao => federacao.uf === ufSelecionada)

            }

            if (valor) {
                federacoes = federacoes.filter(federacao => federacao.modalidade === valor)
            }

            setModalidadeSelecionada(valor)
        }

        if(nomePesquisa){
            federacoes = federacoes.filter(federacao => {
                return federacao['nome'].toLowerCase().includes(nomePesquisa.toLowerCase());
            })
        }
        if(cidadePesquisa){
            federacoes = federacoes.filter(federacao => {
                return federacao['cidade'].toLowerCase().includes(cidadePesquisa.toLowerCase());
            })
        }
       
        setFederacoes(federacoes)

    }

    const handlePesquisaDigitada = (filtro, valor) => {

        if(filtro === 'nome'){
            setNomePesquisa(valor)
        }
        if(filtro === 'cidade'){
            setCidadePesquisa(valor)
        }

        federacoes = federacoesBkp

        if (ufSelecionada) {
            federacoes = federacoes.filter(federacao => federacao.uf === ufSelecionada)

        }
        if (modalidadeSelecionada) {
            federacoes = federacoes.filter(federacao => federacao.modalidade === modalidadeSelecionada)
        }

       const federacoesFiltradas = federacoes.filter(federacao => {
            return federacao[filtro].toLowerCase().includes(valor.toLowerCase());
        })

        setFederacoes(federacoesFiltradas)

    }

    function limparFiltros(){
        setFederacoes(federacoesBkp)
        setUfSelecionada('')
        setModalidadeSelecionada('')
        setNomePesquisa('')
        setCidadePesquisa('')
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Federações & Agremiações</h1>
                        </div>
                        <div class="headline__description">
                            <span>Procure pela Federação / Agremiação que deseja se filiar</span>

                        </div>
                    </div>
                </div>
            </div>

            <main className="section_default">

                {/* Minhas Filiações - Início */}
                {minhasFiliacoes.length > 0 && (
                    <div className="vc_row wpb_row vc_row-fluid vc_column-gap-30">
                        <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-offset-0 vc_col-lg-12 vc_col-md-offset-2 vc_col-md-12 vc_col-sm-offset-2 vc_col-xs-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper" style={{ marginTop: "10px" }}>
                                            <h3 className='title-section_with-sep' style={{ fontWeight: "bold" }}>Minhas Filiações</h3>
                                        </div>
                                    </div>
                                    <div className="vc_empty_space" style={{ height: 24 }}>
                                        <span className="vc_empty_space_inner"></span>
                                    </div>
                                    <div className="row" id='card-mini'>
                                        {minhasFiliacoes.map((federacao) =>
                                            <>
                                                <a style={{ cursor: 'pointer' }} href={`/federacao/${federacao.id}`} onClick={() => 'detalheEvento(evento.id)'}>

                                                    <div key={federacao.id} className="ratings-widget__item  vc_col-lg-4">
                                                        <div className="ratings-widget__img js-hover-veil"><img
                                                            src={federacao.urlImagemFederacao}
                                                            alt="" title="image here (2)" />
                                                        </div>

                                                        <div className="ratings-widget__details ">
                                                            <div className="ratings-widget__details-title" title={federacao.nome}><i className="fa  fa-shield" aria-hidden="true"> </i>
                                                                {mediaQuery.matches ?
                                                                    ` ${federacao.nome}`
                                                                    :
                                                                    ` ${federacao.nome.substr(0, 45)} ${federacao.nome.substr(0, 45).length < 45 ? '' : '...'}`
                                                                }


                                                            </div>
                                                            <div className="ratings-widget__details-cat"><i className="fa  fa-bookmark" aria-hidden="true"
                                                            ></i> {`${modalidades.find(md => md.id === federacao.modalidade)?.descricao}`}</div>
                                                            <div className="ratings-widget__details-cat"><i className="fa  fa-map-marker" aria-hidden="true"

                                                            > </i>
                                                                {` ${federacao.cidade} / ${federacao.uf}`.substr(0, 20)}{`${federacao.cidade} / ${federacao.uf}`.substr(0, 18).length < 18 ? '' : '...'}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Minhas Filiações - Fim */}

               

{/* Fluido - inicio */}
<div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="js-carusel-wrapp clearfix">
                                    
                                <div className="carusel-empty-space" style={{ height: 24 }}></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper" style={{ marginTop: "10px" }}>
                                            <h3 className='title-section_with-sep' style={{ fontWeight: "bold", marginBottom: '40px' }}>Federações & Agremiações</h3>
                                        </div>
                                    </div>
                                    {/* filtro - Início */}
                                    <h6 style={{ marginBottom: '8px' }}>Filtrar Por: </h6>
                                    <div class="wpb_column vc_column_container vc_col-sm-12" style={{ marginBottom: '40px', boxShadow: '0 0 0.5px 0.2px' }}>
                                        <p class="contact-form-name vc_col-sm-3">
                                            <span class="wpcf7-form-control-wrap your-name">
                                                <h6>Nome</h6>
                                                <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={e => handlePesquisaDigitada('nome', e.target.value)}
                                                    value={nomePesquisa}
                                                    placeholder="Digite o nome da Federação" />
                                            </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-2">
                                            <span class="wpcf7-form-control-wrap your-name">
                                                <h6>UF</h6>
                                                <select name="select" id="select" class="form-control" onChange={e => handleFiltroGeral('uf', e.target.value)} value={ufSelecionada}>
                                                    <option value="">Todas </option>
                                                    {UFs.map(uf => (
                                                        <option value={uf}>{uf} </option>
                                                    ))}
                                                </select>
                                            </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-3">
                                            <span class="wpcf7-form-control-wrap your-name">
                                                <h6>Cidade</h6>
                                                <input
                                                    type="text" name="your-name" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                    aria-required="true" aria-invalid="false"
                                                    onChange={e => handlePesquisaDigitada('cidade', e.target.value)}
                                                    value={cidadePesquisa}
                                                    placeholder="Digite a Cidade*" />
                                            </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-2">
                                            <span class="wpcf7-form-control-wrap your-name">
                                                <h6>Modalidade</h6>
                                                <select name="select" id="select" class="form-control" onChange={e => handleFiltroGeral('modalidade', e.target.value)} value={modalidadeSelecionada}>
                                                    <option value="">Todas </option>
                                                    {modalidades.map(modalidade => (
                                                        <option value={modalidade.id}>{modalidade.descricao} </option>
                                                    ))}
                                                </select>
                                            </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-2">
   
                                                <h6 style={{visibility:'hidden'}}>|</h6>
                                                <button 
                                                style={{display:'flex', textAlign:'center', justifyContent:'center', alignItems: 'center', height:'39px', width:'100%', background:'rgb(23, 162, 184)', cursor:'pointer'} } class="package-style-1__btn btn_defoult btn_fill vc_col-sm-12" onClick={() => limparFiltros()}> Limpar
                                    </button>
                                           
                                        </p>
                                    </div>
                                    {/* filtro - Fim */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
{/* Fluido - Fim */}



                <div className="vc_row wpb_row vc_row-fluid" style={{marginTop: mediaQuery.matches ? '20px' : ''}}>
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="js-carusel-wrapp clearfix">
                                    
                                    {federacoes.length > 0 ?
                                        <div className="pnchrOwlCarousel_5ff0a67aea27d  row">
                                            {federacoes.map((federacao) =>
                                                <div id='card-destaque' key={federacao.id} className="vc_col-sm-3 ">
                                                    <a href={`/federacao/${federacao.id}`}>

                                                        <div className="vizit-cart-item" style={{ height: '380px' }}>
                                                            <div className="vizit-cart-item__img">
                                                                <div className="vizit-cart-item__img-overlay"></div>
                                                                <div className="vizit-cart-item__img-overlay-share">
                                                                    <p style={{ color: "#ffffff" }}>Clique aqui para saber mais sobre esta federação/agremiação</p>
                                                                </div><img
                                                                    src={federacao.urlImagemFederacao}
                                                                    alt="" title="image-here" />
                                                            </div>

                                                            <div className="vizit-cart-item__descr">
                                                                <div
                                                                    title={federacao.nome} className="vizit-cart-item__title" style={{ color: "#121212" }}>
                                                                    <i className="fa  fa-shield" aria-hidden="true"></i>
                                                                    {mediaQuery.matches ?
                                                                        federacao.nome
                                                                        :
                                                                        `${federacao.nome.substr(0, 32)}${federacao.nome.substr(0, 32).length < 32 ? '' : '...'}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <>

                                            <div className='wpb_column vc_column_container vc_col-sm-12'>
                                                {loading ?
                                                    <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                        style={{ color: "#c21111" }}></i> Aguarde... Carregando Federações e Agremiações </h2>
                                                    :
                                                    <h2>Nenhum resultado para a pesquisa <i class="fa fa-search" aria-hidden="true"></i></h2>
                                                }

                                            </div>


                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}
