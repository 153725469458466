import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import './Filiacao.css'



export default function Federacao() {

  const { idFederacao } = useParams()

  const [servicos] = useState({
    eventos: firebase.firestore().collection("eventos"),
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    inscricoes: firebase.firestore().collection('inscricoes'),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
    configuracoes: firebase.firestore().collection('configuracoes'),
    auth: firebase.auth()
  })

  const [federacao, setFederacao] = useState({})
  const [modalidade, setModalidade] = useState('')
  const [filiacao, setFiliacao] = useState({});
  const [usuario, setUsuario] = useState({})
  const [usuarioFiliacao, setUsuarioFiliacao] = useState({})


  const mediaQuery = window.matchMedia('(max-width: 650px)');



  useEffect(() => {

    servicos.auth.onAuthStateChanged(async (user) => {
      let idUser = JSON.parse(localStorage.getItem('usuarioId'))

      if (user || idUser) {

        servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then(async (doc) => {
          if (doc.exists) {
            setUsuario(doc.data())

            if (doc.data().usuario === 2) {
              setUsuarioFiliacao(doc.data())

              // carregar filiação
              if (doc.data()?.filiacoes?.length > 0) {
                if (doc.data()?.filiacoes.includes(idFederacao)) {
                  const docRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', doc.data().id).where('tipoUsuario', '==', doc.data().tipoUsuario).get();
                  const data = docRef.docs.map(d => d.data())[0];
                  setFiliacao(data);
                }
              }

            }

          }

        })

      } 

    })

    // carregar Federação, Modalidade e Graduações

    servicos.federacoes.doc(idFederacao).get().then(snap => {

      setFederacao(snap.data());

      const idModalidade = snap.data().modalidade

      servicos.configuracoes.get().then(snap => {
        const idConfiguracoes = snap.docs.map(d => d.id)[0];

        servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').doc(idModalidade).get().then(snap => {
          setModalidade(snap.data().descricao ? snap.data().descricao : '')
        })

      })

    })



  }, [])


  function semFiliacao() {
    return ((usuario.usuario === 2 && !filiacao.id) || !usuario.id)
  }

  return (
    <div className="body-wrapper blank-loder">
      <Header />
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Federação / Agremiação</h1>
            </div>
            <div class="headline__description">
              <span>Confira abaixo os detalhes da Federação / Agremiação</span>
            </div>
          </div>

        </div>
      </div>
      <div class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">
                    <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                    </div>

                    <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>

                      <div className={mediaQuery.matches ? 'vc_col-sm-12' : ''} style={{display: !mediaQuery.matches ? 'flex' : 'block'}} >

                        <div class=" vc_col-sm-4">
                          <div class="wpb_single_image wpb_content_element vc_align_left">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper package-style-1   vc_box_border_grey">
                                <img width="1920" height="1401"
                                  src={federacao?.urlImagemFederacao}
                                  class="vc_single_image-img attachment-full" alt=""
                                  srcset={federacao?.urlImagemFederacao}
                                  sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                            </figure>

                          </div>
                        </div>

                        <div class="wpb_column vc_column_container vc_col-sm-8">

                          <div class="vc_column-inner " style={{ marginTop: '17px' }}>
                            <div class="wpb_wrapper">
                              <div class="wpb_widgetised_column wpb_content_element">
                                <div class="wpb_wrapper">
                                  <div role="form" class="row " >
                                    <p class="contact-form-name vc_col-sm-12">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Nome</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.nome}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>

                                    <p class="contact-form-name vc_col-sm-2">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>DDD</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.ddd}
                                          disabled
                                          placeholder="DDD *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-3">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Telefone</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.telefone}
                                          disabled
                                          placeholder="Celular *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-7">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Email</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.email}
                                          disabled
                                          placeholder="Celular *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-6">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Cidade</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.cidade}
                                          disabled
                                          placeholder="Cidade" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-2">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>UF</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.uf}
                                          disabled
                                          style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-4">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Modalidade</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={modalidade}
                                          disabled
                                          style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>

                                    <div style={{ position: 'relative', top: '40px', marginBottom:'10px' }} class="package-style-1__btn vc_col-lg-6" onClick={() => 'inscricao()'}>
                                      <a href="#" class="btn_defoult btn_fill vc_col-lg-12" onClick={() => {
                                        if (!usuario.id) {
                                          window.location = window.location = `/verificaPerfil?ref=filiacao&idref=${federacao.id}`
                                        } else {
                                          window.location = semFiliacao() ? `/filiacao/${idFederacao}` : `/detalheFiliacao?f=${idFederacao}`
                                        }
                                      }}><i class="fa fa-user" aria-hidden="true" style={{ color: "#fff" }}></i> {semFiliacao() ? 'Filiar-se' : 'Minha Filiação'}</a>
                                    </div>
                                    <div style={{ position: 'relative', top: '40px' }} class="package-style-1__btn vc_col-lg-6" onClick={() => 'inscricao()'}>
                                      <a href={`/membrosFiliados?f=${idFederacao}`} class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#17a2b8' }}><i class="fa fa-users" aria-hidden="true" style={{ color: "#fff" }}></i> Membros Filiados</a>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
      <Footer />
    </div>
  );
}
