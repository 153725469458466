import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import MediaQuery from 'react-responsive'
import moment from 'moment'
import Swal from 'sweetalert2'


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '49%',
        height: 120,
        display: 'flex',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: 10
    },
    avatar: {
        width: 120,
        height: 120
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    summary: {
        backgroundColor: '#4CAF50',
    }
}));

export default function Checagem() {
    const classes = useStyles();
    const [id] = useState(window.location.pathname.replace('/detalheRanking/', ''))
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios'),
        rankings: firebase.firestore().collection('ranking'),
        eventos: firebase.firestore().collection('eventos'),
        inscricoes: firebase.firestore().collection('inscricoes'),
    })
    const [ranking, setRanking] = useState({})
    const [categorias, setCategorias] = useState([])
    const [atletas, setAtletas] = useState([])
    const [eventos, setEventos] = useState([])
    const [indexEvento, setIndexEvento] = useState()
    const [listaEventos, setListaEventos] = useState([])

    useEffect(() => {
        servicos.rankings.doc(id).get().then((e) => {
            setRanking(e.data())
        })
        servicos.rankings.doc(id).collection('eventos').get().then((e) => {
            const data = e.docs.map((d) => d.data())
            setListaEventos(data)
        })
        servicos.rankings.doc(id).collection('categorias').get().then((snap) => {
            const list = []
            const listAtletas = []
            const data = snap.docs.map((d) => d.data())
            mensagemLoading('Montando Ranking. Isso pode demorar um pouco !')
            data.forEach((dc) => {
                list.push({
                    id: dc.id,
                    categoria: dc.nome,
                    posicao: dc.posicao
                })
                // servicos.rankings.doc(id).collection('categorias').doc(dc.id).collection('categorias').get().then((ca) => {
                //     const data = ca.docs.map((d) => d.data())

                //     data.forEach((c, index) => {
                //         c.alunos.forEach((al) => {
                //             servicos.rankings.doc(id).collection('eventos').doc(al.evento.id).get().then((ev) => {
                //                 if (ev.exists) {
                //                     let pontuacao = 0
                //                     let pontuacaoAbsoluto = 0
                //                     if (al.posicaoCategoria) {
                //                         if (al.posicaoCategoria === 1) {
                //                             pontuacao = ev.data().ouro
                //                         }
                //                         if (al.posicaoCategoria === 2) {
                //                             pontuacao = ev.data().prata
                //                         }
                //                         if (al.posicaoCategoria === 3 || ev.data().posicaoCategoria === 4) {
                //                             pontuacao = ev.data().bronze
                //                         }
                //                         if (al.posicaoCategoria === 5) {
                //                             pontuacao = ev.data().ouroSozinho
                //                         }
                //                         if (al.posicaoCategoria === 6) {
                //                             pontuacao = ev.data().ouroEquipe
                //                         }
                //                         if (al.posicaoCategoria === 7) {
                //                             pontuacao = ev.data().prataEquipe
                //                         }
                //                         if (al.posicaoCategoria === 8) {
                //                             pontuacao = ev.data().bronzeEquipe
                //                         }
                //                         if (al.posicaoCategoria > 8) {
                //                             pontuacao = ev.data().semMedalha
                //                         }
                //                     }

                //                     if (al.posicaoAbsoluto) {
                //                         if (al.posicaoAbsoluto === 1) {
                //                             pontuacaoAbsoluto = ev.data().ouroAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto === 2) {
                //                             pontuacaoAbsoluto = ev.data().prataAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto === 3 || ev.data().posicaoAbsoluto === 4) {
                //                             pontuacaoAbsoluto = ev.data().bronzeAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto === 5) {
                //                             pontuacaoAbsoluto = ev.data().ouroSozinhoAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto === 6) {
                //                             pontuacaoAbsoluto = ev.data().ouroEquipeAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto === 7) {
                //                             pontuacaoAbsoluto = ev.data().prataEquipeAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto === 8) {
                //                             pontuacaoAbsoluto = ev.data().bronzeEquipeAbsoluto
                //                         }
                //                         if (al.posicaoAbsoluto > 8) {
                //                             pontuacaoAbsoluto = ev.data().semMedalhaAbsoluto
                //                         }
                //                     }


                //                     if (listAtletas.filter((d) => d.id === al.usuario.id).length === 0) {
                //                         listAtletas.push({
                //                             idCategoria: dc.id,
                //                             evento: al.evento.id,
                //                             id: al.usuario.id,
                //                             idInscricao: al.id,
                //                             atleta: al.usuario.nome + ' ' + al.usuario.sobrenome,
                //                             equipe: al.equipe,
                //                             pontuacao: parseInt(pontuacao + pontuacaoAbsoluto),
                //                             eventos: [
                //                                 {
                //                                     id: al.evento.id,
                //                                     evento: al.evento.nome,
                //                                     pontuacaoAbsoluto: parseInt(pontuacaoAbsoluto),
                //                                     pontuacaoPeso: parseInt(pontuacao),
                //                                     pontuacao: parseInt(pontuacao + pontuacaoAbsoluto)
                //                                 }
                //                             ]
                //                         })
                //                     } else {
                //                         const id = listAtletas.findIndex((u) => u.id === al.usuario.id)
                //                         listAtletas[id].pontuacao = parseInt(listAtletas[id].pontuacao) + parseInt(pontuacao + pontuacaoAbsoluto)
                //                         listAtletas[id].eventos = [
                //                             ...listAtletas[id].eventos,
                //                             {
                //                                 id: al.evento.id,
                //                                 evento: al.evento.nome,
                //                                 pontuacaoAbsoluto: parseInt(pontuacaoAbsoluto),
                //                                 pontuacaoPeso: parseInt(pontuacao),
                //                                 pontuacao: parseInt(pontuacao + pontuacaoAbsoluto)
                //                             }
                //                         ]
                //                     }
                //                 }
                //             })

                //         })
                //     })
                // })
            })
            setTimeout(() => {
                setAtletas(listAtletas.filter((d) => d.pontuacao > 0))
                setCategorias(list.sort((a, b) => {
                    return moment(b.dataCadastro) - moment(a.dataCadastro)
                }))
                Swal.close();
            }, 5000);
        })
    }, [])

    function detalheCategoria(item) {
        if (atletas.filter((e) => e.idCategoria === item.id).length === 0) {
            mensagemLoading('Carregando informações de da categoria ranking \n\n'+item.categoria.toUpperCase())
            const list = []
            const listAtletas = []
            servicos.rankings.doc(id).collection('categorias').doc(item.id).collection('categorias').get().then((d) => {
                const data = d.docs.map((d) => d.data())
                data.forEach((ct) => {
                    ct.alunos.forEach((al) => {
                        if(al.usuario.id === '1UFRA9cSlHOqr3irIFWKGyxwh1A3'){
                            console.log('aqui ')
                            console.log('evento '+al.evento.nome)
                        }
                        servicos.rankings.doc(id).collection('eventos').doc(al.evento.id).get().then((ev) => {
                            if (ev.data().id) {
                                
                                let pontuacaoCategoria = 0
                                let pontuacaoAbsoluto = 0
                                if (al.posicaoCategoria && al.posicaoCategoria > 0) {
                                    if (al.posicaoCategoria === 1) {
                                        pontuacaoCategoria = parseInt(ev.data().ouro)
                                    }
                                    if (al.posicaoCategoria === 2) {
                                        pontuacaoCategoria = parseInt(ev.data().prata)
                                    }
                                    if (al.posicaoCategoria === 3 || al.posicaoCategoria === 4) {
                                        pontuacaoCategoria = parseInt(ev.data().bronze)
                                    }
                                    if (al.posicaoCategoria === 5) {
                                        pontuacaoCategoria = parseInt(ev.data().ouroSozinho)
                                    }
                                    if (al.posicaoCategoria === 6) {
                                        pontuacaoCategoria = parseInt(ev.data().ouroEquipe)
                                    }
                                    if (al.posicaoCategoria === 7) {
                                        pontuacaoCategoria = parseInt(ev.data().prataEquipe)
                                    }
                                    if (al.posicaoCategoria === 8) {
                                        pontuacaoCategoria = parseInt(ev.data().bronzeEquipe)
                                    }
                                    if (al.posicaoCategoria > 8) {
                                        pontuacaoCategoria = parseInt(ev.data().semMedalha)
                                    }
                                }

                                if (al.posicaoAbsoluto && al.posicaoAbsoluto > 0) {
                                    if (al.posicaoAbsoluto === 1) {
                                        pontuacaoAbsoluto = parseInt(ev.data().ouroAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto === 2) {
                                        pontuacaoAbsoluto = parseInt(ev.data().prataAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto === 3 || al.posicaoAbsoluto === 4) {
                                        pontuacaoAbsoluto = parseInt(ev.data().bronzeAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto === 5) {
                                        pontuacaoAbsoluto = parseInt(ev.data().ouroSozinhoAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto === 6) {
                                        pontuacaoAbsoluto = parseInt(ev.data().ouroEquipeAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto === 7) {
                                        pontuacaoAbsoluto = parseInt(ev.data().prataEquipeAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto === 8) {
                                        pontuacaoAbsoluto = parseInt(ev.data().bronzeEquipeAbsoluto)
                                    }
                                    if (al.posicaoAbsoluto > 8) {
                                        pontuacaoAbsoluto = parseInt(ev.data().semMedalhaAbsoluto)
                                    }
                                }

                                
                                if (listAtletas.filter((d) => d.id === al.usuario.id).length === 0) {
                                    
                                    listAtletas.push({
                                        idCategoria: item.id,
                                        evento: al.evento.id,
                                        id: al.usuario.id,
                                        idInscricao: al.id,
                                        atleta: al.usuario.nome + ' ' + al.usuario.sobrenome,
                                        equipe: al.equipe,
                                        pontuacao: parseInt(pontuacaoCategoria + pontuacaoAbsoluto),
                                        eventos: [
                                            {
                                                id: al.evento.id,
                                                evento: al.evento.nome,
                                                pontuacaoAbsoluto: parseInt(pontuacaoAbsoluto),
                                                pontuacaoPeso: parseInt(pontuacaoCategoria),
                                                semMedalha: parseInt(al.posicaoCategoria > 8 ? pontuacaoCategoria : 0) + parseInt(al.posicaoAbsoluto > 8 ? pontuacaoAbsoluto : 0),
                                                pontuacao: parseInt(pontuacaoCategoria + pontuacaoAbsoluto)
                                            }
                                        ]
                                    })
                                    
                                } else {
                                    let total = parseInt(pontuacaoCategoria + pontuacaoAbsoluto)
                                    const id = listAtletas.findIndex((u) => u.id === al.usuario.id)
                                    
                                    if (total > 0) {
                                        listAtletas[id].pontuacao = parseInt(listAtletas[id].pontuacao) + parseInt(total)
                                    }
                                    
                                    if (listAtletas[id].eventos.filter((e) => e.id === ev.data().id).length > 0) {
                                        const idEvento = listAtletas[id].eventos.findIndex((e) => e.id === ev.data().id)
                                        listAtletas[id].eventos[idEvento].pontuacaoPeso = parseInt(listAtletas[id].eventos[idEvento].pontuacaoPeso) + parseInt(pontuacaoCategoria)
                                        listAtletas[id].eventos[idEvento].pontuacaoAbsoluto = parseInt(listAtletas[id].eventos[idEvento].pontuacaoAbsoluto) + parseInt(pontuacaoAbsoluto)
                                        listAtletas[id].eventos[idEvento].pontuacao = parseInt(listAtletas[id].eventos[idEvento].pontuacao) + parseInt(pontuacaoCategoria) + parseInt(pontuacaoAbsoluto)
                                    } else {
                                        listAtletas[id].eventos = [
                                            ...listAtletas[id].eventos,
                                            {
                                                id: al.evento.id,
                                                evento: al.evento.nome,
                                                pontuacaoAbsoluto: parseInt(pontuacaoAbsoluto),
                                                pontuacaoPeso: parseInt(pontuacaoCategoria),
                                                semMedalha: parseInt(al.posicaoCategoria > 8 ? pontuacaoCategoria : 0) + parseInt(al.posicaoAbsoluto > 8 ? pontuacaoAbsoluto : 0),
                                                pontuacao: parseInt(pontuacaoCategoria + pontuacaoAbsoluto)
                                            }
                                        ]
                                       
                                    }
                                    
                                    

                                }
                            }
                        })
                    })
                })
            })
            setTimeout(() => {
                Swal.close()
                listAtletas.push(
                    ...atletas,
                    listAtletas
                )
                setAtletas(listAtletas.filter((d) => d.pontuacao > 0))
            }, 10000);
        }

    }

    function detalheEventos(item, index) {
        if (index === indexEvento) {
            setIndexEvento()
            setEventos([])
        } else {
            setIndexEvento(index)
            const list = []
            listaEventos.forEach((d) => {
                console.log(item.eventos)
                if (item.eventos.filter((e) => e.id === d.id).length > 0) {
                    list.push({
                        evento: d.nome,
                        pontuacaoAbsoluto: item.eventos.filter((e) => e.id === d.id)[0].pontuacaoAbsoluto,
                        pontuacaoPeso: item.eventos.filter((e) => e.id === d.id)[0].pontuacaoPeso,
                        pontuacao: item.eventos.filter((e) => e.id === d.id)[0].pontuacao,
                        semMedalha: item.eventos.filter((e) => e.id === d.id)[0].semMedalha,
                    })
                } else {
                    list.push({
                        evento: d.nome,
                        pontuacaoAbsoluto: 0,
                        pontuacaoPeso: 0,
                        pontuacao: 0
                    })
                }
            })
            setEventos(list)
        }

    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />

                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Ranking {ranking && ranking.id ? ranking.nome : 'Carregando'}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Confira abaixo o ranking  </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <main >
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 style={{ marginLeft: 16 }}>Resultado do Ranking {ranking && ranking.id ? ranking.nome : 'Carregando'}</h3>

                                            </div>
                                            <div class="vc_col-sm-12">
                                                {categorias.length > 0 ?
                                                    <>
                                                        {categorias.sort((a, b) => {
                                                            if (parseInt(a.posicao) < parseInt(b.posicao))
                                                                return -1;
                                                            if (parseInt(a.posicao) > parseInt(b.posicao))
                                                                return 1;
                                                        }).map((itemCategoria, index) =>
                                                            <>
                                                                <MediaQuery minWidth={1224}>
                                                                    <Accordion onClick={() => detalheCategoria(itemCategoria)} >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }} />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            className={classes.summary}
                                                                        >
                                                                            {/* <p style={{ fontSize: 15, color: '#FFFF' }}>{itemCategoria.categoria.toUpperCase()} - {atletas.filter((d) => d.idCategoria === itemCategoria.id).length} ATLETAS</p> */}
                                                                            <p style={{ fontSize: 15, color: '#FFFF' }}>{itemCategoria.categoria.toUpperCase()}</p>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails >
                                                                            <Grid container spacing={12}>
                                                                                <div class="vc_col-sm-12">
                                                                                    <table width={'100%'}>
                                                                                        <tr>
                                                                                            <th>ATLETA</th>
                                                                                            <th>TOTAL</th>
                                                                                        </tr>
                                                                                        <tbody>
                                                                                            {atletas.filter((d) => d.idCategoria === itemCategoria.id).sort((a, b) => {
                                                                                                if (parseInt(a.pontuacao) > parseInt(b.pontuacao))
                                                                                                    return -1;
                                                                                                if (parseInt(a.pontuacao) < parseInt(b.pontuacao))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).map((atleta, index) =>
                                                                                                <>
                                                                                                    <tr key={index + 1} >
                                                                                                        <td style={{ textAlign: 'left' }}>
                                                                                                            <b>{index + 1}° {atleta.atleta.toUpperCase()} </b>- ( {atleta.equipe.nomeEquipe.toUpperCase()} )
                                                                                                        </td>
                                                                                                        <td><b>{atleta.pontuacao} pontos</b> <i class="fa fa-search" style={{ marginLeft: 10 }} onClick={() => detalheEventos(atleta, index)}></i></td>
                                                                                                    </tr>
                                                                                                    {eventos.length > 0 && indexEvento === index ?
                                                                                                        <>
                                                                                                            <table width={'100%'} style={{ backgroundColor: '#E0F8E0' }}>
                                                                                                                <tbody>
                                                                                                                    {eventos.sort((a, b) => {
                                                                                                                        if (parseInt(a.pontuacao) > parseInt(b.pontuacao))
                                                                                                                            return -1;
                                                                                                                        if (parseInt(a.pontuacao) < parseInt(b.pontuacao))
                                                                                                                            return 1;
                                                                                                                        return 0;
                                                                                                                    }).map((item, index) =>
                                                                                                                        <tr key={index + 1} >
                                                                                                                            <td style={{ textAlign: 'left' }}>
                                                                                                                                {item.evento.toUpperCase()}<br />
                                                                                                                                <p style={{ fontSize: 13, marginTop: 10 }}><b>Peso:</b> {item.pontuacaoPeso ? item.pontuacaoPeso : 0} pontos | <b>Absoluto:</b> {item.pontuacaoAbsoluto ? item.pontuacaoAbsoluto : 0} pontos | <b>Sem:</b> {item.semMedalha ? item.semMedalha : 0} pontos | <b>Total: {item.pontuacao}</b></p>

                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    )}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }

                                                                                                </>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </Grid>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </MediaQuery>
                                                                <MediaQuery minWidth={300} maxWidth={1000}>
                                                                    <Accordion onClick={() => detalheCategoria(itemCategoria)}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon style={{ color: '#FFF' }} />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            className={classes.summary}
                                                                        >

                                                                            {/* <p style={{ fontSize: 15, color: '#FFFF' }}>{itemCategoria.categoria.toUpperCase()} - {atletas.filter((d) => d.idCategoria === itemCategoria.id).length} ATLETAS</p> */}
                                                                            <p style={{ fontSize: 15, color: '#FFFF' }}>{itemCategoria.categoria.toUpperCase()}</p>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails >
                                                                            <table width={'100%'}>
                                                                                <tr>
                                                                                    <th>ATLETA</th>
                                                                                    <th>TOTAL</th>
                                                                                </tr>
                                                                                <tbody>
                                                                                    {atletas.filter((d) => d.idCategoria === itemCategoria.id).sort((a, b) => {
                                                                                        if (parseInt(a.pontuacao) > parseInt(b.pontuacao))
                                                                                            return -1;
                                                                                        if (parseInt(a.pontuacao) < parseInt(b.pontuacao))
                                                                                            return 1;
                                                                                        return 0;
                                                                                    }).map((atleta, index) =>
                                                                                        <>
                                                                                            <tr key={index + 1} >
                                                                                                <td style={{ textAlign: 'left' }}>
                                                                                                    <b>{index + 1}° {atleta.atleta.toUpperCase()}</b><br />
                                                                                                    {atleta.equipe.nomeEquipe.toUpperCase()}
                                                                                                </td>
                                                                                                <td><b>{atleta.pontuacao}</b> <i class="fa fa-search" style={{ marginLeft: 10 }} onClick={() => detalheEventos(atleta, index)}></i></td>
                                                                                            </tr>
                                                                                            {eventos.length > 0 && indexEvento === index ?
                                                                                                <>
                                                                                                    <table width={'100%'} style={{ backgroundColor: '#E0F8E0' }}>
                                                                                                        <tbody>
                                                                                                            {eventos.sort((a, b) => {
                                                                                                                if (parseInt(a.pontuacao) > parseInt(b.pontuacao))
                                                                                                                    return -1;
                                                                                                                if (parseInt(a.pontuacao) < parseInt(b.pontuacao))
                                                                                                                    return 1;
                                                                                                                return 0;
                                                                                                            }).map((item, index) =>
                                                                                                                <tr key={index + 1} >
                                                                                                                    <td style={{ textAlign: 'left' }}>
                                                                                                                        {item.evento.toUpperCase()}<br />
                                                                                                                        <p style={{ fontSize: 13, marginTop: 10 }}><b>Peso:</b> {item.pontuacaoPeso ? item.pontuacaoPeso : 0} pontos | <b>Absoluto:</b> {item.pontuacaoAbsoluto ? item.pontuacaoAbsoluto : 0} pontos | <b>Total: {item.pontuacao}</b></p>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )}
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                        </>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </MediaQuery>
                                                            </>
                                                        )}
                                                    </>
                                                    :
                                                    <h4> Sem RESULTADOS lançados </h4>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
