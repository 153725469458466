import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Swal from 'sweetalert2'

export default function Sobre() {
    const [servicos] = useState({
        mail: firebase.firestore().collection('mail'),
        configuracoes: firebase.firestore().collection("configuracoes")
    })
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [assunto, setAssunto] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [configuracoes, setConfiguracoes] = useState({});


    
    useEffect(()=>{
        servicos.configuracoes.get().then((snap =>{
            const config = snap.docs.map(c => c.data())
            setConfiguracoes(config[0]);
        }))
    },[])


    function enviaEmail() {
        if (!nome) {
            mensagemAlerta('Favor informe seu nome')
            return
        }
        if (!email) {
            mensagemAlerta('Favor informe seu Email para resposta de nossos analistas')
            return
        }
        if (!assunto) {
            mensagemAlerta('Qual o assunto que quer nos enviar?')
            return
        }
        if (!mensagem) {
            mensagemAlerta('Digite sua dúvida, sugestão ou reclamação')
            return
        }
        const corpo = {
            to: 'procompetidor@gmail.com',
            from: 'Dúvidas do Site',
            message: {
                subject: assunto,
                html: '<html>' +
                    '<body>' +
                    '<h1><b>Olá, me chamo ' + nome + ' </b></h1>' +
                    '<h2><b>gostaria de uma informação com a mensagem abaixo</b></h2>' +
                    '<h4><b>Mensagem:' + mensagem + ' <b/></h4>' +
                    '<p><b>Meu Email para resposta:' + email + ' <b/></p>' +
                    '</body>' +
                    '</html>'
            }
        }
        mensagemLoading('Enviando seu Email...')
        servicos.mail.add(corpo).then(() => {
            Swal.close();
            mensagemSucesso('Sua mensagem foi enviada com sucesso!')
        }).catch(() => {
            mensagemErro('Erro ao enviar email! Entre em contato com nosso contato por Whatsapp')
        })
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value)
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value)
    }
    const handleAssunto = (event) => {
        event.persist();
        setAssunto(event.target.value)
    }
    const handleMensagem = (event) => {
        event.persist();
        setMensagem(event.target.value)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function formataNumero(){

        const numero = Object.keys(configuracoes).length > 0 ? configuracoes.whatsapp.numero + '' : '';
        
        return `(${numero.substring(0,2)})
        ${numero.length == 10 ? numero.substring(2,6) : numero.substring(2,7)}-${numero.substring(numero.length-4,numero.length)}`
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Contato</h1>
                            </div>
                            <div class="headline__description">
                                <span>Entre em contato por telefone, email ou outros meios de comunicações </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class=" vc_col-sm-8">
                                                <div class="wpb_single_image wpb_content_element vc_align_left">
                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                        <h2>Comunicações</h2>
                                                    </div>
                                                    <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-4 vc_col-md-6 vc_col-sm-offset-0 vc_col-xs-6">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="ticket-style-1-wrapp">
                                                                        <div class="ticket-style-1 " style={{ backgroundColor: "#ffffff" }}>
                                                                            <div class="ticket-style-1__img">
                                                                                <i class="fa fa-phone " aria-hidden="true" style={{ color: "#c21111", fontSize: 40 }} />
                                                                            </div>
                                                                            <div class="ticket-style-1__descr ">
                                                                                <h3 class="ticket-style-1__title" style={{ color: "#000000" }}>TELEFONE</h3>
                                                                                <div class="ticket-style-1__descr-text">(98) 98443-3642</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-4 vc_col-md-6 vc_col-sm-offset-0 vc_col-xs-6">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="ticket-style-1-wrapp">
                                                                        <div class="ticket-style-1 " style={{ backgroundColor: "#ffffff", width: 200 }}>
                                                                            <div class="ticket-style-1__img">
                                                                                <i class="fa fa-envelope " aria-hidden="true" style={{ color: "#c21111", fontSize: 40 }} />
                                                                            </div>
                                                                            <div class="ticket-style-1__descr ">
                                                                                <h3 class="ticket-style-1__title" style={{ color: "#000000" }}>EMAIL</h3>
                                                                                <div class="ticket-style-1__descr-text">procompetidor@gmail.com</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-4 vc_col-md-6 vc_col-sm-offset-0 vc_col-xs-6">
<a target='_blank' href={`https://api.whatsapp.com/send?phone=55${Object.keys(configuracoes).length > 0 ? configuracoes.whatsapp.numero : ''}&text=${Object.keys(configuracoes).length > 0 ? configuracoes.whatsapp.mensagem : ''}`}>                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="ticket-style-1-wrapp">
                                                                        <div class="ticket-style-1 " style={{ backgroundColor: "#ffffff" }}>
                                                                            <div class="ticket-style-1__img">
                                                                                <i class="fa fa-whatsapp " aria-hidden="true" style={{ color: "#c21111", fontSize: 40 }} />
                                                                            </div>
                                                                            <div class="ticket-style-1__descr ">
                                                                                <h3 class="ticket-style-1__title" style={{ color: "#000000" }}>WHATSAPP</h3>
                                                                                <div class="ticket-style-1__descr-text">{formataNumero()}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 20 }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                        <h2>Envie email</h2>
                                                    </div>
                                                    <div style={{ marginTop: 20 }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                        <div class="wpcf7-form" novalidate="novalidate">
                                                            <p class="contact-form-name">
                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        value={nome}
                                                                        onChange={handleNome}
                                                                        placeholder="Nome" />
                                                                </span>
                                                            </p>
                                                            <p class="contact-form-email">
                                                                <span
                                                                    class="wpcf7-form-control-wrap your-email">
                                                                    <input type="email"
                                                                        name="your-email" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                        aria-required="true" aria-invalid="false"
                                                                        value={email}
                                                                        onChange={handleEmail}
                                                                        placeholder="Email" />
                                                                </span>
                                                            </p>
                                                            <p class="contact-form-subject">
                                                                <span
                                                                    class="wpcf7-form-control-wrap your-subject">
                                                                    <input type="text"
                                                                        name="your-subject" size="40"
                                                                        class="wpcf7-form-control wpcf7-text" aria-invalid="false"
                                                                        value={assunto}
                                                                        onChange={handleAssunto}
                                                                        placeholder="Assunto" />
                                                                </span>
                                                            </p>
                                                            <p class="contact-form-text">
                                                                <span
                                                                    class="wpcf7-form-control-wrap your-message">
                                                                    <textarea name="your-message"
                                                                        cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea"
                                                                        value={mensagem}
                                                                        onChange={handleMensagem}
                                                                        aria-invalid="false" placeholder="Sua Mensagem">
                                                                    </textarea>
                                                                </span>
                                                            </p>
                                                            <div onClick={() => enviaEmail()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                <input type="submit" value="Enviar Email" class="wpcf7-form-control " />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
