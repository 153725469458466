import {
    useState, useEffect
} from 'react'
import base64 from 'react-native-base64'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker2';
import { buscaEndereco } from '../service/WsConect'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ReactHtmlParser from 'react-html-parser';

export default function Evento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes"),
        inscricoes: firebase.firestore().collection("inscricoes")
    })
    const [equipe, setEquipe] = useState("")
    const [professor, setProfessor] = useState("")
    const [inscricoes, setInscricoes] = useState([])
    const [usuario, setUsuario] = useState({})


    const [dataNascimento, setDataNascimento] = useState('')

    useEffect(() => {
        async function carregaDados() {
            servicos.auth.onAuthStateChanged((user) => {
                let id = JSON.parse(localStorage.getItem('usuarioId'))
                if (user) {
                    servicos.usuarios.doc(user.uid).get().then((doc) => {
                        setUsuario(doc.data())
                        document.title = 'Conheça o(a) competidor(a) '+doc.data().nome+' '+doc.data().sobrenome
                        document.getElementsByTagName("META")[2].content="Olá venha me conhecer no portal do Procompetidor";
                        servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                            setEquipe(e.data().equipe)
                        })
                        servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                            setProfessor(e.data().professor)
                        })
                    })
                    servicos.inscricoes.where('usuario.id', '==', user.uid).onSnapshot((snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)
                    })
                }else if(id){
                    servicos.usuarios.doc(id).get().then((doc) => {
                        setUsuario(doc.data())
                        document.title = 'Conheça o(a) competidor(a) '+doc.data().nome+' '+doc.data().sobrenome
                        document.getElementsByTagName("META")[2].content="Olá venha me conhecer no portal do Procompetidor";
                        servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                            setEquipe(e.data().equipe)
                        })
                        servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                            setProfessor(e.data().professor)
                        })
                    })
                    servicos.inscricoes.where('usuario.id', '==', id).onSnapshot((snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)
                    })
                }
            })
        }
        carregaDados();

    }, [])

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>{usuario ? usuario.nome : null}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Visualize seu perfil e verifique suas conquistas</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="package-style-1 pnchr-package clearfix vc_col-sm-4">
                                                <div >
                                                    <img src={usuario.avatar} alt="" title="image-here-1" />
                                                </div>
                                            </div>
                                            <div role="form" class="wpcf7 row vc_col-sm-8" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-12">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h2>{usuario.nome} {usuario.sobrenome}</h2><br />
                                                        <div className="ratings-widget__details-title" ><h4 style={{ color: "#585858" }}>Email: {usuario.email}</h4></div>
                                                        <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Contato: {usuario.ddd} {usuario.celular}</h4></div>
                                                        <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>De {usuario.endereco ? usuario.endereco.cidade : null} - {usuario.endereco ? usuario.endereco.uf : null}</h4></div>
                                                        <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>{usuario.sexo}</h4></div>
                                                        <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Equipe {equipe}</h4></div>
                                                        <div className="ratings-widget__details-title"><h4 style={{ color: "#585858" }}>Professor {professor}</h4></div><br />
                                                        <h4>Mais sobre {usuario.nome}</h4>
                                                        <div class="wpb_wrapper">
                                                            {usuario.biografia ? ReactHtmlParser(usuario.biografia) : <h6>Sem informações ainda</h6>}
                                                        </div>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Conquistas</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                                {inscricoes.filter((i) => i.status === 2 && i.conquista > 0).map((inscricao) =>
                                                    <div class="wpb_column vc_col-sm-3 vc_col-lg-3 vc_col-md-6 vc_col-xs-12">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="ticket-style-1-wrapp">
                                                                    <div class="ticket-style-1 " style={{ backgroundColor: "#ffffff", width: 200 }}>
                                                                        {inscricao.conquista === 1 ?
                                                                            <div class="ticket-style-1__img">
                                                                                <i class="fa fa-trophy " aria-hidden="true" style={{ color: "#FFBF00", fontSize: 40 }} />
                                                                            </div>
                                                                            : inscricao.conquista === 2 ?
                                                                                <div class="ticket-style-1__img">
                                                                                    <i class="fa fa-trophy " aria-hidden="true" style={{ color: "#A4A4A4", fontSize: 40 }} />
                                                                                </div>
                                                                                :
                                                                                <div class="ticket-style-1__img">
                                                                                    <i class="fa fa-trophy " aria-hidden="true" style={{ color: "#B18904", fontSize: 40 }} />
                                                                                </div>
                                                                        }
                                                                        <div class="ticket-style-1__descr ">
                                                                            <h3 class="ticket-style-1__title" style={{ color: "#000000" }}>{inscricao.conquista === 1 ? "1° Lugar" : inscricao.conquista === 2 ? "2° Lugar" : inscricao.conquista === 3 ? "3° Lugar" : null}</h3>
                                                                            <div class="ticket-style-1__descr-text" style={{ marginLeft: 5, marginRight: 5 }}>{inscricao.evento.nome}</div>
                                                                            <div class="ticket-style-1__descr-text" style={{ marginLeft: 5, marginRight: 5 }}><p>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( Até " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + "( De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"}</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Participações</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                                {inscricoes.filter((i) => i.status === 2).map((inscricao) =>
                                                    <div class="wpb_column vc_col-sm-3 vc_col-lg-3 vc_col-md-6 vc_col-xs-12">
                                                        <div class="vc_column-inner ">
                                                            <div class="wpb_wrapper">
                                                                <div class="ticket-style-1-wrapp">
                                                                    <div class="ticket-style-1 " style={{ backgroundColor: "#ffffff", width: 200 }}>
                                                                        <div class="ticket-style-1__img">
                                                                            <i class="fa fa-bookmark " aria-hidden="true" style={{ color: "#c21111", fontSize: 40 }} />
                                                                        </div>
                                                                        <div class="ticket-style-1__descr ">
                                                                            <h3 class="ticket-style-1__title" style={{ color: "#000000" }}>
                                                                                {inscricao.evento.nome}</h3>
                                                                            <div class="ticket-style-1__descr-text" style={{ marginLeft: 5, marginRight: 5 }}>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( Até " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + "( De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
