import {
    useState, useEffect
} from 'react'
import base64 from 'react-native-base64'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import { buscaEndereco } from '../service/WsConect'
import { cpf } from 'cpf-cnpj-validator';
import ModalEquipes from '../componentes/ModalEquipes'

export default function Evento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes"),
        mail: firebase.firestore().collection('mail')
    })
    const [listaDia, setListaDia] = useState([
        { dia: '01' },
        { dia: '02' },
        { dia: '03' },
        { dia: '04' },
        { dia: '05' },
        { dia: '06' },
        { dia: '07' },
        { dia: '08' },
        { dia: '09' },
        { dia: '10' },
        { dia: '11' },
        { dia: '12' },
        { dia: '13' },
        { dia: '14' },
        { dia: '15' },
        { dia: '16' },
        { dia: '17' },
        { dia: '18' },
        { dia: '19' },
        { dia: '20' },
        { dia: '21' },
        { dia: '22' },
        { dia: '23' },
        { dia: '24' },
        { dia: '25' },
        { dia: '26' },
        { dia: '27' },
        { dia: '28' },
        { dia: '29' },
        { dia: '30' },
        { dia: '31' },
        { dia: '32' },
    ])
    const [listaMes] = useState([
        { mes: 'Janeiro', number: '01' },
        { mes: 'Fevereiro', number: '02' },
        { mes: 'Março', number: '03' },
        { mes: 'Abril', number: '04' },
        { mes: 'Maio', number: '05' },
        { mes: 'Junho', number: '06' },
        { mes: 'Julho', number: '07' },
        { mes: 'Agosto', number: '08' },
        { mes: 'Setembro', number: '09' },
        { mes: 'Outubro', number: '10' },
        { mes: 'Novembro', number: '11' },
        { mes: 'Dezembro', number: '12' },
    ])
    const [listaAno, setListaAno] = useState([])
    const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])
    const [usuario, setUsuario] = useState({})
    const [modalShow, setModalShow] = useState(false);
    const [dadosEquipe, setDadosEquipe] = useState({})



    useEffect(() => {
        const list = []
        let d = 1940
        for (let index = 0; index < d; index++) {
            const element = parseInt(d) + parseInt(list.length)
            if (element === 2030) {
                break;
            } else {
                list.push(element)
            }
        }
        setListaAno(list)
        servicos.auth.onAuthStateChanged((user) => {
            let id = JSON.parse(localStorage.getItem('usuarioId'))
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setEquipe(doc.data().equipe)
                    setProfessor(doc.data().id)
                    setUsuario(doc.data())
                })
            }else if(id){
                servicos.usuarios.doc(id).get().then((doc) => {
                    setEquipe(doc.data().equipe)
                    setProfessor(doc.data().id)
                    setUsuario(doc.data())
                })
            }
        })
        servicos.equipes.where('status','==',1).get().then((snap) => {
            const data = snap.docs.map((doc) => doc.data());
            const l = []
            const lFinal = []
            data.forEach((d) => {
                l.push(d.nome)
            })
            const array = [...new Set(l)]
            array.forEach((d) => {
                lFinal.push(data.filter((a) => a.nome === d)[0])
            })
            setEquipes(lFinal.sort((a, b) => {
                if (a.equipe < b.equipe)
                    return -1;
                if (a.equipe > b.equipe)
                    return 1;
                return 0;
            }))
        })
    }, [])

    const [dataAtual] = useState(moment().format('YYYYMMDD'))
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [numeroCpf, setCpf] = useState('')
    const [dia, setDia] = useState('')
    const [mes, setMes] = useState('')
    const [ano, setAno] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    const [equipe, setEquipe] = useState('')
    const [professor, setProfessor] = useState('')
    const [inputEquipe, setInputEquipe] = useState('')
    const [inputProfessor, setInputProfessor] = useState('')

    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')
    const [dica, setDica] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')
    const [cadastro, setCadastro] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)

    function cadastraAvatar(id) {
        Swal.close();
        mensagemLoading('Salvando imagem do avatar do(a) competidor(a)')
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('usuarios/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    setCadastro(true)
                    mensagemSucesso('Cadastrado de competidor realizado com sucesso!')
                })
            });
        });
    }

    function cadastraUsuario() {
        setLoading(true)
        if (nome === '') {
            mensagemAlerta('Favor digitar seu primeiro nome')
            setLoading(false)
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar seu sobrenome')
            setLoading(false)
        } else if (!dia) {
            mensagemAlerta('Escolha o dia de Nascimento')
            setLoading(false)
        } else if (!mes) {
            mensagemAlerta('Favor escolha o Mês de Nascimento')
            setLoading(false)
        } else if (!ano) {
            mensagemAlerta('Favor escolha o Ano de Nascimento')
            setLoading(false)
        } else if (dia + '/' + mes + '/' + ano === moment().format('DD/MM/YYYY')) {
            mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
            setLoading(false)
        }
        // else if (moment(dataNascimento).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        //     mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
        //     setLoading(false)
        // } 
        else if (sexo === '') {
            mensagemAlerta('Favor escolha seu sexo')
            setLoading(false)
        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
            setLoading(false)
        } else if (senha === '' || senha.length < 8) {
            mensagemAlerta('Senha vazia ou não atende os termos! Favor digite uma senha forte e no mínimo 8 dígitos')
            setLoading(false)
        } else if (confirmaSenha === '') {
            mensagemAlerta('Confirme sua senha')
            setLoading(false)
        } else if (senha !== confirmaSenha) {
            mensagemAlerta('As senhas não são iguais. Favor tente novamente')
            setLoading(false)
        } else if (dica === '') {
            mensagemAlerta('Digite a dica da senha para ser melhor lembrado')
            setLoading(false)
        } else {
            if (equipe === '0') {
                if (inputEquipe === '' || inputProfessor === '') {
                    mensagemAlerta('Favor diga o nome de sua equipe e o professor que o acompanha! Os dados serão salvos no sistema')
                } else {
                    mensagemLoading('Cadastrando equipe e professor...')
                    servicos.equipes.where('nome', '==', inputEquipe.replace(/[^a-z0-9]/gi, '').toUpperCase()).get().then((s) => {
                        if (s.size > 0) {
                            mensagemAlerta('Esta equipe já está cadastrada em nosso sistema')
                        } else {
                            servicos.equipes.add({
                                equipe: inputEquipe.toUpperCase(),
                                dataCadastro: moment().format('DD/MM/YYYY'),
                                dataAtualizacao: moment().format('DD/MM/YYYY'),
                                nome: inputEquipe.replace(/[^a-z0-9]/gi, '').toUpperCase(),
                                status: parseInt(1),
                                urlImagem: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
                            }).then((docRef) => {
                                setEquipe(docRef.id)
                                servicos.equipes.doc(docRef.id).update({
                                    id: docRef.id
                                }).then(() => {
                                    servicos.equipes.doc(docRef.id).collection('professores').add({
                                        professor: inputProfessor
                                    }).then((refProfessor) => {
                                        setProfessor(refProfessor.id)
                                        servicos.equipes.doc(docRef.id).collection('professores').doc(refProfessor.id).update({
                                            id: refProfessor.id
                                        }).then(() => {
                                            Swal.close()
                                            cadastraEmail(docRef.id, refProfessor.id)
                                        }).catch(() => {
                                            console.log('Erro ao atualizar professor')
                                        })
                                    }).catch(() => {
                                        console.log('Erro ao cadastrar professor')
                                    })
                                }).catch(() => {
                                    console.log('Erro ao atualizar equipe')
                                })
                            }).catch(() => {
                                console.log('Erro ao cadastrar Equipe')
                            })
                        }
                    })
                }
            } else if (professor === '0') {
                if (inputProfessor === '') {
                    mensagemAlerta('Favor diga o nome do seu professor! Caso você seja o professor é só digitar seu nome')
                } else {
                    mensagemLoading('Cadastrando professor...')
                    servicos.equipes.doc(equipe).collection('professores').add({
                        professor: inputProfessor
                    }).then((refProfessor) => {
                        setProfessor(refProfessor.id)
                        servicos.equipes.doc(equipe).collection('professores').doc(refProfessor.id).update({
                            id: refProfessor.id
                        }).then(() => {
                            Swal.close()
                            cadastraEmail('', refProfessor.id)
                        }).catch(() => {
                            console.log('Erro ao atualizar professor')
                        })
                    }).catch(() => {
                        console.log('Erro ao cadastrar professor')
                    })
                }
            } else {
                if (equipe === '' || professor === '') {
                    mensagemAlerta('Favor selecione sua equipe e professor')
                } else {
                    cadastraEmail('', '')
                }
            }
        }

    }

    function cadastraEmail(equipeId, professorId) {
        mensagemLoading('Preparando seu cadastro...')
        servicos.usuarios.where('cpf', '==', numeroCpf.replace(/[^a-z0-9]/gi, '')).get().then((snap) => {
            if (snap.size === 0) {
                servicos.auth.createUserWithEmailAndPassword(email, confirmaSenha).then((user) => {
                    servicos.usuarios.doc(user.user.uid).set({
                        id: user.user.uid,
                        nome: nome.toUpperCase(),
                        sobrenome: sobrenome.toUpperCase(),
                        cpf: numeroCpf.replace(/[^a-z0-9]/gi, ''),
                        ddd: ddd,
                        celular: celular,
                        email: email,
                        biografica: '',
                        dica: dica.toUpperCase(),
                        editaEquipe: parseInt(1),
                        dataNascimento: ano + '-' + mes + '-' + dia,
                        endereco: {
                            endereco: endereco.toUpperCase(),
                            bairro: bairro.toUpperCase(),
                            numero: numero,
                            complemento: complemento ? complemento.toUpperCase() : '',
                            uf: uf.toUpperCase(),
                            cidade: cidade.toUpperCase(),
                            cep: cep.replace(/[^a-z0-9]/gi, '')
                        },
                        tipoUsuario: 'competidor',
                        equipe: equipeId ? equipeId : equipe,
                        professor: professorId ? professorId : professor,
                        status: parseInt(1),
                        sexo: sexo,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                        usuario: parseInt(2),
                        pass: base64.encode(confirmaSenha + user.user.uid),
                    }).then(() => {
                        Swal.close()
                        cadastraAvatar(user.user.uid)
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar o evento')
                    })
                }).catch((erro) => {
                    var errorCode = erro.code;
                    if (errorCode == "auth/invalid-email") {
                        mensagemAlerta('O formato do Email é inválido!')
                        setLoading(false)
                    } else if (errorCode == "auth/email-already-in-use") {
                        mensagemErro('O email já está em uso pelo Procompetidor')
                        setLoading(false)
                    } else if (errorCode == "auth/weak-password") {
                        mensagemErro('Escolha uma senha mais forte!')
                        setLoading(false)
                    }
                })
            } else {
                Swal.close();
                mensagemAlerta('CPF já cadastrado no sistema! Verifique novamente ou faça login')
            }
        })

    }



    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            if (cpf.isValid(event.target.value) === false) {
                mensagemAlerta('CPF incorreto! Verifique novamente')
            } else {
                mensagemLoading('Aguarde, verificando o seu CPF...')
                servicos.usuarios.where('cpf', '==', event.target.value).get().then((snap) => {
                    if (snap.size === 0) {
                        Swal.close();
                        mensagemSucessoCpf('Seu CPF está disponível')
                    } else {
                        Swal.close();
                        mensagemAlerta('CPF já cadastrado no sistema! Verifique novamente ou faça login')
                    }
                })
            }
        }
    }
    const handleDia = (event) => {
        event.persist();
        setDia(event.target.value);
    }
    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleDataNascimmento = (event) => {
        event.persist();
        setDataNascimento(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleSexo = (event) => {
        event.persist();
        setSexo(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.replace(/[^a-z0-9]/gi, '').length === 8) {
            buscaCep(event.target.value.replace(/[^a-z0-9]/gi, ''));
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleEquipe = (event) => {
        setProfessor('')
        setInputEquipe('')
        setInputProfessor('')
        event.persist();
        setEquipe(event.target.value);
        if (event.target.value !== '') {
            mensagemLoading('Carregando professores..')
            servicos.equipes.doc(event.target.value).collection('professores').onSnapshot((snap) => {
                const data = snap.docs.map((doc) => doc.data());
                setProfessores(data)
                Swal.close();
            })
        }
    }
    const handleProfessor = (event) => {
        event.persist();
        setProfessor(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep(c) {
        mensagemLoading('Aguarde procurando CEP...')
        buscaEndereco(c).then((doc) => {
            if (doc.erro === true) {
                setLoadingCep(false)
                mensagemErro('Não achamos seu CEP! Verifique novamente ou digite manualmente')
            } else {
                Swal.close();
                setEndereco(doc.logradouro)
                setBairro(doc.bairro)
                setCidade(doc.localidade)
                setUf(doc.uf)
                setLoadingCep(false)
            }
        })
    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }
    const handleDica = (event) => {
        event.persist();
        setDica(event.target.value);
    }
    const handleInputEquipe = (event) => {
        event.persist();
        setInputEquipe(event.target.value);
    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            const corpo = {
                to: email,
                from: 'Bem Vindo ao Procompetidor',
                message: {
                    subject: 'Novo Cadastro',
                    html: '<html>' +
                        '<body>' +
                        '<h1><b>Olá, ' + nome + ' </b></h1>' +
                        '<h2><b>Seu cadastrado foi realizado com sucesso em nossa plataforma</b></h2>' +
                        '<h4><b>Aproveite toda a nossa plataforma para administrar suas lutas<b/></h4>' +
                        '<p><b>Equipe Procompetidor<b/></p>' +
                        '</body>' +
                        '</html>'
                }
            }
            servicos.mail.add(corpo).then(() => {
                Swal.close();
                window.location = '/'
            }).catch(() => {
                Swal.close();
                window.location = '/'
            })

        })
    }
    function mensagemSucessoCpf(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Cadastre um Competidor</h1>
                            </div>
                            <div class="headline__description">
                                <span>Cadastre um novo competidor para sua equipe</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados Pessoais</h3>
                                                <p><b>Cadastre abaixo os dados de seu competidor. O mesmo será alocado automaticamente a sua equipe. Todos os dados são referentes ao seu Competidor.</b></p>
                                                <p style={{ color: "red" }}><b>Os campos com * são obrigatórios</b></p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="package-style-1 pnchr-package clearfix vc_col-sm-4">
                                                <div >
                                                    {upload ?
                                                        <img src={upload} alt="" title="image-here-1" />
                                                        :
                                                        <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" alt="" title="image-here-1" />
                                                    }
                                                </div>
                                                <input onChange={handleAvatar} accept=".png, .jpg, .jpeg" type="file" name="name" placeholder="Escolha a imagem " />
                                            </div>
                                            <div role="form" class="wpcf7 row " id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nome *</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNome}
                                                            value={nome.toUpperCase()}
                                                            placeholder="Nome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sobrenome *</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleSobrenome}
                                                            value={sobrenome.toUpperCase()}
                                                            placeholder="Sobrenome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CPF</h6>
                                                        <input
                                                            max={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCpf}
                                                            value={numeroCpf}
                                                            placeholder="CPF" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Dia</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleDia} >
                                                            <option value="">Dia do Nascimento </option>
                                                            {listaDia.map((dia) =>
                                                                <option value={dia.dia}>{dia.dia}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Mês de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleMes} >
                                                            <option value="">Mês do Nascimento </option>
                                                            {listaMes.map((mes) =>
                                                                <option value={mes.number}>{mes.mes.toUpperCase()}</option>
                                                            )}
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Ano de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleAno} >
                                                            <option value="">Ano do Nascimento </option>
                                                            {listaAno.map((ano) =>
                                                                <option value={ano}>{ano}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                {/* <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Data Nascimento *</h6>
                                                        <input
                                                            type="date" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDataNascimmento}
                                                            value={dataNascimento} />
                                                    </span>
                                                </p> */}
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>DDD</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDDD}
                                                            value={ddd}
                                                            maxLength={2}
                                                            placeholder="DDD *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Celular</h6>
                                                        <input
                                                            maxLength={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCelular}
                                                            value={celular}
                                                            placeholder="9 + Celular *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sexo *</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleSexo} >
                                                            <option value="">Escolha o Sexo </option>
                                                            <option value="masculino" >MASCULINO </option>
                                                            <option value="feminino" >FEMININO </option>
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Email</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEmail}
                                                            value={email}
                                                            placeholder="Email" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Observação</h6>
                                                        <p>Caso o seu competidor tenha um email digite-o, o email servirá para criação do login</p>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Endereço</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CEP</h6>
                                                        <input
                                                            max={8}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCep}
                                                            value={cep}
                                                            placeholder="CEP *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Endereço</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEndereco}
                                                            value={endereco.toUpperCase()}
                                                            placeholder="Endereço *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Bairro</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleBairro}
                                                            value={bairro.toUpperCase()}
                                                            placeholder="Bairro *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Cidade</h6>
                                                        <input
                                                            disabled
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCidade}
                                                            value={cidade.toUpperCase()}
                                                            placeholder="Cidade *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Estado</h6>
                                                        <input
                                                            disabled
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleUf}
                                                            value={uf.toUpperCase()}
                                                            placeholder="UF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Complemento</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleComplemento}
                                                            value={complemento.toUpperCase()}
                                                            placeholder="Complemento *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>N°</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNumero}
                                                            value={numero}
                                                            placeholder="N° *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Equipe</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                    <input type='text' name="select" 
                                                        class="form-control"
                                                        value={dadosEquipe?.equipe ? dadosEquipe?.equipe : ''}
                                                        placeholder='Selecione uma equipe'
                                                        style={{cursor:"pointer"}}
                                                          onClick={() => setModalShow(true)}
                                                        />                                                        
                                                    </span>
                                                </p>
                                            </div>
                                            {equipe && equipe !== "0" ?
                                                <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                    <div class="screen-reader-response"></div>
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Seu Professor</h6>
                                                            <select name="select" id="select" class="form-control" onChange={handleProfessor} >
                                                                <option value="">Escolha seu Professor </option>
                                                                <option value="0">Não está na lista </option>
                                                                {professores.sort((a, b) => {
                                                                    if (a.professor < b.professor)
                                                                        return -1;
                                                                    if (a.professor > b.professor)
                                                                        return 1;
                                                                }).map((eq) =>
                                                                    <option value={eq.id}>{eq.professor.toUpperCase()}</option>
                                                                )}
                                                            </select>
                                                        </span>
                                                    </p>
                                                </div>
                                                :
                                                null
                                            }
                                            {equipe === "0" || professor === "0" ?
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nome do Professor</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleInputProfessor}
                                                            value={inputProfessor.toUpperCase()}
                                                            placeholder="Digite o nome do professor *" />
                                                    </span>
                                                </p>
                                                :
                                                null
                                            }
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de acesso</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleSenha}
                                                            value={senha}
                                                            placeholder="Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Confirma Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleConfirmaSenha}
                                                            value={confirmaSenha}
                                                            placeholder="Confirma Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Uma dica para lembrar de sua senha</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDica}
                                                            value={dica.toUpperCase()}
                                                            placeholder="Dica de senha *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                    <input type="submit" onClick={() => cadastraUsuario()} value="Cadastrar" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
            {modalShow && (
                <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={setEquipe} setDadosEquipe={setDadosEquipe} setProfessores={setProfessores} setDadosProfessor={()=>{}}
                />
            )}            
        </div>
    );
}
