import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'


export default function Sobre() {
    const [servicos] = useState({
        ranking: firebase.firestore().collection("ranking")
    })
    const [ranknig, setRanknig] = useState([])
    const [loading, setLoading] = useState(true)
    const [lista, setLista] = useState([])
    const [tab, setTab] = useState("")

    useEffect(() => {
        servicos.ranking.onSnapshot((snap) => {
            const data = snap.docs.map(e => e.data())
            setRanknig(data);
            setLoading(false)
        })
    }, [])

    function detalhe(id) {
        window.location = 'detalheRanking/'+id
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Ranking</h1>
                            </div>
                            <div class="headline__description">
                                <span>Acompanhe abaixo o ranking dos melhores do ano, mês ou modalidade </span>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="section_default">
                    <div className="vc_row wpb_row vc_row-fluid">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="js-carusel-wrapp clearfix">
                                        <div className="carusel-empty-space" style={{ height: 24 }}></div>
                                        {ranknig.length > 0 ?
                                            <div className="pnchrOwlCarousel_5ff0a67aea27d  row">
                                                {ranknig.map((evento) =>
                                                    <div key={evento.id} className="vc_col-sm-3 ">
                                                        <div className="vizit-cart-item " style={{ backgroundColor: "#ffffff", height: 450 }}>
                                                            <Link to="#" onClick={() => detalhe(evento.id)}>
                                                                <div className="vizit-cart-item__img">
                                                                    <div className="vizit-cart-item__img-overlay"></div>
                                                                    <div className="vizit-cart-item__img-overlay-share">
                                                                        <p style={{ color: "#ffffff" }}>Clique aqui para acessar o ranking {evento.nome}</p>
                                                                    </div><img
                                                                        src={evento.avatar?evento.avatar:'https://cdn-icons-png.flaticon.com/512/1603/1603847.png'}
                                                                        style={{ height: 300, width: 300 }}
                                                                        alt="" title="image-here" />
                                                                </div>
                                                            </Link>
                                                            <div className="vizit-cart-item__descr">
                                                                <div className="vizit-cart-item__title" style={{ color: "#121212" }}>
                                                                    <i className="fa  fa-bookmark" aria-hidden="true"
                                                                        style={{ color: "#c21111" }}></i>{evento.nome}</div>
                                                                <p><i className="fa fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i>Dia {moment(evento.dataCadastro).format('DD/MM/YYYY')}</p>

                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <>
                                                {loading ?
                                                    <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                        style={{ color: "#c21111" }}></i> Aguarde... Carregando Ranking </h2>
                                                    :
                                                    <h2>Sem ranking disponíveis </h2>
                                                }

                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
}
