import firebase from 'firebase'

// -----Configuração de Produção
const firebaseConfig = {
    apiKey: "AIzaSyD_1KnH9alSyfMWvJM0dYezJRfERYjZj54",
    authDomain: "procompetidor-7867b.firebaseapp.com",
    projectId: "procompetidor-7867b",
    storageBucket: "procompetidor-7867b.appspot.com",
    messagingSenderId: "810225893140",
    appId: "1:810225893140:web:08d200016ba2091f574c01"
};


// ------Configuração genérica para utilizar o Emulador Firebase

// const firebaseConfig = {
//     apiKey: "xxx",
//     authDomain: "xxx",
//     projectId: "demo-teste",
//     storageBucket: "gs://demo-teste.appspot.com",
//     messagingSenderId: "xxx",
//     appId: "xxx",
//     databaseUrl: 'http://127.0.0.1:4000/?ns=demo-teste'
//   };


// Inicalizar o Firebase
firebase.initializeApp(firebaseConfig);

// Inicalizar os serviços Firebase. 
// A configuração adicional useEmulator() permite que qualquer utilização dos serviços firebase seja redirecionada para o emulador e não para o banco de produção. Para utilizar a aplicação em produção basta remover estas linhas e mudar o objeto firebaseConfig para a versão original

// Para subir o firebase emulator, baseta executar o seguinte comando no terminal de comando:
// firebase emulators:start --project=demo-teste --only 'firestore,auth,storage' --import=./src/service/importacaoFirebaseEmulator --export-on-exit

// const firebaseFirestore = firebase.firestore();
// firebaseFirestore.useEmulator("127.0.0.1",8080); //comentar esta linha ao subir pra produção

// const firebaseAuth = firebase.auth();
// firebaseAuth.useEmulator("http://localhost:9099"); //comentar esta linha ao subir pra produção

// const firebaseStorage = firebase.storage();
// firebaseStorage.useEmulator("127.0.0.1",9199); //comentar esta linha ao subir pra produção

// const firebaseFunctions = firebase.functions();
// firebaseFunctions.useEmulator("127.0.0.1",5001);//comentar esta linha ao subir pra produção

// export { firebaseFirestore, firebaseAuth, firebaseStorage };

export const verificaAutenticado = (contexto) => {

    firebase.auth().onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
            if (contexto === 'login') {
                firebase.firestore().collection('usuarios').doc(firebaseUser.uid).get().then((doc) => {
                    if (doc.data().usuario === 0) {
                        window.location = '/administrador'
                    } else{
                        window.location = '/home'
                    }
                })
            } else {

            }
        } else {
            if (contexto !== 'login') {
                window.location = '/';
            }
        }
    });
}

export const logout = () => {
    firebase.auth().signOut().then(() => {
        window.location = '/'
    })
}