import {
    useState, useEffect
} from 'react'
import base64 from 'react-native-base64'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Swal from 'sweetalert2'

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
    })

    const [cpf, setCpf] = useState('')
    const [emailOld, setEmailOld] = useState('')
    const [validaSenha, setValidaSenha] = useState(false)
    const [validaEmail, setValidaEmail] = useState(false)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [dica, setDica] = useState('')

    function login() {
        // console.log(base64.decode('Y3VuaGExOTk1UHBUeEtwQ3VnZld3OEM1eU4zNUtnUjVwQnBtMQ=='))
        if (senha === '') {
            mensagemAlerta('Digite a senha')
        } else {
            mensagemLoading('Realizando login')
            servicos.auth.signInWithEmailAndPassword(email, senha).then(() => {
                servicos.auth.onAuthStateChanged((usuario) => {
                    servicos.usuarios.doc(usuario.uid).update({
                        pass: base64.encode(senha + usuario.uid)
                    })
                    servicos.usuarios.doc(usuario.uid).get().then((doc) => {
                        if (doc.data().usuario === 2) {
                            if (!doc.data().editaEquipe) {
                                window.location = '/mudaEquipe/' + doc.data().id
                            } else {
                                servicos.equipes.doc(doc.data().equipe).get().then((eq) => {
                                    servicos.inscricoes
                                        .where('usuario.id', '==', usuario.uid)
                                        .get().then((us) => {
                                            if (us.size > 0) {
                                                us.forEach((d) => {
                                                    console.log(d.data())
                                                    servicos.eventos.doc(d.data().evento.id).get().then((e) => {
                                                        if (e.data().status !== 1) {
                                                            servicos.inscricoes.doc(d.id).update({
                                                                equipe: {
                                                                    avatar: eq.data().avatar ? eq.data().avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                                                    equipe: eq.data().id,
                                                                    professor: doc.data().professor,
                                                                    nomeEquipe: eq.data().equipe,
                                                                    // nomeProfessor: dadosProfessor.professor
                                                                }
                                                            })
                                                        }
                                                    })
                                                })
                                                setTimeout((
                                                    window.location = '/'
                                                ), 10000)
                                            }else{
                                                window.location = '/'
                                            }
                                        })
                                })

                            }
                        } else {
                            servicos.auth.signOut().then(() => {
                                mensagemErro('Usuário não autorizado para acesso! Se você é administrador, acesse o sistema do Procompetidor')
                            })
                        }
                    })
                });
            }).catch((error) => {
                var errorCode = error.code;
                if (error) {
                    console.log(errorCode)
                    Swal.close();
                    if (errorCode === 'auth/invalid-email') {
                        mensagemAlerta('Email incompleto ou não é válido')
                    } else if (errorCode === 'auth/user-not-found') {
                        mensagemAlerta('Email não cadastrado em nosso Sistema! Cadastre-se ou verifique novamente')
                    } else if (errorCode === 'auth/wrong-password') {
                        mensagemAlerta('Senha Incorreta')
                    }
                }
            });
        }
    }

    function recuperaSenha() {
        if (!cpf) {
            mensagemAlerta('Favor digite seu CPF')
            return
        }
        if (cpf.length < 11) {
            mensagemAlerta('CPF incompleto')
            return
        }
        mensagemLoading('Enviando email...')
        servicos.usuarios.where('cpf', '==', cpf.replace(/[^a-z0-9]/gi, '')).get().then((snap) => {
            if (snap.size === 0) {
                Swal.close();
                mensagemAlerta('CPF não consta em nosso sistema! Verifique novamente ou entre em contato com a Administração')
            } else {
                Swal.close();
                const email = snap.docs.map((d) => d.data())[0].email
                console.log(email)
                servicos.auth.sendPasswordResetEmail(email).then(() => {
                    mensagemSucesso('Email enviado com sucesso para ' + email + ' ! Verifique sua caixa de email e faça os passos para recuperação de senha ')
                }).catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;

                });
            }
        })
    }

    function recuperaEmail() {
        if (!cpf) {
            mensagemAlerta('Favor digite seu CPF')
            return
        }
        if (cpf.length < 11) {
            mensagemAlerta('CPF incompleto')
            return
        }
        mensagemLoading('Buscando Email...')
        servicos.usuarios.where('cpf', '==', cpf).get().then((snap) => {
            if (snap.size === 0) {
                Swal.close();
                mensagemAlerta('CPF não consta em nosso sistema! Verifique novamente ou entre em contato com a Administração')
            } else {
                Swal.close();
                const email = snap.docs.map((d) => d.data())[0].email
                mensagemSucesso('Seu email é \n\n' + email)
                setEmail(email)
            }
        })
    }

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            setValidaSenha(false)
            setValidaEmail(false)
            setCpf('')
        })
    }
    function mensagemSucessoEmail(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />

                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class=" vc_col-sm-8">
                                                <div class="wpb_single_image wpb_content_element vc_align_left">

                                                    <figure class="wpb_wrapper vc_figure">
                                                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                            <img width="1920" height="1401"
                                                                src="https://wallpaperaccess.com/full/236559.jpg"
                                                                class="vc_single_image-img attachment-full" alt=""
                                                                srcset="https://wallpaperaccess.com/full/236559.jpg 1920w, https://wallpaperaccess.com/full/236559.jpg 300w, https://wallpaperaccess.com/full/236559.jpg 1024w, https://wallpaperaccess.com/full/236559.jpg 768w, https://wallpaperaccess.com/full/236559.jpg 1536w"
                                                                sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                                    </figure>
                                                </div>
                                            </div>
                                            {validaSenha ?
                                                <div class="wpb_column vc_column_container  vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="wpb_widgetised_column wpb_content_element">
                                                                <div class="wpb_wrapper">
                                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                        <h2>Recupera Senha</h2>
                                                                    </div>
                                                                    <p>Digite seu seu CPF. Será enviado um email de recuperação de senha em seu Email cadastrado</p>
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleCpf}
                                                                        value={cpf}
                                                                        placeholder="CPF" />
                                                                    <div onClick={() => recuperaSenha()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                        <input type="submit" value="Recuperar Senha" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                    </div>
                                                                    <a href="javascript:void(0)" onClick={() => setValidaSenha(false)} style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Possui login? Clique aqui</h6>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="wpb_column vc_column_container  vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="wpb_widgetised_column wpb_content_element">
                                                                <div class="wpb_wrapper">
                                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                        <h2>Login</h2>
                                                                    </div>
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleEmail}
                                                                        value={email}
                                                                        placeholder="Email" />

                                                                    <input
                                                                        style={{ marginTop: 20 }}
                                                                        type="password" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleSenha}
                                                                        value={senha}
                                                                        placeholder="Senha" />
                                                                    {dica ?
                                                                        <p style={{ textAlign: "center", marginTop: 20 }}>Dica: {dica}</p>
                                                                        :
                                                                        null
                                                                    }
                                                                    <div onClick={() => login()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                        <input type="submit" value="Login" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                    </div>
                                                                    <a href="/cadastroCompetidor" style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Não possui login? Cadastre-se</h6>
                                                                    </a>
                                                                    <a href="javascript:void(0)" onClick={() => setValidaSenha(true)} style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Esqueceu a senha? Clique aqui</h6>
                                                                    </a>
                                                                    <a href="/mudaEmail" style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Esqueceu Email? Clique aqui</h6>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
