import { useState, useEffect } from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'

const mediaQuery = window.matchMedia('(max-width: 450px)');

export default function Home() {
    const queryParams = new URLSearchParams(window.location.search);
    const [type] = useState(queryParams.get('p'))
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos")
    })
    const [loading, setLoading] = useState(true)
    const [eventos, setEventos] = useState([])
    const [tipo, setTipo] = useState("Jiu Jitsu")

    useEffect(() => {
        if (type === 'andamento') {
            servicos.eventos
                .where('status', '==', 1)
                .get().then((snap) => {
                    const list = []
                    const listOld = []
                    const data = snap.docs.map(e => e.data())
                    data.forEach((da, index) => {
                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                        var dt2 = new Date(da.inicioEvento);
                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                        if (total > -1) {
                            data[index].dias = parseInt(total)
                            data[index].proximo = true
                        } else {
                            data[index].dias = parseInt(-total)
                            data[index].proximo = false
                        }
                    })
                    data.forEach((da, index) => {
                        if (da.proximo) {
                            list.push(da)
                        } else {
                            listOld.push(da)
                        }
                    })
                    // setEventos(data.sort((a, b) => {
                    //     return moment.utc(b.inicioEvento).diff(moment.utc(a.inicioEvento))
                    // }))
                    setEventos(list.sort((a, b) => {
                        return parseInt(a.dias) - parseInt(b.dias)
                    }))
                })
        } else if (type === 'realizados') {
            servicos.eventos
                .where('status', '>', 0)
                .get().then((snap) => {
                    const list = []
                    const listOld = []
                    const data = snap.docs.map(e => e.data())
                    data.forEach((da, index) => {
                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                        var dt2 = new Date(da.inicioEvento);
                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                        if (total > -1) {
                            data[index].dias = parseInt(total)
                            data[index].proximo = true
                        } else {
                            data[index].dias = parseInt(-total)
                            data[index].proximo = false
                        }
                    })
                    data.forEach((da, index) => {
                        if (da.proximo) {
                            list.push(da)
                        } else {
                            listOld.push(da)
                        }
                    })
                    // setEventos(data.sort((a, b) => {
                    //     return moment.utc(b.inicioEvento).diff(moment.utc(a.inicioEvento))
                    // }))
                    setEventos(listOld.sort((a, b) => {
                        return parseInt(a.dias) - parseInt(b.dias)
                    }))
                })
        } else {
            servicos.eventos
                .where('status', '>', 0)
                .get().then((snap) => {
                    const list = []
                    const listOld = []
                    const data = snap.docs.map(e => e.data())
                    data.forEach((da, index) => {
                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                        var dt2 = new Date(da.inicioEvento);
                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                        if (total > -1) {
                            data[index].dias = parseInt(total)
                            data[index].proximo = true
                        } else {
                            data[index].dias = parseInt(-total)
                            data[index].proximo = false
                        }
                    })
                    data.forEach((da, index) => {
                        if (da.proximo) {
                            list.push(da)
                        } else {
                            listOld.push(da)
                        }
                    })
                    // setEventos(data.sort((a, b) => {
                    //     return moment.utc(b.inicioEvento).diff(moment.utc(a.inicioEvento))
                    // }))
                    setEventos(listOld.sort((a, b) => {
                        return parseInt(a.dias) - parseInt(b.dias)
                    }))
                })
        }
    }, [])

    function detalheEvento(id) {
        window.location.href = 'evento/' + id
    }

    function resultados(id) {
        window.location.href = 'resultados/' + id
    }


    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            {type === 'realizados' ?
                                <h1>Eventos Realizados</h1>
                                : type === 'andamento' ?
                                    <h1>Eventos em Andamento</h1>
                                    :
                                    <h1>Resultados</h1>
                            }
                        </div>
                        <div class="headline__description">
                            {type === 'realizados' ?
                                <span>Aqui você pode ver todos os eventos realizados</span>
                                : type === 'andamento' ?
                                    <span>Aqui você pode ver todos os eventos que estão em andamento</span>
                                    :
                                    <span>Aqui você pode ver todos os resultados dos eventos em andamento e que já foram realizados</span>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <main className="section_default">
                {/* <p class="contact-form-name vc_col-sm-12">
                    <span class="wpcf7-form-control-wrap your-name">
                        <h4>Escolha aqui seu tipo de vento</h4>
                        <select name="select" id="select" class="form-control" onChange={handleTipo}>
                            <option value="">Escolha o tipo de evento </option>
                            <option value="jiujitsu" >Jiu-Jitsu </option>
                            <option value="corrida" >Corridas </option>
                        </select>
                    </span>
                </p>
                <h2>{tipo}</h2> */}
                <div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="js-carusel-wrapp clearfix">
                                    <div className="carusel-empty-space" style={{ height: 24 }}></div>
                                    {eventos.length > 0 ?
                                        <div className="pnchrOwlCarousel_5ff0a67aea27d  row">
                                            {eventos.map((evento) =>
                                                                                           <div id='card-destaque' key={evento.id} className="vc_col-sm-3 ">
                                                                                           <Link to="#" onClick={() => detalheEvento(evento.id)}>
                                       
                                                                                       <div className="vizit-cart-item ">
                                                                                               <div className="vizit-cart-item__img">
                                                                                                   <div className="vizit-cart-item__img-overlay"></div>
                                                                                                   <div className="vizit-cart-item__img-overlay-share">
                                                                                                       <p style={{ color: "#ffffff" }}>Clique aqui para saber mais, se cadastrar no evento e ver suas incrições</p>
                                                                                                   </div><img
                                                                                                       src={evento.avatar}
                                                                                                       alt="" title="image-here" />
                                                                                                   {/* <p>Clique na imagem para acessar o evento</p> */}
                                                                                               </div>
                                                                                           
                                                                                           <div className="vizit-cart-item__descr">
                                                                                               <div 
                                                                                               title={evento.nome}className="vizit-cart-item__title" style={{ color: "#121212" }}>
                                                                                                   <i className="fa  fa-bookmark" aria-hidden="true"></i>
                                                                                                       {mediaQuery.matches ?
                                                                                                       evento.nome
                                                                                                       :
                                                                                                       `${evento.nome.substr(0, 32)}${evento.nome.substr(0, 32).length < 32 ? '' : '...'}`
                                                                                                       }
                                                                                                       </div>
                                                                                               <p><i className="fa fa-calendar" aria-hidden="true">
                                                                                                   </i>
                                                                                                   {evento.inicioEvento !== evento.fimEvento ? 
                                                                                                   `De ${moment(evento.inicioEvento).format("DD/MM/YYYY")} a ${moment(evento.fimEvento).format("DD/MM/YYYY")}` : `Dia ${moment(evento.inicioEvento).format("DD/MM/YYYY")}` }
                                                                                                   </p>
                                                                                               <p><i className="fa fa-map-marker" aria-hidden="true"></i>{evento.endereco.cidade} / {evento.endereco.uf}</p>
                                       
                                                                                           </div>
                                                                                       </div>
                                       
                                                                                       </Link>
                                                                                   </div>
                                            )}
                                        </div>
                                        :
                                        <>
                                            {loading ?
                                                <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                    style={{ color: "#c21111" }}></i> Aguarde... Carregando eventos </h2>
                                                :
                                                <h2>Sem eventos disponíveis </h2>
                                            }

                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}
