// Carrega UF



export const carregaEstados = () => {
    const URL = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

// Carrega Cidades
export const carregaCidade = (uf) => {
    const URL = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

// Seleciona UF
export const selecionaUf = (uf) => {
    const URL = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

//Busca cep
export const buscaEndereco = (cep) => {
    const URL = `https://viacep.com.br/ws/${cep}/json/`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers
    }).then((response) => response.json());
}

// Email  com Function do Firebase
export const enviaEmail = (titulo, email, corpo) => {
    return (async () => {
        try {
            const URL = `http://localhost:5001/homo-procompetidor/us-central1/app/enviaEmail/${titulo}/${email}/${corpo}`;
            var headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            const response = await fetch(URL, {
                method: "GET",
                headers: headers
            })
            const data = await response.json()
            return data
        } catch (error) {
            return 3
        }
    })();
}

//Busca cep
export const buscaArquivos = () => {
    const URL = `http://localhost:5000/procompetidor-7867b/us-central1/app/buscaArquivos`;
    var headers = new Headers();
    return fetch(URL, {
        headers: headers,
        method: "GET",
    }).then((response) => response.arrayBuffer());
}