import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'

export default function Sobre() {


    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Sobre</h1>
                            </div>
                            <div class="headline__description">
                                <span>Saiba tudo sobre o Procompetidor </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class=" vc_col-sm-6">
                                                <div class="wpb_single_image wpb_content_element vc_align_left">

                                                    <figure class="wpb_wrapper vc_figure">
                                                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                            <img width="1920" height="1401"
                                                                src="https://wallpaperaccess.com/full/236559.jpg"
                                                                class="vc_single_image-img attachment-full" alt=""
                                                                srcset="https://wallpaperaccess.com/full/236559.jpg 1920w, https://wallpaperaccess.com/full/236559.jpg 300w, https://wallpaperaccess.com/full/236559.jpg 1024w, https://wallpaperaccess.com/full/236559.jpg 768w, https://wallpaperaccess.com/full/236559.jpg 1536w"
                                                                sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                                        <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                            <h3>VANTAGENS & BENEFÍCIOS</h3>
                                                            <li>Otimização de Tempo</li>
                                                            <li>Menor sobrecarga na preparação do evento</li>
                                                            <li>Alta taxa de conversão de possíveis cliente em clientes</li>
                                                            <li>Confiança e Segurança</li>
                                                            <li>Acompanhamento em tempo real do andamento do evento</li>
                                                            <li>Diminuição drástica na taxa de erros</li>
                                                            <li>Evento mais organizado</li>
                                                            <li>Organização responsáve</li>
                                                            <li>Local único para informação, inscrição e atendimento</li>
                                                            <li>Transparência</li>
                                                            <li>Velocidade na resolução/correção de questões</li>
                                                            <li>Facilidade na geração de informações em tempo real e no histórico pós evento</li>
                                                            <li>Total controle e acompanhamento financeiro do seu evento
</li>
                                                            <li>Centralização do trabalho de pré evento
</li>
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div class="wpb_column vc_column_container  vc_col-sm-6">
                                                <div class="vc_column-inner ">
                                                    <div class="wpb_wrapper">
                                                        <div class="wpb_widgetised_column wpb_content_element">
                                                            <div class="wpb_wrapper">
                                                                <h3>SOBRE NÓS</h3>
                                                                <p>
                                                                O Site <b>PROCompetidor</b> tem o objetivo de dar aos organizadores de eventos esportivos todo o
suporte necessário para um pré evento de qualidade. Disponibilizando um atendimento eficiente,
transparente e organizado. Ficamos responsáveis pela administração de inscrições,
atendimento para atletas, checagens e chaves, facilitando ao organizador e tornando necessário
apenas o acompanhamento destas etapas, fazendo assim uma otimização do tempo e trabalho
no preparo do seu evento.

                                                                </p>
                                                                <h3>MISSÃO</h3>
                                                                <p>
                                                                Nossa <b>MISSÃO</b> consiste em priorizar a excelência na prestação de serviço. O <b>PROCompetidor </b> 
busca sempre inovar com o uso de tecnologias e estratégias que estão em constante evolução,
fornecendo assim ao organizador o melhor controle, organização e estratégias de prospecção
possíveis para o seu evento.

                                                                </p>
                                                                <h3>VISÃO</h3>
                                                                <p>
                                                                Nossa <b>VISÃO</b> é conquistar o mercado a níveis cada vez mais altos, fornecendo um serviço cada
dia mais completo, e agregando reconhecimento nacional ao <b>PROCompetidor</b> como referência
de excelência na prestação de serviço de gerenciamento online de eventos esportivos.

                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
