import React, { useEffect } from 'react';
import Routes from './Routes'
import firebase from 'firebase'
import './service/firebase';

// const config = {
//     apiKey: "AIzaSyD_1KnH9alSyfMWvJM0dYezJRfERYjZj54",
//     authDomain: "procompetidor-7867b.firebaseapp.com",
//     projectId: "procompetidor-7867b",
//     storageBucket: "procompetidor-7867b.appspot.com",
//     messagingSenderId: "810225893140",
//     appId: "1:810225893140:web:08d200016ba2091f574c01"

// };
// // Initialize Firebase
// firebase.initializeApp(config);

function App() {

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.firestore().collection("usuarios").doc(user.uid).get().then((snap) => {
                    if (snap.data().usuario === 0) {
                        firebase.auth().signOut();
                    }
                })
            }
        })
    }, [])

    return (
        <div className="App">
            <Routes />
        </div>
    );
}

export default App;
