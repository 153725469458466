import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import MediaQuery from 'react-responsive'


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
    card: {
        width: '49%',
        height: 120,
        display: 'flex',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: 10
    },
    avatar: {
        width: 120,
        height: 120
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function Checagem() {
    const classes = useStyles();
    const [id] = useState(window.location.pathname.replace('/resultados/', ''))
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos").doc(id),
        inscricoes: firebase.firestore().collection("inscricoes").where("evento.id", "==", id),
        equipes: firebase.firestore().collection("equipes"),
    })
    const [loading, setLoading] = useState(true)
    const [checagem, setChecagem] = useState([])
    const [checagemTemp, setChecagemTemp] = useState([])
    const [checagemAbs, setChecagemAbs] = useState([])
    const [checagemAbsTemp, setChecagemAbsTemp] = useState([])
    const [checagemEquipe, setChecagemEquipe] = useState([])
    const [inscricoes, setInscricoes] = useState([])
    const [evento, setEvento] = useState({})
    const [tipoChecagem, setTipoChecagem] = useState('categoria')

    const [tipo, setTipo] = useState('lancado')

    useEffect(() => {
        servicos.eventos.get().then((snapE) => {
            setEvento(snapE.data())
            const li = {}
            const liNao = []
            const liSim = []

            const liAbs = {}
            const liAbsSim = {}
            const liAbsNao = {}
            servicos.inscricoes
                .where('status', '==', 2)
                .get().then((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setInscricoes(data)
                    setLoading(false)
                    data.forEach((d) => {
                        var id = d.categoria.categoria.id + '-' + d.categoria.faixa.id + '-' + d.categoria.categoria.sexo + '-' + d.categoria.peso.id
                        // Categoria
                        if (!(id in li)) {
                            li[id] = [d]
                        } else {
                            li[id].push(d)
                        }
                        // // Absoluto
                        if (d.categoria.absoluto.id && d.statusAbsoluto > 0) {
                            if (d.statusAbsoluto === 2) {
                                var idAbs = d.categoria.absoluto.id + '-' + d.categoria.categoria.sexo
                                if (!(idAbs in liAbs)) {
                                    liAbs[idAbs] = [d]
                                } else {
                                    liAbs[idAbs].push(d)
                                }
                            }
                        } else if (d.categoria.absoluto.id && d.statusAbsoluto === 0) {
                            var idAbs = d.categoria.absoluto.id + '-' + d.categoria.categoria.sexo
                            if (!(idAbs in liAbs)) {
                                liAbs[idAbs] = [d]
                            } else {
                                liAbs[idAbs].push(d)
                            }
                        } else if (d.evento.id === 'zV0m9symXzSyADvUvKsv' && d.categoria.absoluto.id) {
                            var idAbs = d.categoria.absoluto.id + '-' + d.categoria.categoria.sexo
                            if (!(idAbs in liAbs)) {
                                liAbs[idAbs] = [d]
                            } else {
                                liAbs[idAbs].push(d)
                            }
                        }
                    })
                    Object.keys(li).forEach((id) => {
                        const lista_atletas = li[id]

                        lista_atletas.forEach((atleta) => {
                            if (atleta.posicaoCategoria && atleta.posicaoCategoria > 0 || id in liSim) {
                                if (!(id in liSim)) {
                                    liSim[id] = [atleta]
                                } else {
                                    liSim[id].push(atleta)
                                }
                                //caso esse id ja existe em na lista de nao, precisa trazer todo mundo de la
                                if (id in liNao) {
                                    const mover_de_nao_para_sim = liNao[id]
                                    liSim[id].push(...mover_de_nao_para_sim)
                                    delete liNao[id]
                                }

                            } else {
                                if (!(id in liNao)) {
                                    liNao[id] = [atleta]
                                } else {
                                    liNao[id].push(atleta)

                                }
                            }
                        })
                    })

                    Object.keys(liAbs).forEach((id) => {
                        const lista_atletas = liAbs[id]

                        lista_atletas.forEach((atleta) => {
                            if (atleta.posicaoAbsoluto && atleta.posicaoAbsoluto > 0 || id in liAbsSim) {
                                if (!(id in liAbsSim)) {
                                    liAbsSim[id] = [atleta]
                                } else {
                                    liAbsSim[id].push(atleta)
                                }
                                //caso esse id ja existe em na lista de nao, precisa trazer todo mundo de la
                                if (id in liNao) {
                                    const mover_de_nao_para_sim = liNao[id]
                                    liAbsSim[id].push(...mover_de_nao_para_sim)
                                    delete liNao[id]
                                }

                            } else {
                                if (!(id in liAbsNao)) {
                                    liAbsNao[id] = [atleta]
                                } else {
                                    liAbsNao[id].push(atleta)

                                }
                            }
                        })
                    })

                    setChecagem(Object.values(liSim))
                    setChecagemTemp(Object.values(liNao))
                    setChecagemAbs(Object.values(liAbsSim))
                    setChecagemAbsTemp(Object.values(liAbsNao))

                })
        })
    }, [])

    function buscaTipo(tipo) {
        setTipo('lancado')
        setTipoChecagem(tipo)
    }
    function selecionaTipo(tipo) {
        setTipo(tipo)
    }
    function buscaTipoOutros(tipo) {
        setTipoChecagem(tipo)
        if (tipo === 'equipe') {
            const l = []
            const lFinal = []
            inscricoes.forEach((d) => {
                // l.push(d.equipe.nomeEquipe.replace(/[^a-z0-9]/gi, ''))
                l.push(d.equipe.equipe)
            })
            const array = [...new Set(l)]
            array.forEach((d) => {
                lFinal.push(inscricoes.filter((a) => a.equipe.equipe === d)[0])
            })
            lFinal.forEach((d) => {
                let totalOuro = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 1).length
                let totalPrata = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 2).length
                let totalBronze = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 3 || i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 4).length
                let totalOuroSozinho = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 5).length
                let totalOuroEquipe = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 6).length
                let totalPrataEquipe = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 7).length
                let totalBronzeEquipe = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoCategoria === 8).length

                let totalOuroAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 1).length
                let totalPrataAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 2).length
                let totalBronzeAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 3 || i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 4).length
                let totalOuroSozinhoAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 5).length
                let totalOuroEquipeAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 6).length
                let totalPrataEquipeAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 7).length
                let totalBronzeEquipeAbs = inscricoes.filter((i) => i.equipe.equipe === d.equipe.equipe && i.posicaoAbsoluto === 8).length

                d.totalOuro = totalOuro
                d.totalPrata = totalPrata
                d.totalBronze = totalBronze
                d.totalOuroSozinho = totalOuroSozinho
                d.totalOuroEquipe = totalOuroEquipe
                d.totalPrataEquipe = totalPrataEquipe
                d.totalBronzeEquipe = totalBronzeEquipe

                d.totalOuroAbs = totalOuroAbs
                d.totalPrataAbs = totalPrataAbs
                d.totalBronzeAbs = totalBronzeAbs
                d.totalOuroSozinhoAbs = totalOuroSozinhoAbs
                d.totalOuroEquipeAbs = totalOuroEquipeAbs
                d.totalPrataEquipeAbs = totalPrataEquipeAbs
                d.totalBronzeEquipeAbs = totalBronzeEquipeAbs


                let pontuacaoOuro = evento.pontuacaoOuro ? evento.pontuacaoOuro : 0
                let pontuacaoPrata = evento.pontuacaoPrata ? evento.pontuacaoPrata : 0
                let pontuacaoBronze = evento.pontuacaoBronze ? evento.pontuacaoBronze : 0
                let pontuacaoOuroSozinho = evento.pontuacaoOuroSozinho ? evento.pontuacaoOuroSozinho : 0
                let pontuacaoOuroEquipe = evento.pontuacaoOuroEquipe ? evento.pontuacaoOuroEquipe : 0
                let pontuacaoPrataEquipe = evento.pontuacaoPrataEquipe ? evento.pontuacaoPrataEquipe : 0
                let pontuacaoBronzeEquipe = evento.pontuacaoBronzeEquipe ? evento.pontuacaoBronzeEquipe : 0

                let pontuacaoOuroAbsoluto = evento.pontuacaoOuroAbsoluto && totalOuroAbs > 0 ? evento.pontuacaoOuroAbsoluto : 0
                let pontuacaoPrataAbsoluto = evento.pontuacaoPrataAbsoluto && totalPrataAbs > 0 ? evento.pontuacaoPrataAbsoluto : 0
                let pontuacaoBronzeAbsoluto = evento.pontuacaoBronzeAbsoluto && totalBronzeAbs > 0 ? evento.pontuacaoBronzeAbsoluto : 0
                let pontuacaoOuroSozinhoAbsoluto = evento.pontuacaoOuroSozinhoAbsoluto && totalOuroSozinhoAbs > 0 ? evento.pontuacaoOuroSozinhoAbsoluto : 0
                let pontuacaoOuroEquipeAbsoluto = evento.pontuacaoOuroEquipeAbsoluto && totalOuroEquipeAbs > 0 ? evento.pontuacaoOuroEquipeAbsoluto : 0
                let pontuacaoPrataEquipeAbsoluto = evento.pontuacaoPrataEquipeAbsoluto && totalPrataEquipeAbs > 0 ? evento.pontuacaoPrataEquipeAbsoluto : 0
                let pontuacaoBronzeEquipeAbsoluto = evento.pontuacaoBronzeEquipeAbsoluto && totalBronzeEquipeAbs > 0 ? evento.pontuacaoBronzeEquipeAbsoluto : 0

                d.pontuacaoOuro = pontuacaoOuro * totalOuro
                d.pontuacaoPrata = pontuacaoPrata * totalPrata
                d.pontuacaoBronze = pontuacaoBronze * totalBronze
                d.pontuacaoOuroSozinho = pontuacaoOuroSozinho * totalOuroSozinho
                d.pontuacaoOuroEquipe = pontuacaoOuroEquipe * totalOuroEquipe
                d.pontuacaoPrataEquipe = pontuacaoPrataEquipe * totalPrataEquipe
                d.pontuacaoBronzeEquipe = pontuacaoBronzeEquipe * totalBronzeEquipe

                d.pontuacaoOuroAbs = pontuacaoOuroAbsoluto * totalOuroAbs
                d.pontuacaoPrataAbs = pontuacaoPrataAbsoluto * totalPrataAbs
                d.pontuacaoBronzeAbs = pontuacaoBronzeAbsoluto * totalBronzeAbs
                d.pontuacaoOuroSozinhoAbs = pontuacaoOuroSozinhoAbsoluto * totalOuroSozinhoAbs
                d.pontuacaoOuroEquipeAbs = pontuacaoOuroEquipeAbsoluto * totalOuroEquipeAbs
                d.pontuacaoPrataEquipeAbs = pontuacaoPrataEquipeAbsoluto * totalPrataEquipeAbs
                d.pontuacaoBronzeEquipeAbs = pontuacaoBronzeEquipeAbsoluto * totalBronzeEquipeAbs

                let pontuacaoTotalOuro = parseFloat((pontuacaoOuro * totalOuro) + (totalOuroAbs * pontuacaoOuroAbsoluto))
                let pontuacaoTotalPrata = parseFloat((pontuacaoPrata * totalPrata) + (pontuacaoPrataAbsoluto * totalPrataAbs))
                let pontuacaoTotalBronze = parseFloat((pontuacaoBronze * totalBronze) + (pontuacaoBronzeAbsoluto * totalBronzeAbs))
                let pontuacaoTotalOuroSozinho = parseFloat((pontuacaoOuroSozinho * totalOuroSozinho) + (pontuacaoOuroSozinhoAbsoluto * totalOuroSozinhoAbs))
                let pontuacaoTotalOuroEquipe = parseFloat((pontuacaoOuroEquipe * totalOuroEquipe) + (pontuacaoOuroEquipeAbsoluto * totalOuroEquipeAbs))
                let pontuacaoTotalPrataEquipe = parseFloat((pontuacaoPrataEquipe * totalPrataEquipe) + (pontuacaoPrataEquipeAbsoluto * totalPrataEquipeAbs))
                let pontuacaoTotalBronzeEquipe = parseFloat((pontuacaoBronzeEquipe * totalBronzeEquipe) + (pontuacaoBronzeEquipeAbsoluto * totalBronzeEquipeAbs))

                d.totalPontos = parseFloat(pontuacaoTotalOuro + pontuacaoTotalPrata + pontuacaoTotalBronze + pontuacaoTotalOuroSozinho + pontuacaoTotalOuroEquipe + pontuacaoTotalPrataEquipe + pontuacaoTotalBronzeEquipe)

                // d.totalPontos = parseFloat((totalOuro + totalOuroAbs) * parseFloat(pontuacaoOuro + pontuacaoOuroAbsoluto)) + parseFloat((totalPrata + totalPrataAbs) * parseFloat(pontuacaoPrata + pontuacaoPrataAbsoluto)) + parseFloat((totalBronze + totalBronzeAbs) * parseFloat(pontuacaoBronze + pontuacaoBronzeAbsoluto)) + parseFloat((totalOuroSozinho + totalOuroSozinhoAbs) * parseFloat(pontuacaoOuroSozinho + pontuacaoOuroSozinhoAbsoluto)) + parseFloat((totalOuroEquipe + totalOuroEquipeAbs) * parseFloat(pontuacaoOuroEquipe + pontuacaoOuroEquipeAbsoluto)) + parseFloat((totalPrataEquipe + totalPrataEquipeAbs) * parseFloat(pontuacaoPrataEquipe + pontuacaoPrataEquipeAbsoluto)) + parseFloat((totalBronzeEquipe + totalBronzeEquipeAbs) * parseFloat(pontuacaoBronzeEquipe + pontuacaoBronzeEquipeAbsoluto))
                // d.totalPontos = parseInt((totalOuro + totalOuroAbs) * parseInt(evento.pontuacaoOuro ? evento.pontuacaoOuro : 0)) + parseInt((totalPrata + totalPrataAbs) * parseInt(evento.pontuacaoPrata ? evento.pontuacaoPrata : 0)) + parseInt((totalBronze + totalBronzeAbs) * parseInt(evento.pontuacaoBronze ? evento.pontuacaoBronze : 0))
            })
            setChecagemEquipe(lFinal)
        }
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Resultados {evento.id && evento.nome}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Resultados de cada combate </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <main >
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 style={{ marginLeft: 16 }}>Tipos de Inscritos</h3>
                                                <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => buscaTipo("categoria")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12"> Por Categoria</a>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => buscaTipoOutros("absoluto")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12"> Por Absoluto</a>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => buscaTipoOutros("equipe")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12"> Por Equipe</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 style={{ marginLeft: 16 }}>Resultado por {tipoChecagem}</h3>
                                                {tipoChecagem === "categoria" ?
                                                    <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                        <p>Clique em <b>RESULTADOS LANÇADOS</b> para ver todos os resultados ja lançados</p>
                                                        <p>Clique em <b>RESULTADOS PENDENTES</b> para ver todos os resultados que ainda serão lançados</p>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" >
                                                            <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagem.length + checagemTemp.length} Resultados ao total</a>
                                                        </div>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => selecionaTipo("lancado")}>
                                                            {tipo === "lancado" ?
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagem.length} Resultados Lançados <i class="fa  fa-check" aria-hidden="true"
                                                                    style={{ color: "#ffff" }}></i></a>
                                                                :
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagem.length} Resultados Lançados </a>
                                                            }

                                                        </div>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => selecionaTipo("pendente")}>
                                                            {tipo === "pendente" ?
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemTemp.length} Resultados Pendentes <i class="fa  fa-check" aria-hidden="true"
                                                                    style={{ color: "#ffff" }}></i></a>
                                                                :
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemTemp.length} Resultados Pendentes</a>
                                                            }

                                                        </div>
                                                    </div>
                                                    : tipoChecagem === "absoluto" ?
                                                        <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                            <p>Clique em <b>RESULTADOS LANÇADOS</b> para ver todos os resultados ja lançados</p>
                                                            <p>Clique em <b>RESULTADOS PENDENTES</b> para ver todos os resultados que ainda serão lançados</p>
                                                            <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" >
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemAbs.length + checagemAbsTemp.length} Resultados ao total</a>
                                                            </div>
                                                            <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => selecionaTipo("lancado")}>
                                                                {tipo === "lancado" ?
                                                                    <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemAbs.length} Resultados Lançados <i class="fa  fa-check" aria-hidden="true"
                                                                        style={{ color: "#ffff" }}></i></a>
                                                                    :
                                                                    <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemAbs.length} Resultados Lançados </a>
                                                                }

                                                            </div>
                                                            <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-4" onClick={() => selecionaTipo("pendente")}>
                                                                {tipo === "pendente" ?
                                                                    <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemAbsTemp.length} Resultados Pendentes <i class="fa  fa-check" aria-hidden="true"
                                                                        style={{ color: "#ffff" }}></i></a>
                                                                    :
                                                                    <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-8">{checagemAbsTemp.length} Resultados Pendentes</a>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {/* Checagem geral */}
                                            {tipoChecagem === "categoria" ?
                                                <div class="vc_col-sm-12">
                                                    {tipo === "lancado" ?
                                                        <>
                                                            {checagem.length > 0 ?
                                                                <>
                                                                    {checagem.sort((a, b) => {
                                                                        if (parseInt(a[0].categoria.categoria.posicao) < parseInt(b[0].categoria.categoria.posicao))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.categoria.posicao) > parseInt(b[0].categoria.categoria.posicao))
                                                                            return 1;
                                                                        if (parseInt(a[0].categoria.faixa.posicao) < parseInt(b[0].categoria.faixa.posicao))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.faixa.posicao) > parseInt(b[0].categoria.faixa.posicao))
                                                                            return 1;
                                                                        if (parseInt(a[0].categoria.peso.peso) < parseInt(b[0].categoria.peso.peso))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.peso.peso) > parseInt(b[0].categoria.peso.peso))
                                                                            return 1;
                                                                        if (b[0].categoria.categoria.sexo < a[0].categoria.categoria.sexo)
                                                                            return -1;
                                                                        if (b[0].categoria.categoria.sexo > a[0].categoria.categoria.sexo)
                                                                            return 1;
                                                                        return 0;
                                                                    }).map((itemChecagem, index) =>
                                                                        <>
                                                                            <MediaQuery minWidth={1224}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <p>{itemChecagem[0].categoria.categoria.categoria} ({itemChecagem[0].categoria.categoria.tipoIdade === 0 ? "Até " + itemChecagem[0].categoria.categoria.idade + " anos" : itemChecagem[0].categoria.categoria.tipoIdade === 1 ? "De " + itemChecagem[0].categoria.categoria.minIdade + " até " + itemChecagem[0].categoria.categoria.maxIdade + " anos" : "Acima de " + itemChecagem[0].categoria.categoria.idade}) , {itemChecagem[0].categoria.faixa.faixa}, {itemChecagem[0].categoria.peso.nome} ({itemChecagem[0].categoria.peso.tipoPeso === 0 ? "ATÉ " + itemChecagem[0].categoria.peso.peso + " KG" : "ACIMA " + itemChecagem[0].categoria.peso.peso + " KG"}), {itemChecagem[0].categoria.categoria.sexo}</p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails >
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoCategoria) < parseInt(b.posicaoCategoria))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoCategoria) > parseInt(b.posicaoCategoria))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).filter((d) => d.posicaoCategoria > 0).map((inscricao) =>

                                                                                                <Grid item xs={12}>

                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoCategoria === 1 || inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? "#DAA520" : inscricao.posicaoCategoria === 2 || inscricao.posicaoCategoria === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? 1 : inscricao.posicaoCategoria === 7 ? 2 : inscricao.posicaoCategoria === 8 ? 3 : inscricao.posicaoCategoria}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                            <MediaQuery minWidth={300} maxWidth={1000}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        {/* <Typography className={classes.heading}>{itemChecagem.nomeCategoria} FAIXA {itemChecagem.nomeFaixa} PESO {itemChecagem.nomePeso} {itemChecagem.sexo} - ({inscricoes.filter((i) => i.checagem === itemChecagem.id).length} inscritos)</Typography> */}
                                                                                        {/* <p style={{ fontSize: 12 }}><b>{itemChecagem.nomeCategoria}, {itemChecagem.nomeFaixa}, {itemChecagem.nomePeso}, {itemChecagem.sexo}</b></p> */}
                                                                                        <p style={{ fontSize: 12 }}><b>{itemChecagem[0].categoria.categoria.categoria} ({itemChecagem[0].categoria.categoria.tipoIdade === 0 ? "Até " + itemChecagem[0].categoria.categoria.idade + " anos" : itemChecagem[0].categoria.categoria.tipoIdade === 1 ? "De " + itemChecagem[0].categoria.categoria.minIdade + " até " + itemChecagem[0].categoria.categoria.maxIdade + " anos" : "Acima de " + itemChecagem[0].categoria.categoria.idade}) , {itemChecagem[0].categoria.faixa.faixa}, {itemChecagem[0].categoria.peso.nome} ({itemChecagem[0].categoria.peso.tipoPeso === 0 ? "ATÉ " + itemChecagem[0].categoria.peso.peso + " KG" : "ACIMA " + itemChecagem[0].categoria.peso.peso + " KG"}), {itemChecagem[0].categoria.categoria.sexo} </b></p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails >
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoCategoria) < parseInt(b.posicaoCategoria))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoCategoria) > parseInt(b.posicaoCategoria))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).filter((d) => d.posicaoCategoria > 0).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoCategoria === 1 || inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? "#DAA520" : inscricao.posicaoCategoria === 2 || inscricao.posicaoCategoria === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? 1 : inscricao.posicaoCategoria === 7 ? 2 : inscricao.posicaoCategoria === 8 ? 3 : inscricao.posicaoCategoria}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                        </>
                                                                    )}
                                                                </>
                                                                :
                                                                <h4> Sem RESULTADOS lançados </h4>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {tipo === "pendente" ?
                                                        <>
                                                            {checagemTemp.length > 0 ?
                                                                <>
                                                                    {checagemTemp.sort((a, b) => {
                                                                        if (parseInt(a[0].categoria.categoria.posicao) < parseInt(b[0].categoria.categoria.posicao))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.categoria.posicao) > parseInt(b[0].categoria.categoria.posicao))
                                                                            return 1;
                                                                        if (parseInt(a[0].categoria.faixa.posicao) < parseInt(b[0].categoria.faixa.posicao))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.faixa.posicao) > parseInt(b[0].categoria.faixa.posicao))
                                                                            return 1;
                                                                        if (parseInt(a[0].categoria.peso.peso) < parseInt(b[0].categoria.peso.peso))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.peso.peso) > parseInt(b[0].categoria.peso.peso))
                                                                            return 1;
                                                                        if (b[0].categoria.categoria.sexo < a[0].categoria.categoria.sexo)
                                                                            return -1;
                                                                        if (b[0].categoria.categoria.sexo > a[0].categoria.categoria.sexo)
                                                                            return 1;
                                                                        return 0;
                                                                    }).map((itemChecagem, index) =>
                                                                        <>
                                                                            <MediaQuery minWidth={1224}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <p>{itemChecagem[0].categoria.categoria.categoria} ({itemChecagem[0].categoria.categoria.tipoIdade === 0 ? "Até " + itemChecagem[0].categoria.categoria.idade + " anos" : itemChecagem[0].categoria.categoria.tipoIdade === 1 ? "De " + itemChecagem[0].categoria.categoria.minIdade + " até " + itemChecagem[0].categoria.categoria.maxIdade + " anos" : "Acima de " + itemChecagem[0].categoria.categoria.idade}) , {itemChecagem[0].categoria.faixa.faixa}, {itemChecagem[0].categoria.peso.nome} ({itemChecagem[0].categoria.peso.tipoPeso === 0 ? "ATÉ " + itemChecagem[0].categoria.peso.peso + " KG" : "ACIMA " + itemChecagem[0].categoria.peso.peso + " KG"}), {itemChecagem[0].categoria.categoria.sexo}</p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails >
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoCategoria) < parseInt(b.posicaoCategoria))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoCategoria) > parseInt(b.posicaoCategoria))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoCategoria === 1 || inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? "#DAA520" : inscricao.posicaoCategoria === 2 || inscricao.posicaoCategoria === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? 1 : inscricao.posicaoCategoria === 7 ? 2 : inscricao.posicaoCategoria === 8 ? 3 : inscricao.posicaoCategoria}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                            <MediaQuery minWidth={300} maxWidth={1000}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        {/* <Typography className={classes.heading}>{itemChecagem.nomeCategoria} FAIXA {itemChecagem.nomeFaixa} PESO {itemChecagem.nomePeso} {itemChecagem.sexo} - ({inscricoes.filter((i) => i.checagem === itemChecagem.id).length} inscritos)</Typography> */}
                                                                                        {/* <p style={{ fontSize: 12 }}><b>{itemChecagem.nomeCategoria}, {itemChecagem.nomeFaixa}, {itemChecagem.nomePeso}, {itemChecagem.sexo}</b></p> */}
                                                                                        <p style={{ fontSize: 12 }}><b>{itemChecagem[0].categoria.categoria.categoria} ({itemChecagem[0].categoria.categoria.tipoIdade === 0 ? "Até " + itemChecagem[0].categoria.categoria.idade + " anos" : itemChecagem[0].categoria.categoria.tipoIdade === 1 ? "De " + itemChecagem[0].categoria.categoria.minIdade + " até " + itemChecagem[0].categoria.categoria.maxIdade + " anos" : "Acima de " + itemChecagem[0].categoria.categoria.idade}) , {itemChecagem[0].categoria.faixa.faixa}, {itemChecagem[0].categoria.peso.nome} ({itemChecagem[0].categoria.peso.tipoPeso === 0 ? "ATÉ " + itemChecagem[0].categoria.peso.peso + " KG" : "ACIMA " + itemChecagem[0].categoria.peso.peso + " KG"}), {itemChecagem[0].categoria.categoria.sexo} </b></p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails >
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoCategoria) < parseInt(b.posicaoCategoria))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoCategoria) > parseInt(b.posicaoCategoria))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoCategoria === 1 || inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? "#DAA520" : inscricao.posicaoCategoria === 2 || inscricao.posicaoCategoria === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoCategoria === 5 || inscricao.posicaoCategoria === 6 ? 1 : inscricao.posicaoCategoria === 7 ? 2 : inscricao.posicaoCategoria === 8 ? 3 : inscricao.posicaoCategoria}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                        </>
                                                                    )}
                                                                </>
                                                                :
                                                                <h4> Sem RESULTADOS pendentes </h4>
                                                            }

                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                            }

                                            {/* Checagem por Absoluto */}
                                            {tipoChecagem === "absoluto" ?
                                                <div class="vc_col-sm-12">
                                                    {tipo === "lancado" ?
                                                        <>
                                                            {checagemAbs.length > 0 ?
                                                                <>
                                                                    {checagemAbs.sort((a, b) => {
                                                                        if (parseInt(a[0].categoria.categoria.posicao) < parseInt(b[0].categoria.categoria.posicao))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.categoria.posicao) > parseInt(b[0].categoria.categoria.posicao))
                                                                            return 1;
                                                                        return 0
                                                                    }).map((itemChecagem) =>
                                                                        <>
                                                                            <MediaQuery minWidth={1224}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <p>{itemChecagem[0].categoria.absoluto.absoluto} / {itemChecagem[0].categoria.categoria.sexo}</p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails className={classes.detalheAcordeon}>
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoAbsoluto) < parseInt(b.posicaoAbsoluto))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoAbsoluto) > parseInt(b.posicaoAbsoluto))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).filter((d) => d.posicaoAbsoluto > 0).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoAbsoluto === 1 || inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? "#DAA520" : inscricao.posicaoAbsoluto === 2 || inscricao.posicaoAbsoluto === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? 1 : inscricao.posicaoAbsoluto === 7 ? 2 : inscricao.posicaoAbsoluto === 8 ? 3 : inscricao.posicaoAbsoluto}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                            <MediaQuery minWidth={200} maxWidth={1000}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <p style={{ fontSize: 12 }}><b>{itemChecagem[0].categoria.absoluto.absoluto} / {itemChecagem[0].categoria.categoria.sexo}</b></p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails className={classes.detalheAcordeon}>
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoAbsoluto) < parseInt(b.posicaoAbsoluto))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoAbsoluto) > parseInt(b.posicaoAbsoluto))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).filter((d) => d.posicaoAbsoluto > 0).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoAbsoluto === 1 || inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? "#DAA520" : inscricao.posicaoAbsoluto === 2 || inscricao.posicaoAbsoluto === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? 1 : inscricao.posicaoAbsoluto === 7 ? 2 : inscricao.posicaoAbsoluto === 8 ? 3 : inscricao.posicaoAbsoluto}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                        </>
                                                                    )}
                                                                </>
                                                                :
                                                                <h4> Sem RESULTADOS lançados </h4>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {tipo === "pendente" ?
                                                        <>
                                                            {checagemAbsTemp.length > 0 ?
                                                                <>
                                                                    {checagemAbsTemp.sort((a, b) => {
                                                                        if (parseInt(a[0].categoria.categoria.posicao) < parseInt(b[0].categoria.categoria.posicao))
                                                                            return -1;
                                                                        if (parseInt(a[0].categoria.categoria.posicao) > parseInt(b[0].categoria.categoria.posicao))
                                                                            return 1;
                                                                        return 0
                                                                    }).map((itemChecagem) =>
                                                                        <>
                                                                            <MediaQuery minWidth={1224}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <p>{itemChecagem[0].categoria.absoluto.absoluto} / {itemChecagem[0].categoria.categoria.sexo}</p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails className={classes.detalheAcordeon}>
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoAbsoluto) < parseInt(b.posicaoAbsoluto))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoAbsoluto) > parseInt(b.posicaoAbsoluto))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoAbsoluto === 1 || inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? "#DAA520" : inscricao.posicaoAbsoluto === 2 || inscricao.posicaoAbsoluto === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? 1 : inscricao.posicaoAbsoluto === 7 ? 2 : inscricao.posicaoAbsoluto === 8 ? 3 : inscricao.posicaoAbsoluto}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                            <MediaQuery minWidth={200} maxWidth={1000}>
                                                                                <Accordion>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >
                                                                                        <p style={{ fontSize: 12 }}><b>{itemChecagem[0].categoria.absoluto.absoluto} / {itemChecagem[0].categoria.categoria.sexo}</b></p>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails className={classes.detalheAcordeon}>
                                                                                        <Grid container spacing={3}>
                                                                                            {itemChecagem.sort((a, b) => {
                                                                                                if (parseInt(a.posicaoAbsoluto) < parseInt(b.posicaoAbsoluto))
                                                                                                    return -1;
                                                                                                if (parseInt(a.posicaoAbsoluto) > parseInt(b.posicaoAbsoluto))
                                                                                                    return 1;
                                                                                                return 0;
                                                                                            }).map((inscricao) =>
                                                                                                <Grid item xs={12}>
                                                                                                    <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                        <div className="ratings-widget__img js-hover-veil">
                                                                                                            <h1 style={{ color: inscricao.posicaoAbsoluto === 1 || inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? "#DAA520" : inscricao.posicaoAbsoluto === 2 || inscricao.posicaoAbsoluto === 7 ? "#808080" : "#D2691E" }}>{inscricao.posicaoAbsoluto === 5 || inscricao.posicaoAbsoluto === 6 ? 1 : inscricao.posicaoAbsoluto === 7 ? 2 : inscricao.posicaoAbsoluto === 8 ? 3 : inscricao.posicaoAbsoluto}°</h1>
                                                                                                        </div>
                                                                                                        <div className="ratings-widget__details ">
                                                                                                            <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                            <div className="ratings-widget__details-cat"><p>{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor ? inscricao.equipe.nomeProfessor : null}</p></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </MediaQuery>
                                                                        </>
                                                                    )}
                                                                </>
                                                                :
                                                                <h4> Sem RESULTADOS pendentes </h4>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                null
                                            }

                                            {/* Checagem por Equipe */}
                                            {tipoChecagem === "equipe" ?
                                                <div class="vc_col-sm-12">
                                                    {checagemEquipe.length > 0 ?
                                                        <>
                                                            {checagemEquipe.sort((a, b) => {
                                                                if (parseInt(b.totalPontos) < parseInt(a.totalPontos))
                                                                    return -1;
                                                                if (parseInt(b.totalPontos) > parseInt(a.totalPontos))
                                                                    return 1;
                                                                return 0;
                                                            }).map((itemChecagem, index) =>
                                                                <>
                                                                    <MediaQuery minWidth={1224}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <Typography className={classes.heading} style={{ textAlign: "left" }}><b>{index + 1}°</b> {itemChecagem.equipe.nomeEquipe}  - ( Total {parseFloat(itemChecagem.totalPontos)} pontos )</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails className={classes.detalheAcordeon}>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={6}>
                                                                                        <div key={itemChecagem.id} className="vc_col-lg-12" >
                                                                                            <div className="ratings-widget__details-cat"><p><b>Quadro de Medalhas</b></p></div>
                                                                                            {/* <div className="ratings-widget__details-title"> <h5>OURO - {itemChecagem.totalOuro}</h5></div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA - {itemChecagem.totalPrata} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE - {itemChecagem.totalBronze} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO SOZINHO - {itemChecagem.totalOuroSozinho} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE - {itemChecagem.totalOuroEquipe} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE - {itemChecagem.totalPrataEquipe} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE - {itemChecagem.totalBronzeEquipe} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO ABSOLUTO - {itemChecagem.totalOuroAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA ABSOLUTO - {itemChecagem.totalPrataAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE ABSOLUTO - {itemChecagem.totalBronzeAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO SOZINHO ABSOLUTO - {itemChecagem.totalOuroSozinhoAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE ABSOLUTO - {itemChecagem.totalOuroEquipeAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE ABSOLUTO - {itemChecagem.totalPrataEquipeAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE ABSOLUTO - {itemChecagem.totalBronzeEquipeAbs} </h5> </div> */}

                                                                                            <div className="ratings-widget__details-title"> <h5>OURO - {itemChecagem.totalOuro}</h5> {itemChecagem.pontuacaoOuro} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA - {itemChecagem.totalPrata} </h5> {itemChecagem.pontuacaoPrata} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE - {itemChecagem.totalBronze} </h5> {itemChecagem.pontuacaoBronze} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO SOZINHO - {itemChecagem.totalOuroSozinho} </h5> {itemChecagem.pontuacaoOuroSozinho} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE - {itemChecagem.totalOuroEquipe} </h5> {itemChecagem.pontuacaoOuroEquipe} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE - {itemChecagem.totalPrataEquipe} </h5> {itemChecagem.pontuacaoPrataEquipe} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE - {itemChecagem.totalBronzeEquipe} </h5> {itemChecagem.pontuacaoBronzeEquipe} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO ABSOLUTO - {itemChecagem.totalOuroAbs} </h5> {itemChecagem.pontuacaoOuroAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA ABSOLUTO - {itemChecagem.totalPrataAbs} </h5> {itemChecagem.pontuacaoPrataAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE ABSOLUTO - {itemChecagem.totalBronzeAbs} </h5> {itemChecagem.pontuacaoBronzeAbs} Pontos</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO SOZINHO ABSOLUTO - {itemChecagem.totalOuroSozinhoAbs} </h5> {itemChecagem.pontuacaoOuroSozinhoAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE ABSOLUTO - {itemChecagem.totalOuroEquipeAbs} </h5> {itemChecagem.pontuacaoOuroEquipeAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE ABSOLUTO - {itemChecagem.totalPrataEquipeAbs} </h5> {itemChecagem.pontuacaoPrataEquipeAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE ABSOLUTO - {itemChecagem.totalBronzeEquipeAbs} </h5> {itemChecagem.pontuacaoBronzeEquipeAbs} PONTOS</div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                    <MediaQuery minWidth={200} maxWidth={1000}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <p style={{ fontSize: 12, textAlign: "right" }}><b>{index + 1}° {itemChecagem.equipe.nomeEquipe}  - ( Total {parseFloat(itemChecagem.totalPontos)} pontos )</b></p>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails className={classes.detalheAcordeon}>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item xs={6}>
                                                                                        <div key={itemChecagem.id} className="vc_col-lg-12" >
                                                                                            <div className="ratings-widget__details-cat"><p><b>Quadro de Medalhas</b></p></div>
                                                                                            <div className="ratings-widget__details-title"> <h5>OURO - {itemChecagem.totalOuro}</h5> {itemChecagem.pontuacaoOuro} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA - {itemChecagem.totalPrata} </h5> {itemChecagem.pontuacaoPrata} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE - {itemChecagem.totalBronze} </h5> {itemChecagem.pontuacaoBronze} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO SOZINHO - {itemChecagem.totalOuroSozinho} </h5> {itemChecagem.pontuacaoOuroSozinho} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE - {itemChecagem.totalOuroEquipe} </h5> {itemChecagem.pontuacaoOuroEquipe} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE - {itemChecagem.totalPrataEquipe} </h5> {itemChecagem.pontuacaoPrataEquipe} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE - {itemChecagem.totalBronzeEquipe} </h5> {itemChecagem.pontuacaoBronzeEquipe} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO ABSOLUTO - {itemChecagem.totalOuroAbs} </h5> {itemChecagem.pontuacaoOuroAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA ABSOLUTO - {itemChecagem.totalPrataAbs} </h5> {itemChecagem.pontuacaoPrataAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE ABSOLUTO - {itemChecagem.totalBronzeAbs} </h5> {itemChecagem.pontuacaoBronzeAbs} Pontos</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO SOZINHO ABSOLUTO - {itemChecagem.totalOuroSozinhoAbs} </h5> {itemChecagem.pontuacaoOuroSozinhoAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE ABSOLUTO - {itemChecagem.totalOuroEquipeAbs} </h5> {itemChecagem.pontuacaoOuroEquipeAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE ABSOLUTO - {itemChecagem.totalPrataEquipeAbs} </h5> {itemChecagem.pontuacaoPrataEquipeAbs} PONTOS</div>
                                                                                            <div style={{marginTop:10}} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE ABSOLUTO - {itemChecagem.totalBronzeEquipeAbs} </h5> {itemChecagem.pontuacaoBronzeEquipeAbs} PONTOS</div>

                                                                                            {/* <div className="ratings-widget__details-title"> <h5>OURO - {itemChecagem.totalOuro}</h5></div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA - {itemChecagem.totalPrata} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE - {itemChecagem.totalBronze} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO SOZINHO - {itemChecagem.totalOuroSozinho} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE - {itemChecagem.totalOuroEquipe} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE - {itemChecagem.totalPrataEquipe} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE - {itemChecagem.totalBronzeEquipe} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO ABSOLUTO - {itemChecagem.totalOuroAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA ABSOLUTO - {itemChecagem.totalPrataAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE ABSOLUTO - {itemChecagem.totalBronzeAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO SOZINHO ABSOLUTO - {itemChecagem.totalOuroSozinhoAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>OURO MESMA EQUIPE ABSOLUTO - {itemChecagem.totalOuroEquipeAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>PRATA MESMA EQUIPE ABSOLUTO - {itemChecagem.totalPrataEquipeAbs} </h5> </div>
                                                                                            <div style={{ marginTop: 10 }} className="ratings-widget__details-title"> <h5>BRONZE MESMA EQUIPE ABSOLUTO - {itemChecagem.totalBronzeEquipeAbs} </h5> </div> */}
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                </>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {loading ?
                                                                <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Aguarde... Carregando resultados </h2>
                                                                :
                                                                <h4>Sem informações de filtro para esta consulta </h4>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                                :
                                                null
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
