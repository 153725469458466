import React, { useEffect, useState } from 'react';
import './ModalEquipes.css'; // Import CSS file for modal styling
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import firebase from 'firebase'
import moment from 'moment';
import Swal from 'sweetalert2'

const ModalEquipes = ({ isOpen, setIsOpen, setEquipe, setDadosEquipe, setProfessores, setDadosProfessor }) => {

  const [equipes, setEquipes] = useState([]);
  const [equipesFiltradas, setEquipesFiltradas] = useState([]);
  const [pesquisa, setPesquisa] = useState('');
  const [incluirNovaEquipe, setIncluirNovaEquipe] = useState(false);
  const [novaEquipe, setNovaEquipe] = useState('');

  const [servicos] = useState({
    equipes: firebase.firestore().collection("equipes")
  });


  useEffect(async () => {
    mensagemLoading('carregando equipes...')
    const snap = await servicos.equipes
    .where('status', '==', 1)
    .get();
    const data = snap.docs.map(d => d.data()).sort((a, b) => {
      if (a.nome < b.nome) {
        return -1;
    } else if (a.nome > b.nome) {
        return 1;
    } else {
        return 0;
    }
    })

    setEquipes(data);
    setEquipesFiltradas(data);
    Swal.close();


  }, []);

  const handlePesquisa = (event) => {
    event.persist();
    setPesquisa(event.target.value.toUpperCase());
    const _equipesFiltradas = equipes.filter(equipe => {
      return equipe.equipe.trim().toUpperCase().includes(event.target.value.trim().toUpperCase());
    })
    setEquipesFiltradas(_equipesFiltradas);
  }


  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOut = (e) => {
    e.stopPropagation();
    setIsOpen(false);

  }

  async function hanleEquipe(equipe) {

    setEquipe(equipe.id);
    setDadosEquipe(equipe);
    const snap = await servicos.equipes.doc(equipe.id).collection('professores').get();
    setProfessores(snap.docs.map(d => d.data()));
    setDadosProfessor({})
    setIsOpen(false);

  }

  async function handleIncluirEquipe(e) {

    e.stopPropagation();

    if (novaEquipe.trim() === '') {
      mensagemAlerta("Por favor, insira uma equipe.");
      return;
    }

    if (! await equipeDisponivel(novaEquipe.trim().toUpperCase())) {
      mensagemAlerta("Esta equipe já está cadastrada");
      return;
    }

    const docRef = await servicos.equipes.add({
      equipe: novaEquipe.toUpperCase(),
      dataCadastro: moment().format('DD/MM/YYYY'),
      dataAtualizacao: moment().format('DD/MM/YYYY'),
      nome: novaEquipe.replace(/[^a-z0-9]/gi, '').toUpperCase(),
      status: 1,
      urlImagem: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
    })

    setEquipe(docRef.id)

    await servicos.equipes.doc(docRef.id).update({
      id: docRef.id
    })

    const snapEquipe = await servicos.equipes.doc(docRef.id).get();

    setEquipe(docRef.id);
    setDadosEquipe(snapEquipe.data());
    setDadosProfessor({})
    setProfessores([])
    setIsOpen(false);

  }

  function mensagemAlerta(msg) {
    Swal.fire('Alerta', msg, 'warning').then(() => {
      Swal.hideLoading();
    })
  }

  function mensagemLoading(msg) {
    Swal.fire({
        title: 'Aguarde',
        html: msg,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        },
    })
}
  async function equipeDisponivel(comparacao) {
    try {
      const snap = await servicos.equipes.where('equipe', '==', comparacao).get();
      if (snap.empty) {
        return true
      } else if (!snap.empty) {
        return false
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>

      {isOpen && (
        <div className="modal-overlay" onClick={(e) => handleClickOut(e)}>
          <div className="modal" onClick={(e) => {
            e.stopPropagation();
          }}>
            <span className="close-btn" onClick={toggleModal}>&times;</span>
            <div className="modal-header">
              <h4>Selecione a Equipe</h4>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                type="text" name="your-name" size="40"
                class="wpcf7-form-control wpcf7-text"
                style={{ border: "1px black solid", marginTop: '10px', width: "100%", height: "40px" }}
                onChange={(e) => handlePesquisa(e)}
                value={pesquisa}
                placeholder="Pesquisar"
                autoComplete='no-fill' />
              <i className="fa  fa-search" style={{ justifySelf: 'center', alignSelf: 'center', position: "relative", right: "25px", top: '5px' }}></i>
            </div>
            <button className='btn-fechar-modal' onClick={() => setIncluirNovaEquipe(true)} style={{ marginTop: "10px", width: "100%" }}>Minha equipe não está na lista</button>

            {incluirNovaEquipe && (
              <div style={{ display: "flex", alignItems: "center", marginTop: '10px', width: "100%" }}>
                <input
                  type="text" name="your-name" size="40"
                  class="wpcf7-form-control wpcf7-text"
                  style={{ border: "1px black solid", width: "75%", height: "40px" }}
                  onChange={(e) => setNovaEquipe(e.target.value.toUpperCase())}
                  value={novaEquipe}
                  placeholder="digite a equipe"
                  autoComplete='no-fill' />
                <button className='btn-fechar-modal' onClick={(e) => handleIncluirEquipe(e)} style={{ height: "40px", marginLeft: "5px", width: "25%" }}>incluír</button>
              </div>

            )}

            <div className="modal-body">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr >
                    <th className='rsrt-th'>Equipe</th>
                  </tr>
                </thead>
                <tbody>
                  {equipesFiltradas.map((equipe) =>
                    <tr key={equipe.id} className='rsrt-tr'>
                      <td><h5 onClick={() => hanleEquipe(equipe)}>{equipe.equipe}</h5></td>
                    </tr>
                  )}
                  {equipesFiltradas.length === 0 && (
                    <tr >
                      <th>Nenhuma equipe encontrada</th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <button className='btn-fechar-modal' style={{ margin: "0 0 0 84%" }} onClick={toggleModal}>fechar</button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalEquipes;