import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import moment from 'moment'

export default function Home() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        equipes: firebase.firestore().collection("equipes"),
    })
    const [equipe, setEquipe] = useState('')
    const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])
    const [professor, setProfessor] = useState('')
    const [dadosEquipe, setDadosEquipe] = useState({})
    const [dadosProfessor, setDadosProfessor] = useState({})
    const [inputEquipe, setInputEquipe] = useState('')
    const [inputProfessor, setInputProfessor] = useState('')
    const [inscricoes, setInscricoes] = useState('')
    const [id] = useState(window.location.pathname.replace('/mudaEquipe/', ''))

    useEffect(() => {
        servicos.inscricoes.where('usuario.id', '==', id).get().then((snap) => {
            const data = snap.docs.map((doc) => doc.data());
            setInscricoes(data)
        })
        servicos.equipes.where('status','==',1).get().then((snap) => {
            const data = snap.docs.map((doc) => doc.data());
            const l = []
            const lFinal = []
            data.forEach((d) => {
                l.push(d.nome)
            })
            const array = [...new Set(l)]
            array.forEach((d) => {
                lFinal.push(data.filter((a) => a.nome === d)[0])
            })
            setEquipes(lFinal.sort((a, b) => {
                if (a.equipe < b.equipe)
                    return -1;
                if (a.equipe > b.equipe)
                    return 1;
                return 0;
            }))
        })
    }, [])

    function atualizaPerfil() {
        mensagemLoading('Atualizando dados...')
        if (equipe === '0') {
            if (inputEquipe === '' || inputProfessor === '') {
                mensagemAlerta('Favor diga o nome de sua equipe e o professor que o acompanha! Os dados serão salvos no sistema')
            } else {
                mensagemLoading('Cadastrando equipe e professor...')
                servicos.equipes.where('nome', '==', inputEquipe.replace(/[^a-z0-9]/gi, '').toUpperCase()).get().then((s) => {
                    if (s.size > 0) {
                        mensagemAlerta('Esta equipe já está cadastrada em nosso sistema')
                    } else {
                        servicos.equipes.add({
                            equipe: inputEquipe.toUpperCase(),
                            dataCadastro: moment().format('DD/MM/YYYY'),
                            dataAtualizacao: moment().format('DD/MM/YYYY'),
                            nome: inputEquipe.replace(/[^a-z0-9]/gi, '').toUpperCase(),
                            status: parseInt(1),
                            urlImagem: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
                        }).then((docRef) => {
                            setEquipe(docRef.id)
                            servicos.equipes.doc(docRef.id).update({
                                id: docRef.id
                            }).then(() => {
                                servicos.equipes.doc(docRef.id).collection('professores').add({
                                    professor: inputProfessor.toUpperCase()
                                }).then((refProfessor) => {
                                    setProfessor(refProfessor.id)
                                    servicos.equipes.doc(docRef.id).collection('professores').doc(refProfessor.id).update({
                                        id: refProfessor.id
                                    }).then(() => {
                                        Swal.close()
                                        servicos.usuarios.doc(id).update({
                                            equipe: docRef.id,
                                            professor: refProfessor.id,
                                            dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
                                            editaEquipe: parseInt(1)
                                        }).then(() => {
                                            Swal.close()
                                            inscricoes.forEach((d) => {
                                                servicos.eventos.doc(d.evento.id).get().then((e) => {
                                                    if (e.data().status === 1) {
                                                        servicos.inscricoes.doc(d.id).update({
                                                            equipe: {
                                                                avatar: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                                                equipe: docRef.id,
                                                                professor: refProfessor.id,
                                                                nomeEquipe: inputEquipe.toUpperCase(),
                                                                nomeProfessor: inputProfessor.toUpperCase()
                                                            }
                                                        })
                                                    }
                                                })
                                            })
                                            setTimeout((
                                                mensagemSucesso('Perfil atualizado com sucesso')
                                            ), 10000)
                                            
                                        }).catch((err) => {
                                            mensagemErro('Erro ao cadastrar o evento ' + err)
                                        })
                                    }).catch(() => {
                                        console.log('Erro ao atualizar professor')
                                    })
                                }).catch(() => {
                                    console.log('Erro ao cadastrar professor')
                                })
                            }).catch(() => {
                                console.log('Erro ao atualizar equipe')
                            })
                        }).catch(() => {
                            console.log('Erro ao cadastrar Equipe')
                        })
                    }
                })
            }
        } else {
            if (!equipe) {
                mensagemAlerta('Favor selecione a equipe')
                return
            }
            if (equipe && !professor) {
                mensagemAlerta('Favor escolha o professor')
                return
            }
            servicos.usuarios.doc(id).update({
                equipe: equipe,
                professor: professor,
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
                editaEquipe: parseInt(1)
            }).then(() => {
                Swal.close()
                if (dadosEquipe.id && dadosProfessor.id) {
                    inscricoes.forEach((d) => {
                        servicos.eventos.doc(d.evento.id).get().then((e) => {
                            if (e.data().status === 1) {
                                servicos.inscricoes.doc(d.id).update({
                                    equipe: {
                                        avatar: dadosEquipe.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                        equipe: dadosEquipe.id,
                                        professor: dadosProfessor.id,
                                        nomeEquipe: dadosEquipe.equipe,
                                        nomeProfessor: dadosProfessor.professor
                                    }
                                })
                            }
                        })
                    })
                    setTimeout((
                        mensagemSucesso('Perfil atualizado com sucesso')
                    ), 10000)
                } else {
                    mensagemSucesso('Perfil atualizado com sucesso')
                }
            }).catch((err) => {
                mensagemErro('Erro ao atualizar perfil ' + err)
            })
        }
    }

    const handleEquipe = (event) => {
        setProfessor('')
        setInputEquipe('')
        setInputProfessor('')
        event.persist();
        setEquipe(event.target.value);
        setDadosEquipe(equipes.filter((d) => d.id === event.target.value)[0])
        if (event.target.value !== '') {
            mensagemLoading('Carregando professores..')
            servicos.equipes.doc(event.target.value).collection('professores').onSnapshot((snap) => {
                const data = snap.docs.map((doc) => doc.data());
                setProfessores(data)
                Swal.close();
            })
        }
    }
    const handleProfessor = (event) => {
        event.persist();
        setProfessor(event.target.value);
        setDadosProfessor(professores.filter((d) => d.id === event.target.value)[0])
    }
    const handleInputEquipe = (event) => {
        event.persist();
        setInputEquipe(event.target.value);
    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Confirme sua equipe</h1>
                        </div>
                        <div class="headline__description">
                            <span>Conofirme sua equipe </span>
                        </div>
                    </div>
                </div>
            </div>

            <main className="section_default">
                <div className="vc_row wpb_row vc_row-fluid">
                    <div class="wpb_column vc_column_container vc_col-sm-12">
                        <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                                <div class="wpb_text_column wpb_content_element ">
                                    <div class="wpb_wrapper">
                                        <h3>Dados de Equipe / Responsável</h3>
                                        <p>Este procedimento será realizado apenas esta vez, o objetivo é realizar ajustes internos na plataforma</p>
                                        <p>Caso tenha uma inscrição em algum evento ativo, a alteração também terá efeito, não prejudicando os dados</p>
                                    </div>
                                    <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                    </div>
                                    <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                        <div class="screen-reader-response"></div>
                                        {/* <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Tipo de Usuário</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleTipoUsuario} >
                                                            <option value="">Escolha aqui o tipo </option>
                                                            <option value="competidor" selected={tipoUsuario === "competidor" ? "selected" : null} >Competidor </option>
                                                            <option value="responsavel" selected={tipoUsuario === "responsavel" ? "selected" : null} >Responsável </option>
                                                            <option value="professor" selected={tipoUsuario === "professor" ? "selected" : null} >Professor </option>
                                                        </select>
                                                    </span>
                                                </p> */}
                                        <p class="contact-form-name vc_col-sm-3">
                                            <h6>Sua equipe</h6>
                                            <span class="wpcf7-form-control-wrap your-name">
                                                <select name="select" id="select" class="form-control" onChange={handleEquipe} >
                                                    <option value="">Escolha a Equipe </option>
                                                    <option value="0" >Não está na lista </option>
                                                    {equipes.sort((a, b) => {
                                                        if (a.equipe.toUpperCase() < b.equipe.toUpperCase())
                                                            return -1;
                                                        if (a.equipe.toUpperCase() > b.equipe.toUpperCase())
                                                            return 1;
                                                        return 0;
                                                    }).map((lEquipe) =>
                                                        <option value={lEquipe.id} selected={equipe ? equipe === lEquipe.id ? "selected" : null : null} >{lEquipe.equipe.toUpperCase()}</option>
                                                    )}
                                                </select>
                                            </span>
                                        </p>
                                        {equipe !== "0" ?
                                            <p class="contact-form-name vc_col-sm-3">
                                                <h6>Seu Professor</h6>
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <select name="select" id="select" class="form-control" onChange={handleProfessor}>
                                                        <option value="">Escolha o Professor </option>
                                                        <option value="0" >Não está na lista </option>
                                                        {professores.sort((a, b) => {
                                                            if (a.professor.toUpperCase() < b.professor.toUpperCase())
                                                                return -1;
                                                            if (a.professor.toUpperCase() > b.professor.toUpperCase())
                                                                return 1;
                                                            return 0;
                                                        }).map((lProfessor) =>
                                                            <option value={lProfessor.id} selected={professor ? professor === lProfessor.id ? "selected" : null : null} >{lProfessor.professor.toUpperCase()}</option>
                                                        )}
                                                    </select>
                                                </span>
                                            </p>
                                            :
                                            null
                                        }
                                        {equipe === "0" ?
                                            <p class="contact-form-name vc_col-sm-3">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Nome da Equipe</h6>
                                                    <input
                                                        type="text" name="your-name" size="40"
                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        aria-required="true" aria-invalid="false"
                                                        onChange={handleInputEquipe}
                                                        value={inputEquipe.toUpperCase()}
                                                        placeholder="Digite o nome da equipe *" />
                                                </span>
                                            </p>
                                            :
                                            null
                                        }
                                        {equipe === "0" || professor === "0" ?
                                            <p class="contact-form-name vc_col-sm-3">
                                                <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Nome do Professor</h6>
                                                    <input
                                                        type="text" name="your-name" size="40"
                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        aria-required="true" aria-invalid="false"
                                                        onChange={handleInputProfessor}
                                                        value={inputProfessor.toUpperCase()}
                                                        placeholder="Digite o nome do professor *" />
                                                </span>
                                            </p>
                                            :
                                            null
                                        }

                                    </div>
                                    <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                    </div>
                                </div>
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3">
                                        <input type="submit" onClick={() => atualizaPerfil()} value="Salvar Dados" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}
