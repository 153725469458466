import {
  useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import 'suneditor/dist/css/suneditor.min.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import MediaQuery from 'react-responsive'

export default function ListaInscricoes() {
  const [servicos] = useState({
      auth: firebase.auth(),
      usuarios: firebase.firestore().collection("usuarios"),
      eventos: firebase.firestore().collection("eventos"),
      inscricoes: firebase.firestore().collection("inscricoes"),
      mail: firebase.firestore().collection('mail'),
  })
  const [inscricoes, setInscricoes] = useState([])
  const [validaInicioInscricao, setValidaInicioInscricao] = useState(0)
  const [validaFimInscricao, setValidaFimInscricao] = useState(0)

  const [id] = useState(window.location.pathname.replace('/listaInscricoes/', ''))
  const [usuario, setUsuario] = useState({})
  const [evento, setEvento] = useState({})

  useEffect(() => {

          servicos.auth.onAuthStateChanged((user) => {
              let idUser = JSON.parse(localStorage.getItem('usuarioId'))
              if (user) {
                  mensagemLoading('Aguarde.. Verificando Inscrições')

                  servicos.eventos.doc(id).get().then((doc) => {

                      setEvento(doc.data())
                      var dateInicioInscricao = moment(doc.data().inicioInscricao).format('DD/MM/YYYY')
                      var dataInicioInscricao = moment(dateInicioInscricao, 'DD/MM/YYYY');
                      var dateFimInscricao = moment(doc.data().fimInscricao).format('DD/MM/YYYY')
                      var dataFimInscricao = moment(dateFimInscricao, 'DD/MM/YYYY');

                      var date1 = moment().format('DD/MM/YYYY')
                      var data1 = moment(date1, 'DD/MM/YYYY');

                      setValidaInicioInscricao(dataInicioInscricao.diff(data1, 'days'))
                      setValidaFimInscricao(dataFimInscricao.diff(data1, 'days'))
                  })

                  servicos.usuarios.doc(user.uid).get().then((doc) => {
                      setUsuario(doc.data())
                  })
                  servicos.inscricoes
                      .where('evento.id', '==', id)
                      .where('usuario.id', '==', user.uid)
                      .get().then((snap) => {
                          const data = snap.docs.map((doc) => doc.data());
                          setInscricoes(data)
                          Swal.close();
                      })
              } else if (idUser) {
                  mensagemLoading('Aguarde.. Verificando Inscrições')
                  servicos.eventos.doc(id).get().then((doc) => {
                      setEvento(doc.data())
                      var dateInicioInscricao = moment(doc.data().inicioInscricao).format('DD/MM/YYYY')
                      var dataInicioInscricao = moment(dateInicioInscricao, 'DD/MM/YYYY');
                      var dateFimInscricao = moment(doc.data().fimInscricao).format('DD/MM/YYYY')
                      var dataFimInscricao = moment(dateFimInscricao, 'DD/MM/YYYY');

                      var date1 = moment().format('DD/MM/YYYY')
                      var data1 = moment(date1, 'DD/MM/YYYY');

                      setValidaInicioInscricao(dataInicioInscricao.diff(data1, 'days'))
                      setValidaFimInscricao(dataFimInscricao.diff(data1, 'days'))
                  })
                  servicos.usuarios.doc(idUser).get().then((doc) => {
                      setUsuario(doc.data())
                  })
                  servicos.inscricoes
                      .where('evento.id', '==', id)
                      .where('usuario.id', '==', idUser)
                      .get().then((snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)
                        Swal.close();
                    })
              }
          })

  }, [])

  function novaInscricao() {
      window.location = window.location.href.replace('/listaInscricoes/', '/inscricao/')
  }

  function inscricao(evn) {
      if (evn.id) {
          window.location = window.location.href.replace('/listaInscricoes/' + evento.id, '/minhaInscricao/' + evn.id)
      } else {
          if (usuario.usuario === 3) {
              window.location = window.location.href.replace('/listaCompetidores/', '/inscricaoAlunos/')
          } else {
              window.location = window.location.href.replace('/listaCompetidores/', '/inscricao/')
          }

      }

  }

  function mensagemLoading(msg) {
      Swal.fire({
          title: 'Aguarde',
          html: msg,
          timerProgressBar: true,
          didOpen: () => {
              Swal.showLoading()
          },
      })
  }

  function valorComDesconto(inscricao){
    let valorTotal = inscricao?.valorCategoriaOriginal;

    if(inscricao.valorAbsolutoOriginal > 0 ){

        if(Object.keys(inscricao.categoria.absoluto).length >0){
            valorTotal += inscricao.valorAbsoluto;
        }
    
    }

    if(inscricao.voucher && inscricao?.voucher?.tipo === "desconto"){
        valorTotal -=  valorTotal * (parseFloat(inscricao.voucher.porcentagem) / 100);
    } else if(inscricao.voucher && inscricao.voucher.tipo === "valor"){
        valorTotal -= parseFloat(inscricao.voucher.valor)
    }


    if(inscricao.valorAbsolutoOriginal === 0 ){
        
        if(Object.keys(inscricao.categoria.absoluto).length >0){
            valorTotal += inscricao.valorAbsoluto;
        }
    
    }

    return valorTotal;

}

  return (
      <div>
          <div className="body-wrapper blank-loder">
              <Header />
              <div class="section_fullwidth">
                  <div class="headline headline_img">
                      <div class="headline__inner">
                          <div class="headline__title">
                              <h1>{`Sua Lista de Inscrições no Evento \n ${evento.nome}`}  </h1>
                          </div>
                          <div class="headline__description">
                              <span>Visualize seu perfil e edite</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="section_default section-single-class-layout clearfix">
                  <main class="section_default">
                      <div class="vc_row wpb_row vc_row-fluid">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">
                                      <div class="vc_empty_space" style={{ height: 28 }}><span
                                          class="vc_empty_space_inner"></span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                              <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">
                                      <div class="wpb_text_column wpb_content_element ">
                                          <div class="wpb_wrapper">
                                              <div class="schedule-toggle-title">
                                                  <h3 class="title-section_with-sep" style={{ color: "#121212" }}>Suas Inscrições</h3>
                                              </div>
                                          </div>
                                          {(((validaInicioInscricao > 0 || validaFimInscricao < 0 ) & (!evento.statusInscricao || evento.statusInscricao === 0)) || (evento.statusInscricao === 2 )) ?
                                              <div class="schedule-toggle-nav clearfix">
                                                  <div class="form-submit__btn-wrapp btn_defoult btn_fill " style={{ backgroundColor: "#A4A4A4" }}>
                                                      <input type="submit" value="Inscrição Encerrada" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                  </div>
                                              </div>
                                              :
                                              <div class="schedule-toggle-nav clearfix">
                                                  <div class="form-submit__btn-wrapp btn_defoult btn_fill " >
                                                      <input style={{cursor:'pointer'}} type="submit" onClick={() => novaInscricao()} value="Nova Inscrição" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                  </div>
                                              </div>
                                          }
                                          <div class="schedule-empty-space" style={{ height: 27 }}></div>
                                          <h5>OBSERVAÇÕES</h5>
                                          <p style={{ marginTop: 20, marginLeft: 20 }}>* Para visualizar suas inscrições clique no icone <i className="fa  fa-search" aria-hidden="true" ></i></p>
                                          <div class="vc_col-sm-12" style={{ overflow: 'auto', marginTop: 20 }}>
                                              <MediaQuery minWidth={1224}>
                                                  <table width={'100%'} >
                                                      <tr bgcolor="#0B610B">
                                                          <th style={{ color: 'white' }}><b>Nome</b></th>
                                                          <th style={{ color: 'white' }}>Categoria Peso e Absoluto</th>
                                                          <th style={{ color: 'white' }}>Peso</th>
                                                          <th style={{ color: 'white' }}>Valor</th>
                                                          <th style={{ color: 'white' }}>Status</th>
                                                          <th style={{ color: 'white' }}>Detalhe</th>
                                                      </tr>
                                                      <tbody>
                                                          {inscricoes.map((competidor, index) =>
                                                              <tr key={index + 1} >
                                                                  <td>{competidor.usuario.nome} {competidor.usuario.sobrenome}</td>
                                                                  <td>
                                                                      {competidor.categoria.categoria.categoria} - {competidor.categoria.categoria.tipoIdade === 0 ? "ATÉ " + competidor.categoria.categoria.idade + " ANOS" : competidor.categoria.categoria.tipoIdade === 1 ? "DE " + competidor.categoria.categoria.minIdade + " ATÉ " + competidor.categoria.categoria.maxIdade + " ANOS" : "ACIMA DE " + competidor.categoria.categoria.idade} ( {competidor.categoria.faixa.faixa} )<br />
                                                                      {competidor.categoria.absoluto.id ?
                                                                          <p>Absoluto - {competidor.categoria.absoluto.absoluto}</p>
                                                                          :
                                                                          <p style={{ fontSize: 10 }}>Não possui Absoluto</p>
                                                                      }                                                                </td>
                                                                  <td>{competidor.categoria.peso.tipoPeso === 0 ? "ATÉ " + competidor.categoria.peso.peso + " KG" : "ACIMA " + competidor.categoria.peso.peso + " KG"}</td>
                                                                  {/* {competidor.voucher.id ?
                                                                      <>
                                                                          {competidor.voucher.tipo === "desconto" ?
                                                                              <td><h6 style={{ color: "green" }}>R$ {valorComDesconto(competidor)}</h6></td>
                                                                              : competidor.voucher.tipo === "valor" ?
                                                                                  <td><h6 style={{ color: "green" }}>R$ {valorComDesconto(competidor)}</h6></td>
                                                                                  :
                                                                                  <td><h6 style={{ color: "green" }}>GRATUITA</h6></td>
                                                                          }
                                                                      </>
                                                                      :
                                                                      <td><b>R$ {competidor.valorTotal}</b></td>
                                                                  } */}
                                                                  <td><b style={{color: (competidor.voucher.id || competidor.valorTotal === 0 || competidor?.descontoFiliacao?.descontoAplicado) ? 'green' : ''}}> {competidor.valorTotal ? competidor.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'GRATUITA'}</b></td>
                                                                  {competidor.pagamento.pagamento || competidor.status > 0 ?
                                                                      <>
                                                                          {competidor.pagamento.status === "approved" || competidor.status === 2 ?
                                                                              <td><p style={{ color: "green" }}><b>Inscrito</b></p></td>
                                                                              :
                                                                              <td><p style={{ color: "orange" }}><b>Pendente</b></p></td>
                                                                          }
                                                                      </>
                                                                      :
                                                                      <td><p style={{ color: "orange" }}><b>Pendente</b></p></td>
                                                                  }
                                                                  <td>
                                                                      <a href="javascript:void(0)" onClick={() => inscricao(competidor)} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                          <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                      </a>
                                                                  </td>
                                                              </tr>
                                                          )}
                                                      </tbody>
                                                  </table>
                                              </MediaQuery>
                                          </div>
                                          <div class="vc_col-sm-12" style={{ overflow: 'auto', marginTop: 20 }}>
                                              <MediaQuery minWidth={300} maxWidth={1000}>
                                                  <Table>
                                                      <Thead>
                                                          <Tr>
                                                              <Th class="schedule-table__day">Nome</Th>
                                                              <Th class="schedule-table__day">Categoria</Th>
                                                              <Th class="schedule-table__day">Peso</Th>
                                                              <Th class="schedule-table__day">Absoluto</Th>
                                                              <Th class="schedule-table__day">Valor</Th>
                                                              <Th class="schedule-table__day">Status</Th>
                                                              <Th class="schedule-table__day">Detalhe</Th>
                                                          </Tr>
                                                      </Thead>
                                                      <Tbody>
                                                          {inscricoes.map((competidor) =>
                                                              <Tr key={competidor.id}>
                                                                  <Td><p>{competidor.usuario.nome} {competidor.usuario.sobrenome}</p></Td>
                                                                  <Td><p>{competidor.categoria.categoria.categoria} - {competidor.categoria.categoria.tipoIdade === 0 ? "ATÉ " + competidor.categoria.categoria.idade + " ANOS" : competidor.categoria.categoria.tipoIdade === 1 ? "DE " + competidor.categoria.categoria.minIdade + " ATÉ " + competidor.categoria.categoria.maxIdade + " ANOS" : "ACIMA DE " + competidor.categoria.categoria.idade} ( {competidor.categoria.faixa.faixa} )</p></Td>
                                                                  <Td><p>{competidor.categoria.peso.tipoPeso === 0 ? "ATÉ " + competidor.categoria.peso.peso + " KG" : "ACIMA " + competidor.categoria.peso.peso + " KG"}</p></Td>
                                                                  {competidor.categoria.absoluto.id ?
                                                                      <Td><p>{competidor.categoria.absoluto.absoluto.toUpperCase()}</p></Td>
                                                                      :
                                                                      <Td><p>NÃO</p></Td>
                                                                  }
                                                                  <Td>
                                                                      {/* {competidor.voucher.id ?
                                                                          <>
                                                                              {competidor.voucher.tipo === "desconto" ?
                                                                                  <td><h6 style={{ color: "green" }}>R$ {(parseFloat(competidor.valorCategoriaOriginal) + parseFloat(competidor.valorAbsolutoOriginal)) - ((parseFloat(competidor.valorCategoriaOriginal) + parseFloat(competidor.valorAbsolutoOriginal)) * parseFloat(competidor.voucher.porcentagem) / 100)}</h6></td>
                                                                                  : competidor.voucher.tipo === "valor" ?
                                                                                      <td><h6 style={{ color: "green" }}>R$ {(parseFloat(competidor.valorCategoriaOriginal) + parseFloat(competidor.valorAbsolutoOriginal)) - parseFloat(competidor.voucher.valor)}</h6></td>
                                                                                      :
                                                                                      <td><h6 style={{ color: "green" }}>GRATUITA</h6></td>
                                                                              }
                                                                          </>
                                                                          :
                                                                          <p>R$ {competidor.valorTotal}
                                                                          </p>
                                                                      } */}
                                                                      
                                                                      <p style={{color: (competidor.voucher.id || competidor.valorTotal === 0 || competidor?.descontoFiliacao?.descontoAplicado) ? 'green' : ''}}> {competidor.valorTotal ? competidor.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'GRATUITA'}</p>

                                                                  </Td>
                                                                  {competidor.pagamento.pagamento || competidor.status > 0 ?
                                                                      <>
                                                                          {competidor.pagamento.status === "approved" || competidor.status === 2 ?
                                                                              <Td><p style={{ color: "green" }}>Inscrito</p></Td>
                                                                              : competidor.pagamento.tipoPagamento === "ticket" ?
                                                                                  <>
                                                                                      {moment(competidor.pagamento.vencimento).diff(moment(), 'days') <= 0 ?
                                                                                          <Td><p style={{ color: "red" }}>Boleto Vencido</p></Td>
                                                                                          :
                                                                                          competidor.pagamento.status === "pending" || competidor.pagamento.status === "in_process" ?
                                                                                              <Td><p style={{ color: "orange" }}>Aguard. Pagamento</p></Td>
                                                                                              :
                                                                                              null
                                                                                      }
                                                                                  </>
                                                                                  :
                                                                                  competidor.pagamento.status === "pending" || competidor.pagamento.status === "in_process" ?
                                                                                      <Td><p style={{ color: "orange" }}>Aguard. Pagamento</p></Td>
                                                                                      :
                                                                                      null
                                                                          }
                                                                      </>
                                                                      :
                                                                      <Td><p style={{ color: "orange" }}>Realizar Pagamento</p></Td>
                                                                  }
                                                                  <Td>
                                                                      <a href="javascript:void(0)" onClick={() => inscricao(competidor)} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                          <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                      </a>
                                                                  </Td>
                                                              </Tr>
                                                          )}
                                                      </Tbody>
                                                  </Table>
                                              </MediaQuery>
                                          </div>
                                          {inscricoes.length === 0 ?
                                              <p style={{ textAlign: 'center' }} ><h6>Sem inscrições</h6></p>
                                              :
                                              null
                                          }

                                          <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </main>
              </div>
              <Footer />
          </div>
      </div>
  );
}
