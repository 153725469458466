import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import { buscaEndereco } from '../service/WsConect'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import base64 from 'react-native-base64'
import ModalEquipes from '../componentes/ModalEquipes';
import { getPagamento, getPagamentos, atualizaPagamento, criaPagamento } from '../service/MercadoPago'


export default function Evento() {
    const [modalShow, setModalShow] = useState(false);
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        eventos: firebase.firestore().collection("eventos"),
        federacoes: firebase.firestore().collection('federacoes')
    })
    const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])
    const [inscricoes, setInscricoes] = useState([])
    const [filiacoes, setFiliacoes] = useState([])

    console.log('filiacoes: ', filiacoes);

    const [listaDia, setListaDia] = useState([
        { dia: '01' },
        { dia: '02' },
        { dia: '03' },
        { dia: '04' },
        { dia: '05' },
        { dia: '06' },
        { dia: '07' },
        { dia: '08' },
        { dia: '09' },
        { dia: '10' },
        { dia: '11' },
        { dia: '12' },
        { dia: '13' },
        { dia: '14' },
        { dia: '15' },
        { dia: '16' },
        { dia: '17' },
        { dia: '18' },
        { dia: '19' },
        { dia: '20' },
        { dia: '21' },
        { dia: '22' },
        { dia: '23' },
        { dia: '24' },
        { dia: '25' },
        { dia: '26' },
        { dia: '27' },
        { dia: '28' },
        { dia: '29' },
        { dia: '30' },
        { dia: '31' },
        { dia: '32' },
    ])
    const [listaMes] = useState([
        { mes: 'Janeiro', number: '01' },
        { mes: 'Fevereiro', number: '02' },
        { mes: 'Março', number: '03' },
        { mes: 'Abril', number: '04' },
        { mes: 'Maio', number: '05' },
        { mes: 'Junho', number: '06' },
        { mes: 'Julho', number: '07' },
        { mes: 'Agosto', number: '08' },
        { mes: 'Setembro', number: '09' },
        { mes: 'Outubro', number: '10' },
        { mes: 'Novembro', number: '11' },
        { mes: 'Dezembro', number: '12' },
    ])
    const [listaAno, setListaAno] = useState([])

    const [id, setId] = useState('')
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [cpf, setCpf] = useState('')
    const [cpfOld, setCpfOld] = useState('')
    const [dia, setDia] = useState('')
    const [mes, setMes] = useState('')
    const [ano, setAno] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [email, setEmail] = useState('')
    const [emailOld, setEmailOld] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [biografia, setBiografia] = useState('')

    const [tipoUsuario, setTipoUsuario] = useState('')
    const [responsavel, setResponsavel] = useState('')
    const [documentoResponsavel, setDocumentoResponsavel] = useState('')
    const [equipe, setEquipe] = useState('')
    const [professor, setProfessor] = useState('')
    const [dadosEquipe, setDadosEquipe] = useState({})
    const [dadosProfessor, setDadosProfessor] = useState({})
    const [inputEquipe, setInputEquipe] = useState('')
    const [inputProfessor, setInputProfessor] = useState('')

    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')
    const [dica, setDica] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('')
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)

    useEffect(() => {
        const list = []
        let d = 1940
        for (let index = 0; index < d; index++) {
            const element = parseInt(d) + parseInt(list.length)
            if (element === 2030) {
                break;
            } else {
                list.push(element)
            }
        }
        setListaAno(list)
        async function carregaDados() {
            servicos.auth.onAuthStateChanged(async (user) => {
                const snapEquipes = await servicos.equipes.where('status', '==', 1).get()
                const equipesData = snapEquipes.docs.map((doc) => doc.data());
                const l = []
                const lFinal = []
                equipesData.forEach((d) => {
                    l.push(d.nome)
                })
                const array = [...new Set(l)]
                array.forEach((d) => {
                    lFinal.push(equipesData.filter((a) => a.nome === d)[0])
                })
                setEquipes(lFinal.sort((a, b) => {
                    if (a.equipe < b.equipe)
                        return -1;
                    if (a.equipe > b.equipe)
                        return 1;
                    return 0;
                }))

                let id = JSON.parse(localStorage.getItem('usuarioId'))
                if (user) {

                    servicos.inscricoes.where('usuario.id', '==', user.uid).onSnapshot(async (snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)

                        // Atualizar Inscrições

                        if (data.some(insc => insc.status !== 2)) {

                            const inscricoesPendentes = data.filter(insc => insc.status !== 2);
                            for (let insc of inscricoesPendentes) {

                                const _evento = (await servicos.eventos.doc(insc.evento.id).get()).data();

                                const date = new Date();
                                const hoje = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

                                const [ano, mes, dia] = _evento.fimEvento.split('-').map(Number);

                                const fimEvento = Date.UTC(ano, mes - 1, dia);

                                if (hoje < fimEvento && _evento) {
                                    const res = await getPagamentos(insc.id, _evento.chavePagamento)
                                    console.log('res: ', res)
                                    if (res.results && res.results.length > 0) {
                                        for (let r of res.results) {
                                            if (!r.description.includes("ABSOLUTO")) {
                                                await verificaCategoria(r, insc, _evento.chavePagamento)
                                            }
                                        }
                                    }

                                }
                            }

                        }



                    })
                    servicos.usuarios.doc(user.uid).get().then((doc) => {
                        setLoading(false)
                        setId(doc.data().id)
                        setAvatar(doc.data().avatar)
                        setNome(doc.data().nome)
                        setSobrenome(doc.data().sobrenome)
                        setDataNascimento(doc.data().dataNascimento)
                        setCpf(doc.data().cpf)
                        setCpfOld(doc.data().cpf)
                        setDia(moment(doc.data().dataNascimento).format('DD'))
                        setMes(moment(doc.data().dataNascimento).format('MM'))
                        setAno(parseInt(moment(doc.data().dataNascimento).format('YYYY')))

                        setDDD(doc.data().ddd)
                        setCelular(doc.data().celular)
                        setSexo(doc.data().sexo)
                        setEmail(doc.data().email)
                        setEmailOld(doc.data().email)
                        setCep(doc.data().endereco.cep)
                        setEndereco(doc.data().endereco.endereco)
                        setBairro(doc.data().endereco.bairro)
                        setNumero(doc.data().endereco.numero)
                        setComplemento(doc.data().endereco.complemento)
                        setUf(doc.data().endereco.uf)
                        setCidade(doc.data().endereco.cidade)
                        setTipoUsuario(doc.data().tipoUsuario)
                        if (doc.data().professor) {
                            setProfessor(doc.data().professor)
                            if (doc.data().equipe) {
                                servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then(snap => setDadosProfessor(snap.data()))
                            }
                        }
                        setResponsavel(doc.data().responsavel)
                        setDocumentoResponsavel(doc.data().documentoResponsavel)
                        setDica(doc.data().dica)
                        setBiografia(doc.data().biografia)
                        if (doc.data().equipe) {
                            setEquipe(doc.data().equipe)
                            if (lFinal.some(eqp => eqp.id === doc.data().equipe)) {
                                setDadosEquipe(lFinal.filter((d) => d.id === doc.data().equipe)[0])
                            }
                            servicos.equipes.doc(doc.data().equipe).collection('professores').onSnapshot((snap) => {
                                const data = snap.docs.map((doc) => doc.data());
                                setProfessores(data)
                            })
                        }

                        // Carregar filiações
                        console.log('data?.filiacoes?.length: ', doc.data()?.filiacoes?.length);
                        if (doc.data()?.filiacoes?.length > 0) {
                            servicos.federacoes.where('id', 'in', doc.data().filiacoes).get().then(async snap => {

                                let dataFiliacoes = snap.docs.map(d => d.data())
                                console.log('dataFiliacoes: ', dataFiliacoes);

                                for (let [index, federacao] of dataFiliacoes.entries()) {
                                    const snapFiliacao = await servicos.federacoes.doc(federacao.id).collection('filiacoes')
                                        .where('idFiliado', '==', doc.data().id)
                                        .where('tipoUsuario', '==', doc.data().tipoUsuario)
                                        .get()

                                    if (snap.size > 0) {
                                        const dadosFiliacao = snapFiliacao.docs.map(d => d.data())[0]
                                        console.log('dadosFiliacao: ', dadosFiliacao);
                                        dataFiliacoes[index].filiacao = dadosFiliacao
                                    }

                                }

                                setFiliacoes(dataFiliacoes)


                            })
                        }

                    })
                } else if (id) {
                    servicos.inscricoes.where('usuario.id', '==', id).onSnapshot(async (snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)


                        // Atualizar Inscrições

                        if (data.some(insc => insc.status !== 2)) {

                            const inscricoesPendentes = data.filter(insc => insc.status !== 2);
                            for (let insc of inscricoesPendentes) {

                                const _evento = (await servicos.eventos.doc(insc.evento.id).get()).data();

                                const date = new Date();
                                const hoje = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

                                const [ano, mes, dia] = _evento.fimEvento.split('-').map(Number);

                                const fimEvento = Date.UTC(ano, mes - 1, dia);

                                if (hoje < fimEvento && _evento) {
                                    const res = await getPagamentos(insc.id, _evento.chavePagamento)
                                    console.log('res: ', res)
                                    if (res.results && res.results.length > 0) {
                                        for (let r of res.results) {
                                            if (!r.description.includes("ABSOLUTO")) {
                                                await verificaCategoria(r, insc, _evento.chavePagamento)
                                            }
                                        }
                                    }

                                }
                            }

                        }

                    })
                    servicos.usuarios.doc(id).get().then((doc) => {
                        try {
                            servicos.auth.signInWithEmailAndPassword(doc.data().email, base64.decode(doc.data().pass).replace(id, ''));
                        } catch (error) {
                            console.log(error.message);
                        }
                        setLoading(false)
                        setId(doc.data().id)
                        setAvatar(doc.data().avatar)
                        setNome(doc.data().nome)
                        setSobrenome(doc.data().sobrenome)
                        setDataNascimento(doc.data().dataNascimento)
                        setCpf(doc.data().cpf)
                        setCpfOld(doc.data().cpf)
                        setDia(moment(doc.data().dataNascimento).format('DD'))
                        setMes(moment(doc.data().dataNascimento).format('MM'))
                        setAno(parseInt(moment(doc.data().dataNascimento).format('YYYY')))

                        setDDD(doc.data().ddd)
                        setCelular(doc.data().celular)
                        setSexo(doc.data().sexo)
                        setEmail(doc.data().email)
                        setEmailOld(doc.data().email)
                        setCep(doc.data().endereco.cep)
                        setEndereco(doc.data().endereco.endereco)
                        setBairro(doc.data().endereco.bairro)
                        setNumero(doc.data().endereco.numero)
                        setComplemento(doc.data().endereco.complemento)
                        setUf(doc.data().endereco.uf)
                        setCidade(doc.data().endereco.cidade)
                        setTipoUsuario(doc.data().tipoUsuario)
                        if (doc.data().professor) {
                            setProfessor(doc.data().professor)
                            if (doc.data().equipe) {
                                servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then(snap => setDadosProfessor(snap.data()))
                            }
                        }
                        setResponsavel(doc.data().responsavel)
                        setDocumentoResponsavel(doc.data().documentoResponsavel)
                        setDica(doc.data().dica)
                        setBiografia(doc.data().biografia)
                        if (doc.data().equipe) {
                            setEquipe(doc.data()?.equipe)
                            if (lFinal.some(eqp => eqp.id === doc.data().equipe)) {
                                setDadosEquipe(lFinal.filter((d) => d.id === doc.data().equipe)[0])
                            }
                            servicos.equipes.doc(doc.data().equipe).collection('professores').onSnapshot((snap) => {
                                const data = snap.docs.map((doc) => doc.data());
                                setProfessores(data)
                            })
                        }

                    })
                }
            })

        }
        carregaDados();

    }, [])


    async function verificaCategoria(list, insc, chavePgto) {
        if (list.status === 'approved') {
            const pag = await getPagamento(list.id, chavePgto)
            await servicos.inscricoes.doc(insc.id).update({
                pagamento: {
                    status: pag.status,
                    barcode: pag.barcode ? pag.barcode.content : null,
                    pagamento: pag.id,
                    tipoPagamento: pag.payment_type_id,
                    vencimento: pag.date_of_expiration ? pag.date_of_expiration : '',
                },
                dataPagamento: pag.status === 'approved' ? moment().format('YYYY-MM-DD HH:mm') : '',
                status: pag.status === 'approved' ? parseInt(2) : insc.status
            })

        }

    }

    function cadastraAvatar() {
        Swal.close();
        mensagemLoading('Salvando imagem do seu avatar')
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('usuarios/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    mudaSenha();
                })
            });
        });
    }

    async function cadastraUsuario() {
        setLoading(true)

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailPattern.test(email)) {
            mensagemAlerta("Por favor, insira um email válido.");
            return;
        }

        if (email.trim().length > 0 && email !== emailOld) {
            const emailDisp = await itemDisponivel('email', email);
            if (emailDisp === false) {
                setLoading(false);
                mensagemAlerta("Este Email já está cadastrado no Sistema.");
                return;
            }
        }


        if (cpf.trim().length === 0) {
            setLoading(false);
            mensagemAlerta('Favor digite seu CPF');
            return;
        }

        if (cpf.trim().length > 0 && cpf !== cpfOld) {
            const cpfDisp = await itemDisponivel('cpf', cpf);
            if (cpfDisp === false) {
                setLoading(false);
                mensagemAlerta("Este CPF já está cadastrado no Sistema.");
                return;
            }
        }

        if (Object.keys(dadosEquipe).length === 0) {
            mensagemAlerta('Por favor, selecione sua equipe. ')
            setLoading(false)
            return;
        }
        if (Object.keys(dadosProfessor).length === 0 && inputProfessor.trim() === '') {
            mensagemAlerta('Por favor, informe seu professor.')
            setLoading(false)
            return;
        }

        if (nome === '') {
            mensagemAlerta('Favor digitar seu primeiro nome')
            setLoading(false)
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar seu sobrenome')
            setLoading(false)
        } else if (ddd === '' || ddd.length < 2) {
            mensagemAlerta('DDD vazio ou incompleto')
            setLoading(false)
        } else if (celular === '' || celular.length < 9) {
            mensagemAlerta('Celular vazio ou incompleto! Não esqueça de acrescentar o 9 no começo do número')
            setLoading(false)
        }
        else if (!dia) {
            mensagemAlerta('Escolha o dia de Nascimento')
            setLoading(false)
        } else if (!mes) {
            mensagemAlerta('Favor escolha o Mês de Nascimento')
            setLoading(false)
        } else if (!ano) {
            mensagemAlerta('Favor escolha o Ano de Nascimento')
            setLoading(false)
        } else if (dia + '/' + mes + '/' + ano === moment().format('DD/MM/YYYY')) {
            mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
            setLoading(false)
        }
        else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
            setLoading(false)
        } else if (tipoUsuario === '') {
            mensagemAlerta('Favor configure os dados de equipe / responsável')
            setLoading(false)
        } else {
            if (professor === '0') {
                if (inputProfessor === '') {
                    mensagemAlerta('Favor diga o nome do seu professor! Caso você seja o professor é só digitar seu nome')
                } else {
                    mensagemLoading('Cadastrando professor...')
                    servicos.equipes.doc(equipe).collection('professores').add({
                        professor: inputProfessor
                    }).then((refProfessor) => {
                        setProfessor(refProfessor.id)
                        servicos.equipes.doc(equipe).collection('professores').doc(refProfessor.id).update({
                            id: refProfessor.id
                        }).then(() => {
                            Swal.close()
                            cadastraEmail('', refProfessor.id)
                        }).catch(() => {
                            console.log('Erro ao atualizar professor')
                        })
                    }).catch(() => {
                        console.log('Erro ao cadastrar professor')
                    })
                }
            } else {
                if (equipe === '' || professor === '') {
                    mensagemAlerta('Favor selecione sua equipe e professor')
                } else {
                    cadastraEmail('', '')
                }
            }

        }

    }

    async function itemDisponivel(item, comparacao) {
        // validar se um dado item (ex: CPF ou Email) está disponvel ou já existe cadastrado. (obs: apenas na collection usuarios)
        try {
            const snap = await servicos.usuarios.where(item, '==', comparacao).get();
            if (snap.empty) {
                return true
            } else if (!snap.empty) {
                return false
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function cadastraEmail(idEquipe, idProfessor) {
        mensagemLoading('Atualizando seu perfil...')
        if (email !== emailOld) {
            servicos.auth.currentUser.updateEmail(email).then((res) => {
                let updateUsuario = {
                    nome: nome.toUpperCase(),
                    sobrenome: sobrenome.toUpperCase(),
                    ddd: ddd,
                    celular: celular,
                    // dataNascimento: dataNascimento,
                    dataNascimento: ano + '-' + mes + '-' + dia,
                    biografia: biografia ? biografia : '',
                    email: email,
                    endereco: {
                        endereco: endereco.toUpperCase(),
                        bairro: bairro.toUpperCase(),
                        numero: numero,
                        complemento: complemento ? complemento.toUpperCase() : '',
                        uf: uf.toUpperCase(),
                        cidade: cidade.toUpperCase(),
                        cep: cep.replace(/[^a-z0-9]/gi, '')
                    },
                    sexo: sexo,
                    tipoUsuario: tipoUsuario,
                    equipe: idEquipe ? idEquipe : equipe,
                    professor: idProfessor ? idProfessor : professor,
                    dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
                }
                if (cpf.trim().length > 0 && cpf !== cpfOld) {
                    updateUsuario = { ...updateUsuario, cpf: cpf.trim() };
                }
                servicos.usuarios.doc(id).update(updateUsuario).then(() => {
                    Swal.close()
                    if (upload) {
                        cadastraAvatar()
                    } else {
                        if (idEquipe || idProfessor) {
                            inscricoes.forEach((d) => {
                                servicos.eventos.doc(d.evento.id).get().then((e) => {
                                    if (e.data().status === 1) {
                                        servicos.inscricoes.doc(d.id).update({
                                            equipe: {
                                                avatar: idEquipe?.avatar ? idEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                                equipe: idEquipe.id ? idEquipe.id : dadosEquipe.id,
                                                professor: idProfessor,
                                                nomeEquipe: idEquipe.id ? idEquipe.equipe : dadosEquipe.equipe,
                                                nomeProfessor: idProfessor.professor
                                            }
                                        })
                                    }
                                })
                            })
                            mudaSenha();
                        } else if (dadosEquipe.id && dadosProfessor.id) {
                            inscricoes.forEach((d) => {
                                servicos.eventos.doc(d.evento.id).get().then((e) => {
                                    if (e.data().status === 1) {
                                        servicos.inscricoes.doc(d.id).update({
                                            equipe: {
                                                avatar: dadosEquipe.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                                equipe: dadosEquipe.id,
                                                professor: dadosProfessor.id,
                                                nomeEquipe: dadosEquipe.equipe,
                                                nomeProfessor: dadosProfessor.professor
                                            }
                                        })
                                    }
                                })
                            })
                            mudaSenha();
                        } else {
                            inscricoes.forEach((d) => {
                                servicos.eventos.doc(d.evento.id).get().then((e) => {
                                    if (e.data().status === 1) {
                                        servicos.equipes.doc(equipe).get().then((eq) => {
                                            servicos.equipes.doc(equipe).collection('professores').doc(professor).get().then((p) => {
                                                servicos.inscricoes.doc(d.id).update({
                                                    equipe: {
                                                        avatar: eq.data().avatar ? eq.data().avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                                        equipe: equipe,
                                                        professor: professor,
                                                        nomeEquipe: eq.data().equipe,
                                                        nomeProfessor: p.data().professor
                                                    }
                                                })
                                            })
                                        })
                                    }
                                })
                            })
                        }

                        mudaSenha();
                    }
                }).catch(() => {
                    mensagemErro('Erro ao atualizar perfil')
                })
            }).catch((erro) => {
                Swal.close();
                console.log(erro);
                var errorCode = erro.code;
                if (errorCode == "auth/invalid-email") {
                    mensagemAlerta('O formato do Email é inválido!')
                    setLoading(false)
                } else if (errorCode == "auth/email-already-in-use") {
                    mensagemAlerta('O email já está em uso pelo Procompetidor')
                    setLoading(false)
                } else if (errorCode == "auth/requires-recent-login") {
                    mensagemAlerta('Por questões de segurança para alterar o email é preciso realizar novamente o Login!')
                    setLoading(false)
                }
            })
        } else {
            let updateUsuario = {
                nome: nome.toUpperCase(),
                sobrenome: sobrenome.toUpperCase(),
                ddd: ddd,
                celular: celular,
                // dataNascimento: dataNascimento,
                dataNascimento: ano + '-' + mes + '-' + dia,
                biografia: biografia ? biografia : '',
                endereco: {
                    endereco: endereco.toUpperCase(),
                    bairro: bairro.toUpperCase(),
                    numero: numero,
                    complemento: complemento ? complemento.toUpperCase() : '',
                    uf: uf.toUpperCase(),
                    cidade: cidade.toUpperCase(),
                    cep: cep
                },
                sexo: sexo,
                tipoUsuario: tipoUsuario,
                equipe: idEquipe ? idEquipe : equipe,
                professor: idProfessor ? idProfessor : professor,
                dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
            }

            if (cpf.trim().length > 0 && cpf !== cpfOld) {
                updateUsuario = { ...updateUsuario, cpf: cpf.trim() };
            }

            servicos.usuarios.doc(id).update(updateUsuario).then(async () => {
                Swal.close()
                if (upload) {
                    cadastraAvatar()
                } else {
                    if (idEquipe || idProfessor) {
                        const _professor = (await servicos.equipes.doc(idEquipe ? idEquipe : equipe).collection('professores').doc(idProfessor).get()).data();
                        const _equipe = (await servicos.equipes.doc(idEquipe ? idEquipe : equipe).get()).data();
                        inscricoes.forEach((d) => {
                            servicos.eventos.doc(d.evento.id).get().then((e) => {
                                if (e.data().status === 1) {
                                    servicos.inscricoes.doc(d.id).update({
                                        equipe: {
                                            avatar: dadosEquipe?.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                            equipe: _equipe ? _equipe.id : dadosEquipe.id,
                                            professor: idProfessor,
                                            nomeEquipe: _equipe ? _equipe.equipe : dadosEquipe.equipe,
                                            nomeProfessor: _professor.professor
                                        }
                                    })
                                }
                            })
                        })
                        mudaSenha();
                    } else {
                        if (dadosEquipe.id && dadosProfessor.id) {
                            inscricoes.forEach((d) => {
                                servicos.eventos.doc(d.evento.id).get().then((e) => {
                                    if (e.data().status === 1) {
                                        servicos.inscricoes.doc(d.id).update({
                                            equipe: {
                                                avatar: dadosEquipe.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                                equipe: dadosEquipe.id,
                                                professor: dadosProfessor.id,
                                                nomeEquipe: dadosEquipe.equipe,
                                                nomeProfessor: dadosProfessor.professor
                                            }
                                        })
                                    }
                                })
                            })
                            mudaSenha();
                        } else {
                            mudaSenha();
                        }
                    }

                }
            }).catch((err) => {
                mensagemErro('Erro ao atualizar perfil')
                console.log('erro:', err.message);
            })
        }
    }

    function mudaSenha() {
        if (senha && confirmaSenha) {
            if (senha !== confirmaSenha) {
                mensagemAlerta('As senhas não conferem')
                return
            }
            if (!dica) {
                mensagemAlerta('Digite a dica para lembrar melhor sua senha')
                return
            }
            servicos.auth.currentUser.updatePassword(confirmaSenha).then(() => {
                servicos.usuarios.doc(id).update({
                    dica: dica,
                    pass: base64.encode(senha + id)
                }).then(() => {
                    if (dadosEquipe.id && dadosProfessor.id) {
                        inscricoes.forEach((d) => {
                            servicos.eventos.doc(d.evento.id).get().then((e) => {
                                if (e.data().status !== 1) {
                                    servicos.inscricoes.doc(d.id).update({
                                        equipe: {
                                            avatar: dadosEquipe.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                            equipe: dadosEquipe.id,
                                            professor: dadosProfessor.id,
                                            nomeEquipe: dadosEquipe.equipe,
                                            nomeProfessor: dadosProfessor.professor
                                        }
                                    })
                                }
                            })
                        })
                        setTimeout((
                            mensagemSucesso('Seu perfil foi atualizado com sucesso! Caso esqueça a senha, ir a tela de Login para recuperar a senha')
                        ), 10000)
                    } else {
                        mensagemSucesso('Seu perfil foi atualizado com sucesso! Caso esqueça a senha, ir a tela de Login para recuperar a senha')
                    }
                }).catch(() => {
                    mensagemErro('Erro ao alterar a senha')
                })
            }).catch((erro) => {
                Swal.close();
                console.log(erro);
                var errorCode = erro.code;
                if (errorCode == "auth/requires-recent-login") {
                    mensagemAlerta('Por questões de segurança para alterar a senha é preciso realizar novamente o Login!')
                    setLoading(false)
                }
            })
        } else {
            if (dadosEquipe?.id && dadosProfessor?.id) {
                inscricoes.forEach((d) => {
                    servicos.eventos.doc(d.evento.id).get().then((e) => {
                        if (e.data().status === 1) {
                            servicos.inscricoes.doc(d.id).update({
                                equipe: {
                                    avatar: dadosEquipe.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                    equipe: dadosEquipe.id,
                                    professor: dadosProfessor.id,
                                    nomeEquipe: dadosEquipe.equipe,
                                    nomeProfessor: dadosProfessor.professor
                                }
                            })
                        }
                    })
                })
                mensagemSucesso('Seu perfil foi atualizado com sucesso! Caso esqueça a senha, ir a tela de Login para recuperar a senha')
            } else {
                mensagemSucesso('Seu perfil foi atualizado com sucesso! Caso esqueça a senha, ir a tela de Login para recuperar a senha')
            }
        }
    }


    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleDataNascimento = (event) => {
        event.persist();
        setDataNascimento(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            mensagemLoading('Aguarde, verificando o seu CPF...')
            servicos.usuarios.where('cpf', '==', event.target.value).get().then((snap) => {
                if (snap.size === 0) {
                    Swal.close();
                } else {
                    Swal.close();
                    mensagemAlerta('CPF já cadastrado no sistema! Verifique novamente ou faça login')
                }
            })
        }
    }
    const handleDia = (event) => {
        event.persist();
        setDia(event.target.value);
    }
    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleSexo = (event) => {
        event.persist();
        setSexo(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.replace(/[^a-z0-9]/gi, '').length === 8) {
            buscaCep(event.target.value);
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    // const handleEquipe = (event) => {
    //     setProfessor('')
    //     setInputEquipe('')
    //     setInputProfessor('')
    //     event.persist();
    //     setEquipe(event.target.value);
    //     setDadosEquipe(equipes.filter((d) => d.id === event.target.value)[0])
    //     if (event.target.value !== '') {
    //         mensagemLoading('Carregando professores..')
    //         servicos.equipes.doc(event.target.value).collection('professores').onSnapshot((snap) => {
    //             const data = snap.docs.map((doc) => doc.data());
    //             setProfessores(data)
    //             Swal.close();
    //         })
    //     }
    // }
    const handleProfessor = (event) => {
        event.persist();
        setProfessor(event.target.value);
        if (event.target.value.trim().length > 0 && event.target.value !== '0') {
            setDadosProfessor(professores.filter((d) => d.id === event.target.value)[0])
        } else {
            setDadosProfessor({});
        }
    }
    const handleInputEquipe = (event) => {
        event.persist();
        setInputEquipe(event.target.value);
    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value);
    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }
    const handleDica = (event) => {
        event.persist();
        setDica(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    const handleBiografia = (event) => {
        setBiografia(event);
    }

    function buscaCep(c) {
        mensagemLoading('Aguarde procurando CEP...')
        buscaEndereco(c).then((doc) => {
            if (doc.erro === true) {
                setLoadingCep(false)
                mensagemErro('Não achamos seu CEP! Verifique novamente ou digite manualmente')
            } else {
                Swal.close();
                setEndereco(doc.logradouro)
                setBairro(doc.bairro)
                setCidade(doc.localidade)
                setUf(doc.uf)
                setLoadingCep(false)
            }
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning').then(() => {
            Swal.hideLoading();
        })
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function detalheInscricao(dados) {
        window.location = '/minhaInscricao/' + dados.id
    }

    function mensagemStatus(status) {
        let mensagem = ''
        switch (status) {
            case 1: mensagem = 'Pendente';
                break;
            case 2: mensagem = 'Aprovada';
                break;
            case 3: mensagem = 'Reprovado';
                break;
            default: mensagem = '';
                break
        }

        return mensagem;

    }
    function mensagemCor(status) {
        let cor = ''

        switch (status) {
            case 1: case 'Pendente': cor = 'orange'; break;
            case 2: case 'Aprovada': cor = 'green'; break;
            case 3: case 'Reprovada': cor = 'red'; break;
            case 4: case 'Vencida': cor = 'purple'; break;
            default: cor = ''; break;
        }

        return cor;

    }

    function compartilhaPerfil() {
        window.location.href = 'competidor/' + id
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Olá, {nome}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Visualize seu perfil e edite</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3">
                                <input type="submit" onClick={() => compartilhaPerfil()} value="Compartilhar meu Perfil" class="wpcf7-form-control wpcf7-pnchr_submit" />
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados Pessoais</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="package-style-1 pnchr-package clearfix vc_col-sm-4">
                                                <div >
                                                    {upload ?
                                                        <img src={upload} alt="" title="image-here-1" />
                                                        :
                                                        <img src={avatar} alt="" title="image-here-1" />
                                                    }
                                                </div>
                                                <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                            </div>
                                            <div role="form" class="wpcf7 row " id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNome}
                                                            value={nome.toUpperCase()}
                                                            placeholder="Nome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sobrenome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleSobrenome}
                                                            value={sobrenome.toUpperCase()}
                                                            placeholder="Sobrenome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CPF</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCpf}
                                                            value={cpf}
                                                            placeholder="CPF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Dia</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleDia} >
                                                            <option value="">Dia do Nascimento </option>
                                                            {listaDia.map((diaRef) =>
                                                                <option value={diaRef.dia} selected={dia && parseInt(dia) === parseInt(diaRef.dia) ? "selected" : null}>{diaRef.dia}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Mês de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleMes} >
                                                            <option value="">Mẽs do Nascimento </option>
                                                            {listaMes.map((mesRef) =>
                                                                <option value={mesRef.number} selected={mes && parseInt(mes) === parseInt(mesRef.number) ? "selected" : null}>{mesRef.mes.toUpperCase()}</option>
                                                            )}
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Ano de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleAno} >
                                                            <option value="">Ano do Nascimento </option>
                                                            {listaAno.map((anoRef) =>
                                                                <option value={anoRef} selected={ano && ano === anoRef ? "selected" : null}>{anoRef}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                {/* <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Data Nascimento</h6>
                                                        <input
                                                            type="date" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            value={dataNascimento}
                                                            onChange={handleDataNascimento}
                                                            placeholder="DD/MM/YYYY *" />
                                                    </span>
                                                </p> */}
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>DDD</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDDD}
                                                            value={ddd}
                                                            maxLength={2}
                                                            placeholder="DDD *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Celular</h6>
                                                        <input
                                                            maxLength={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCelular}
                                                            value={celular}
                                                            placeholder="9 + Celular *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <h6>Sexo</h6>
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <select name="select" id="select" class="form-control" onChange={handleSexo} >
                                                            <option value="">Escolha o Sexo </option>
                                                            <option value="masculino" selected={sexo === "masculino" ? "selected" : null} >MASCULINO </option>
                                                            <option value="feminino" selected={sexo === "feminino" ? "selected" : null}>FEMININO </option>
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Email</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEmail}
                                                            value={email}
                                                            placeholder="Email *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <p>Guarde bem o seu email, ele será importante para receber as notificações e alterar sua senha</p>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {tipoUsuario === "responsavel" || tipoUsuario === "professor" ?
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <div class="schedule-toggle-title">
                                                        <h3 class="title-section_with-sep" style={{ color: "#121212" }}>Seus competidores</h3>
                                                    </div>
                                                </div>

                                                <div class="schedule-toggle-nav clearfix">
                                                    <div class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                        <input type="submit" onClick={() => cadastraUsuario()} value="Novo Competidor" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                    </div>
                                                </div>
                                                <div class="schedule-empty-space" style={{ height: 27 }}></div>
                                                <table style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr >
                                                            <th class="schedule-table__day">Avatar</th>
                                                            <th class="schedule-table__day">Nome</th>
                                                            <th class="schedule-table__day">Sexo</th>
                                                            <th class="schedule-table__day">Idade</th>
                                                            <th class="schedule-table__day">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ height: 50 }}><h6>Guilherme</h6></td>
                                                            <td><h6>Guilherme</h6></td>
                                                            <td><h6>Guilherme</h6></td>
                                                            <td><h6>Guilherme</h6></td>
                                                            <td><h6>Guilherme</h6></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <p style={{ textAlign: 'center' }} ><h6>Sem competidores</h6></p>
                                                <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <h3>Sua Biografia</h3>
                                                </div>
                                                <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                                <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                    <div class="screen-reader-response"></div>
                                                    <p class="contact-form-name vc_col-sm-12">
                                                        <SunEditor
                                                            lang="pt_br"
                                                            placeholder="Digite aqui sua biografia"
                                                            onChange={handleBiografia}
                                                            setContents={biografia}
                                                        />
                                                    </p>
                                                </div>
                                                <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Endereço</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CEP</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCep}
                                                            value={cep}
                                                            placeholder="CEP *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Endereço</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEndereco}
                                                            value={endereco.toUpperCase()}
                                                            placeholder="Endereço *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Bairro</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleBairro}
                                                            value={bairro.toUpperCase()}
                                                            placeholder="Bairro *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Cidade</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCidade}
                                                            value={cidade.toUpperCase()}
                                                            placeholder="Cidade *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Estado</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleUf}
                                                            value={uf.toUpperCase()}
                                                            placeholder="UF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Complemento</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleComplemento}
                                                            value={complemento}
                                                            placeholder="Complemento" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>N°</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNumero}
                                                            value={numero}
                                                            placeholder="N°" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Equipe / Responsável</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                {/* <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Tipo de Usuário</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleTipoUsuario} >
                                                            <option value="">Escolha aqui o tipo </option>
                                                            <option value="competidor" selected={tipoUsuario === "competidor" ? "selected" : null} >Competidor </option>
                                                            <option value="responsavel" selected={tipoUsuario === "responsavel" ? "selected" : null} >Responsável </option>
                                                            <option value="professor" selected={tipoUsuario === "professor" ? "selected" : null} >Professor </option>
                                                        </select>
                                                    </span>
                                                </p> */}
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <h6>Sua equipe</h6>
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <input type='text' name="select"
                                                            class="form-control"
                                                            value={dadosEquipe?.equipe ? dadosEquipe?.equipe : ''}
                                                            placeholder='Selecione uma equipe'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setModalShow(true)}
                                                        />
                                                    </span>
                                                </p>
                                                {equipe && equipe !== "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <h6>Seu Professor</h6>
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <select name="select" id="select" class="form-control" onChange={handleProfessor}>
                                                                <option value="">Escolha o Professor </option>
                                                                <option value="0" >Não está na lista </option>
                                                                {professores.sort((a, b) => {
                                                                    if (a.professor.toUpperCase() < b.professor.toUpperCase())
                                                                        return -1;
                                                                    if (a.professor.toUpperCase() > b.professor.toUpperCase())
                                                                        return 1;
                                                                    return 0;
                                                                }).map((lProfessor) =>
                                                                    <option value={lProfessor.id} selected={professor ? professor === lProfessor.id ? "selected" : null : null} >{lProfessor.professor.toUpperCase()}</option>
                                                                )}
                                                            </select>
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }
                                                {/* {equipe === "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Nome da Equipe</h6>
                                                            <input
                                                                type="text" name="your-name" size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false"
                                                                onChange={handleInputEquipe}
                                                                value={inputEquipe.toUpperCase()}
                                                                placeholder="Digite o nome da equipe *" />
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                } */}
                                                {equipe === "0" || professor === "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Nome do Professor</h6>
                                                            <input
                                                                type="text" name="your-name" size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false"
                                                                onChange={handleInputProfessor}
                                                                value={inputProfessor.toUpperCase()}
                                                                placeholder="Digite o nome do professor *" />
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }

                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de acesso</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nova Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleSenha}
                                                            value={senha}
                                                            placeholder="Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Confirma Nova Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleConfirmaSenha}
                                                            value={confirmaSenha}
                                                            placeholder="Confirma Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Uma dica para lembrar de sua senha</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDica}
                                                            value={dica}
                                                            placeholder="Dica de senha *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3">
                                                <input type="submit" onClick={() => cadastraUsuario()} value="Salvar Dados" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {tipoUsuario === "competidor" ?
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner ">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <div class="schedule-toggle-title">
                                                        <h3 class="title-section_with-sep" style={{ color: "#121212" }}>Minhas Competições</h3>
                                                    </div>
                                                </div>
                                                <div class="schedule-empty-space" style={{ height: 27 }}></div>
                                                <Table>
                                                    <Thead>
                                                        <Tr >
                                                            <Th class="schedule-table__day">Avatar</Th>
                                                            <Th class="schedule-table__day">Evento</Th>
                                                            <Th class="schedule-table__day">Categoria Principal</Th>
                                                            <Th class="schedule-table__day">Situação</Th>
                                                            <Th class="schedule-table__day">Ação</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {inscricoes.map((inscricao, index) =>
                                                            <Tr>
                                                                <Td >
                                                                    <img
                                                                        style={{ width: 60, height: 60 }}
                                                                        src={inscricao.evento.avatar}
                                                                        alt="" title="image here (2)" />
                                                                </Td>
                                                                <Td><h5>{inscricao.evento.nome}</h5></Td>
                                                                <Td><h5>{inscricao.categoria.categoria.categoria} - {inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade} ( {inscricao.categoria.faixa.faixa} )</h5></Td>
                                                                <Td>
                                                                    {inscricao.status === 0 ?
                                                                        <h5 style={{ color: "orange" }}>Realizar Pagamento</h5>
                                                                        : inscricao.status === 1 ?
                                                                            <h5 style={{ color: "orange" }}>Aguardando Pagamento</h5>
                                                                            : inscricao.status === 2 ?
                                                                                <h5 style={{ color: "green" }}>Inscrição Confirmada</h5>
                                                                                :
                                                                                <h5 style={{ color: "red" }}>Insccrição Cancelada</h5>
                                                                    }
                                                                </Td>
                                                                <Td>
                                                                    <a href="javascript:void(0)" onClick={() => detalheInscricao(inscricao)} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                        <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                    </a>
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                    </Tbody>
                                                </Table>
                                                {inscricoes.length === 0 ?
                                                    <p style={{ textAlign: 'center' }} ><h6>Sem competições ativas</h6></p>
                                                    :
                                                    null
                                                }
                                                <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>

                        {/* Filiações - Início */}
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="wpb_wrapper">
                                            <div class="schedule-toggle-title">
                                                <h3 class="title-section_with-sep" style={{ color: "#121212" }}>Minhas Filiações</h3>
                                            </div>
                                        </div>
                                        <div class="schedule-empty-space" style={{ height: 27 }}></div>
                                        <Table>
                                            <Thead>
                                                <Tr >
                                                    <Th class="schedule-table__day">Imagem</Th>
                                                    <Th class="schedule-table__day">Federação</Th>
                                                    <Th class="schedule-table__day">Status</Th>
                                                    <Th class="schedule-table__day">Detalhes</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {filiacoes.map(federacao =>
                                                    <Tr>
                                                        <Td >
                                                            <img
                                                                style={{ width: 60, height: 60 }}
                                                                src={federacao.urlImagemFederacao}
                                                                alt="" title="image here (2)" />
                                                        </Td>
                                                        <Td><h5>{federacao.nome}</h5></Td>
                                                        <Td style={{ color: mensagemCor(federacao?.filiacao?.statusFiliacao) }}>
                                                                        <span><b>{mensagemStatus(federacao?.filiacao?.statusFiliacao)}</b></span>


                                                                    </Td>
                                                        <Td>
                                                            <a href={`filiacao/${federacao.id}`} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                <i className="fa  fa-search" aria-hidden="true" ></i>
                                                            </a>
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </Tbody>
                                        </Table>
                                        {inscricoes.length === 0 ?
                                            <p style={{ textAlign: 'center' }} ><h6>Sem competições ativas</h6></p>
                                            :
                                            null
                                        }
                                        <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Filiações - Fim */}

                    </main>
                </div>
                <Footer />
            </div>
            {modalShow && (
                <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={setEquipe} setDadosEquipe={setDadosEquipe} setProfessores={setProfessores} setDadosProfessor={setDadosProfessor}
                />
            )}

        </div>
    );
}
