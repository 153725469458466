import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import { buscaEndereco } from '../service/WsConect'
import { cpf } from 'cpf-cnpj-validator';

export default function Evento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes")
    })

    const [listaDia, setListaDia] = useState([
        { dia: '01' },
        { dia: '02'  },
        { dia: '03'  },
        { dia: '04'  },
        { dia: '05'  },
        { dia: '06'  },
        { dia: '07'  },
        { dia: '08'  },
        { dia: '09'  },
        { dia: '10'  },
        { dia: '11'  },
        { dia: '12'  },
        { dia: '13'},
        { dia: '14'},
        { dia: '15'},
        { dia: '16'},
        { dia: '17'},
        { dia: '18'},
        { dia: '19'},
        { dia: '20'},
        { dia: '21'},
        { dia: '22' },
        { dia: '23' },
        { dia: '24' },
        { dia: '25' },
        { dia: '26' },
        { dia: '27' },
        { dia: '28' },
        { dia: '29' },
        { dia: '30' },
        { dia: '31' },
        { dia: '32' },
    ])
    const [listaMes] = useState([
        { mes: 'Janeiro', number: '01' },
        { mes: 'Fevereiro', number: '02' },
        { mes: 'Março', number: '03' },
        { mes: 'Abril', number: '04' },
        { mes: 'Maio', number: '05' },
        { mes: 'Junho', number: '06' },
        { mes: 'Julho', number: '07' },
        { mes: 'Agosto', number: '08' },
        { mes: 'Setembro', number: '09' },
        { mes: 'Outubro', number: '10' },
        { mes: 'Novembro', number: '11' },
        { mes: 'Dezembro', number: '12' },
    ])
    const [listaAno, setListaAno] = useState([])
    const [dia, setDia] = useState('')
    const [mes, setMes] = useState('')
    const [ano, setAno] = useState('')

    const [dataAtual] = useState(moment().format('YYYYMMDD'))
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [numeroCpf, setCpf] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')

    const [tipoUsuario, setTipoUsuario] = useState('')
    const [equipe, setEquipe] = useState('')

    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')
    const [dica, setDica] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('');
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')
    const [cadastro, setCadastro] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)



    useEffect(() => {
        const list = []
        let d = 1940
        for (let index = 0; index < d; index++) {
            const element = parseInt(d) + parseInt(list.length)
            if (element === 2030) {
                break;
            } else {
                list.push(element)
            }
        }
        setListaAno(list)
    }, [])

    function cadastraAvatar(id) {
        Swal.close();
        mensagemLoading('Salvando imagem do seu avatar')
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('usuarios/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    setCadastro(true)
                    mensagemSucesso('Você foi cadastrado com sucesso! Bem vindo ao Procompetidor')
                })
            });
        });
    }

    function cadastraUsuario() {
        setLoading(true)
        if (nome === '') {
            mensagemAlerta('Favor digitar seu primeiro nome')
            setLoading(false)
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar seu sobrenome')
            setLoading(false)
        }
        else if (!dia) {
            mensagemAlerta('Escolha o dia de Nascimento')
            setLoading(false)
        } else if (!mes) {
            mensagemAlerta('Favor escolha o Mês de Nascimento')
            setLoading(false)
        } else if (!ano) {
            mensagemAlerta('Favor escolha o Ano de Nascimento')
            setLoading(false)
        } else if (dia + '/' + mes + '/' + ano === moment().format('DD/MM/YYYY')) {
            mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
            setLoading(false)
        }
        // else if (moment(dataNascimento).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        //     mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
        //     setLoading(false)
        // } 
        else if (ddd === '' || ddd.length < 2) {
            mensagemAlerta('DDD vazio ou incompleto')
            setLoading(false)
        } else if (celular === '' || celular.length < 9) {
            mensagemAlerta('Celular vazio ou incompleto! Não esqueça de acrescentar o 9 no começo do número')
            setLoading(false)
        } else if (email === '') {
            mensagemAlerta('Favor digite o seu melhor email. Ele será importante para seu login e receber todas as notificações do evento')
            setLoading(false)
        } else if (sexo === '') {
            mensagemAlerta('Favor escolha seu sexo')
            setLoading(false)
        } else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
            setLoading(false)
        } else if (tipoUsuario === '') {
            mensagemAlerta('Selecione se você é Responsável Familiar ou Professor')
            setLoading(false)
        } else if (senha === '' || senha.length < 8) {
            mensagemAlerta('Senha vazia ou não atende os termos! Favor digite uma senha forte e no mínimo 8 dígitos')
            setLoading(false)
        } else if (confirmaSenha === '') {
            mensagemAlerta('Confirme sua senha')
            setLoading(false)
        } else if (senha !== confirmaSenha) {
            mensagemAlerta('As senhas não são iguais. Favor tente novamente')
            setLoading(false)
        } else if (dica === '') {
            mensagemAlerta('Digite a dica da senha para ser melhor lembrado')
            setLoading(false)
        } else {
            cadastraEmail()
        }

    }

    function cadastraEmail() {
        mensagemLoading('Preparando seu cadastro...')
        servicos.usuarios.where('cpf', '==', numeroCpf.replace(/[^a-z0-9]/gi, '')).get().then((snap) => {
            if (snap.size === 0) {
                servicos.auth.createUserWithEmailAndPassword(email, confirmaSenha).then((user) => {
                    servicos.usuarios.doc(user.user.uid).set({
                        id: user.user.uid,
                        nome: nome.toUpperCase(),
                        sobrenome: sobrenome.toUpperCase(),
                        cpf: numeroCpf.replace(/[^a-z0-9]/gi, ''),
                        ddd: ddd,
                        celular: celular,
                        email: email,
                        biografica: '',
                        // dataNascimento: dataNascimento,
                        dataNascimento: ano + '-' + mes + '-' + dia,
                        endereco: {
                            endereco: endereco.toUpperCase(),
                            bairro: bairro.toUpperCase(),
                            numero: numero.toUpperCase(),
                            complemento: complemento?complemento.toUpperCase():'',
                            uf: uf.toUpperCase(),
                            cidade: cidade.toUpperCase(),
                            cep: cep.replace(/[^a-z0-9]/gi, '')
                        },
                        dica: dica.toUpperCase(),
                        tipoUsuario: tipoUsuario,
                        equipe: equipe,
                        status: parseInt(1),
                        sexo: sexo,
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                        usuario: parseInt(3)
                    }).then(() => {
                        cadastraAvatar(user.user.uid)
                    }).catch(() => {
                        mensagemErro('Erro ao cadastrar o Usuário')
                    })
                }).catch((erro) => {
                    var errorCode = erro.code;
                    if (errorCode == "auth/invalid-email") {
                        mensagemAlerta('O formato do Email é inválido!')
                        setLoading(false)
                    } else if (errorCode == "auth/email-already-in-use") {
                        mensagemErro('O email já está em uso pelo Procompetidor')
                        setLoading(false)
                    } else if (errorCode == "auth/weak-password") {
                        mensagemErro('Escolha uma senha mais forte!')
                        setLoading(false)
                    }
                })
            } else {
                Swal.close();
                mensagemAlerta('CPF já cadastrado no sistema! Verifique novamente ou faça login')
            }
        })
    }



    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
        if (event.target.value.length === 11) {
            if (cpf.isValid(event.target.value) === false) {
                mensagemAlerta('CPF incorreto! Verifique novamente')
            } else {
                mensagemLoading('Aguarde, verificando o seu CPF...')
                servicos.usuarios.where('cpf', '==', event.target.value).get().then((snap) => {
                    if (snap.size === 0) {
                        Swal.close();
                        mensagemSucessoCpf('Seu CPF está disponível')
                    } else {
                        Swal.close();
                        mensagemAlerta('CPF já cadastrado no sistema! Verifique novamente ou faça login')
                    }
                })
            }
        }
    }
    const handleDia = (event) => {
        event.persist();
        setDia(event.target.value);
    }
    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleDataNascimmento = (event) => {
        event.persist();
        setDataNascimento(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleSexo = (event) => {
        event.persist();
        setSexo(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.replace(/[^a-z0-9]/gi, '').length === 8) {
            buscaCep(event.target.value);
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        if (event.target.value) {
            setTipoUsuario(event.target.value);
        }
    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }
    const handleDica = (event) => {
        event.persist();
        setDica(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep(c) {
        mensagemLoading('Aguarde procurando CEP...')
        buscaEndereco(c).then((doc) => {
            if (doc.erro === true) {
                setLoadingCep(false)
                mensagemErro('Não achamos seu CEP! Verifique novamente ou digite manualmente')
            } else {
                Swal.close();
                setEndereco(doc.logradouro)
                setBairro(doc.bairro)
                setCidade(doc.localidade)
                setUf(doc.uf)
                setLoadingCep(false)
            }
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/perfilResponsavel'
        })
    }
    function mensagemSucessoCpf(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Novo cadastro</h1>
                            </div>
                            <div class="headline__description">
                                <span>Cadastre-se como Responsável</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados Pessoais</h3>
                                                <p><b>Cadastre suas credenciais para que possa cadastrar seus alunos. </b></p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="package-style-1 pnchr-package clearfix vc_col-sm-4">
                                                <div >
                                                    {upload ?
                                                        <img src={upload} alt="" title="image-here-1" />
                                                        :
                                                        <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" alt="" title="image-here-1" />
                                                    }
                                                </div>
                                                <input onChange={handleAvatar} accept=".png, .jpg, .jpeg" type="file" name="name" placeholder="Escolha a imagem " />
                                            </div>
                                            <div role="form" class="wpcf7 row " id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNome}
                                                            value={nome.toUpperCase()}
                                                            placeholder="Nome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sobrenome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleSobrenome}
                                                            value={sobrenome.toUpperCase()}
                                                            placeholder="Sobrenome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CPF</h6>
                                                        <input
                                                            max={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCpf}
                                                            value={numeroCpf}
                                                            placeholder="CPF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Dia</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleDia} >
                                                            <option value="">Dia do Nascimento </option>
                                                            {listaDia.map((dia) =>
                                                                <option value={dia.dia}>{dia.dia}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Mês de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleMes} >
                                                            <option value="">Dia do Nascimento </option>
                                                            {listaMes.map((mes) =>
                                                                <option value={mes.number}>{mes.mes.toUpperCase()}</option>
                                                            )}
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Ano de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleAno} >
                                                            <option value="">Dia do Nascimento </option>
                                                            {listaAno.map((ano) =>
                                                                <option value={ano}>{ano}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                {/* <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Data Nascimento</h6>
                                                        <input
                                                            type="date" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDataNascimmento}
                                                            value={dataNascimento} />
                                                    </span>
                                                </p> */}
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>DDD</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDDD}
                                                            value={ddd}
                                                            maxLength={2}
                                                            placeholder="DDD *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Celular</h6>
                                                        <input
                                                            maxLength={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCelular}
                                                            value={celular}
                                                            placeholder="9 + Celular *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sexo</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleSexo} >
                                                            <option value="">Escolha o Sexo </option>
                                                            <option value="masculino" >MASCULINO </option>
                                                            <option value="feminino" >FEMININO </option>
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Email</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEmail}
                                                            value={email}
                                                            placeholder="Email *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <p>Guarde bem o seu email, ele será importante para receber as notificações e alterar sua senha</p>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Endereço</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CEP</h6>
                                                        <input
                                                            max={8}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCep}
                                                            value={cep}
                                                            placeholder="CEP *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Endereço</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEndereco}
                                                            value={endereco.toUpperCase()}
                                                            placeholder="Endereço *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Bairro</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleBairro}
                                                            value={bairro.toUpperCase()}
                                                            placeholder="Bairro *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Cidade</h6>
                                                        <input
                                                            disabled
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCidade}
                                                            value={cidade.toUpperCase()}
                                                            placeholder="Cidade *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Estado</h6>
                                                        <input
                                                            disabled
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleUf}
                                                            value={uf.toUpperCase()}
                                                            placeholder="UF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Complemento</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleComplemento}
                                                            value={complemento.toUpperCase()}
                                                            placeholder="Complemento *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>N°</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNumero}
                                                            value={numero}
                                                            placeholder="N° *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de acesso</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                    <div class="screen-reader-response"></div>
                                                    <p class="contact-form-name vc_col-sm-2">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Tipo</h6>
                                                            <select name="select" id="select" class="form-control" onChange={handleTipo} >
                                                                <option value="">Escolha </option>
                                                                <option value="professor" >Professor </option>
                                                                <option value="familia" >Família </option>
                                                            </select>
                                                        </span>
                                                    </p>
                                                </div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleSenha}
                                                            value={senha}
                                                            placeholder="Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Confirma Senha</h6>
                                                        <input
                                                            type="password" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            security="true"
                                                            onChange={handleConfirmaSenha}
                                                            value={confirmaSenha}
                                                            placeholder="Confirma Senha" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Uma dica para lembrar de sua senha</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDica}
                                                            value={dica.toUpperCase()}
                                                            placeholder="Dica de senha *" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                    <input type="submit" onClick={() => cadastraUsuario()} value="Cadastrar" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
