import axios from 'axios'
const baseUrl = "https://api.mercadopago.com"
// const baseUrlProcompetidor = "http://localhost:5000/procompetidor-7867b/us-central1/app"
const baseUrlProcompetidor = "https://us-central1-procompetidor-7867b.cloudfunctions.net/app"

// const token = "TEST-1008893631732682-011620-0129e8f40ad145284919b3393e8a04cb-554737429"
// const publicKey = "TEST-aeb30924-3ee6-4601-8de0-8933dbf655e5"
const token = "APP_USR-1008893631732682-011620-32290672d87fd7e05454a419f583eafa-554737429"
// const token = "TEST-1008893631732682-011620-0129e8f40ad145284919b3393e8a04cb-554737429"
// const publicKey = "TEST-aeb30924-3ee6-4601-8de0-8933dbf655e5"
const tokenProcompetidor = "APP_USR-8034385883906385-072611-f665f4a514e0538cacbde0cb2657c35e-1153629660"



export const geraPreferencia = (dados, chave,device_id) => {
    if (chave === 0) {
        return (async () => {
            try {
                const URL = baseUrl + "/checkout/preferences";
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token)
                headers.append('X-meli-session-id', device_id)
                headers.append('x-integrator-id', "dev_113a02781c5a11eeb2d71a7506ef66b5")
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    } else {
        return (async () => {
            try {
                const URL = baseUrl + "/checkout/preferences";
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + tokenProcompetidor)
                headers.append('X-meli-session-id', device_id)
                headers.append('x-integrator-id', "dev_113a02781c5a11eeb2d71a7506ef66b5")
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    }

}

export const getPagamento = (id, chave) => {
    return (async () => {
        try {
            const URL = baseUrlProcompetidor + "/getPedido?id=" + id+"&chave="+chave;
            var headers = new Headers();
            headers.append("Content-Type", "application/json")
            const response = await fetch(URL,
                {
                    method: 'GET',
                    headers: headers,
                }
            )
            const data = await response.json()
            return data;
        } catch (erro){
            return erro
        }
    })();

}

export const getPagamentos = (id, chave) => {
    return (async () => {
        try {
            const URL = baseUrlProcompetidor + "/getPedidos?id=" + id+"&chave="+chave;
            var headers = new Headers();
            headers.append("Content-Type", "application/json")
            const response = await fetch(URL,
                {
                    method: 'GET',
                    headers: headers,
                }
            )
            const data = await response.json()
            return data;
        } catch (err) {
            return err
        }
    })();

}

export const criaPagamento = (dados, chave) => {
    if (chave === 0) {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    } else {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + tokenProcompetidor)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "post",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                console.log(data)
                return data;
            } catch {
                return 2
            }
        })();
    }

}
export const atualizaPagamento = (id, dados, chave) => {
    if (chave === 0) {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments/${id}`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "put",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                return data;
            } catch {
                return 2
            }
        })();
    } else {
        return (async () => {
            try {
                const URL = baseUrl + `/v1/payments/${id}`;
                var headers = new Headers();
                headers.append('Authorization', 'Bearer ' + tokenProcompetidor)
                headers.append("Content-Type", "application/json")
                const response = await fetch(URL,
                    {
                        headers: headers,
                        method: "put",
                        body: JSON.stringify(dados)
                    }
                )
                const data = await response.json()
                return data;
            } catch {
                return 2
            }
        })();
    }

}



