import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet";
const mercadopago = require("mercadopago");
mercadopago.configure({
    access_token: "TEST-1008893631732682-011620-0129e8f40ad145284919b3393e8a04cb-554737429",
    sandbox: true,

})

export default function Evento() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const [id] = useState(window.location.pathname.replace('/checkout/', ''))
    const [inscricao, setIncricao] = useState({})
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [url, setUrl] = useState('')

    useEffect(() => {
        // mercadopago.card_token({

        // })
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                mensagemLoading('Carregando dados do pagamento..')
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    servicos.inscricoes
                        .where('evento.id', '==', id)
                        .where('usuario.id', '==', user.uid)
                        .get().then((snap) => {
                            const data = snap.docs.map((d) => d.data())[0]
                            setIncricao(data)
                            servicos.eventos.doc(id).get().then((d) => {
                                setEvento(d.data())
                                Swal.close();
                            })
                        })
                })
            }
        })
    }, [])

    function sucesso() {
        window.location = window.location.href.replace('/pagamento/' + id, '/sucesso/' + inscricao.id)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Formas </h1>
                            <h1>de Pagamento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Para realizar sua inscrição, escolha as formas de pagamentos.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class=" vc_col-sm-4">
                                            <div class="wpb_single_image wpb_content_element vc_align_left">
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={evento.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome} </h3>
                                                        <div class="package-style-1__text">
                                                            {evento.inicioEvento === evento.fimEvento ?
                                                                <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                                :
                                                                <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                            }
                                                            <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                        </div>
                                                        <div class="package-style-1__price clearfix">
                                                            <div class="package-style-1__price-amount" style={{ color: "#121212" }}><span
                                                                class="package-style-1__price-currency">R$</span><span
                                                                    class="package-style-1__price-value extra-field-1-target"
                                                                    data-extra-field-1-def="20">{inscricao.valorTotal}</span></div>

                                                        </div>
                                                    </div>
                                                </figure>
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={usuario.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                        <div class="package-style-1__text">
                                                            <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                            <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                        </div>
                                                    </div>
                                                </figure>

                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div role="form" class="row " >
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                                    <h3>Categorias</h3>
                                                                    {inscricao.categoria ?
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Categoria</th>
                                                                                    <th class="schedule-table__day">Peso</th>
                                                                                    <th class="schedule-table__day">Faixa</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Absoluto</th>
                                                                                    <th class="schedule-table__day">Idade</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Extrato</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    <table style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}>Valor da Categoria</td>
                                                                                <td>R$ {inscricao.pagamento ? inscricao.valorCategoria : null}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                                <td>R$ {inscricao.pagamento ? inscricao.valorAbsoluto : null}</td>
                                                                            </tr>
                                                                            {inscricao.voucher && inscricao.voucher.id ?
                                                                                <>
                                                                                    <tr>
                                                                                        <td style={{ height: 50 }}><h6>Valor Categoria</h6></td>
                                                                                        <td><h6>R$ {parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)}</h6></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ height: 50 }}><h6 >Voucher de {inscricao.voucher.tipo} aplicado</h6></td>
                                                                                        {inscricao.voucher.tipo === "desconto" ?
                                                                                            <td><h6>{inscricao.voucher.porcentagem} %</h6></td>
                                                                                            : inscricao.voucher.tipo === "valor" ?
                                                                                                <td><h6>-R$ {inscricao.voucher.valor}</h6></td>
                                                                                                :
                                                                                                <td><h6>GRÁTIS</h6></td>
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ height: 50 }}><h6 style={{ color: "green" }}>Valor Total com desconto</h6></td>
                                                                                        {inscricao.voucher.tipo === "desconto" ?
                                                                                            <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) - ((parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) * parseFloat(inscricao.voucher.porcentagem) / 100)}</h6></td>
                                                                                            : inscricao.voucher.tipo === "valor" ?
                                                                                                <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) - parseFloat(inscricao.voucher.valor)}</h6></td>
                                                                                                :
                                                                                                <td><h6 style={{ color: "green" }}>INSCRIÇÃO GRATUITA</h6></td>
                                                                                        }
                                                                                    </tr>
                                                                                </>
                                                                                :
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>Valor Total</h6></td>
                                                                                    <td><h6>R$ {inscricao.valorTotal ? inscricao.valorTotal : null}</h6></td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div>
                                                                    <h1>Importante</h1>
                                                                    <h3>Pagamentos via Boleto</h3>
                                                                    <p>Após gerar o boleto de pagamento , clique no botão de <b>"Voltar para o site"</b> ou aguarde<br /> 5 segundos para ser redirecionado a nossa<br /> tela de configuração na tela do<br /> <b>MERCADO PAGO</b> para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Verificando Pagamento"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                    <h3>Pagamentos via PIX</h3>
                                                                    <p>Após gerar o código PIX de pagamento ,<br /> clique no botão de <b>"Voltar para o site"</b> ser redirecionado a nossa tela de configuração na tela do <b>MERCADO PAGO</b> para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Verificando Pagamento"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                    <h3>Pagamentos via Cartão</h3>
                                                                    <p>Após confirmar o pagamento ,<br /> você ser redirecionado após 5 segundos a nossa tela de configuração para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Finalizando Inscrição"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                </div>
                                                                {url ?
                                                                    <a href={url} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagar</a>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            <Helmet>
                                                                <script src="./teste.js" type="text/javascript" />
                                                            </Helmet>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div >
    );
}
