import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
    card: {
        width: '49%',
        height: 120,
        display: 'flex',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: 10
    },
    avatar: {
        width: 120,
        height: 120
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function Checagem() {
    const classes = useStyles();
    const [id] = useState(window.location.pathname.replace('/chaves/', ''))
    const [servicos] = useState({
        usuarios: firebase.firestore().collection('usuarios'),
        checagem: firebase.firestore().collection('eventos').doc(id).collection('checagem'),
        eventos: firebase.firestore().collection('eventos').doc(id),
        inscricoes: firebase.firestore().collection('inscricoes').where('status', '==', 2),
    })
    const [evento, setEvento] = useState({})
    const [resultados, setResultados] = useState([])
    const [checagem, setChecagem] = useState([])
    const [inscricoes, setInscricoes] = useState([])
    const [tipoCategoria, setTipoCategoria] = useState('categoria')
    const [link, setLink] = useState('')
    const [show, setShow] = useState(false)

    const [categorias, setCategorias] = useState([])
    const [categoria, setCategoria] = useState('')

    const [absolutos, setAbsolutos] = useState([])
    const [absoluto, setAbsoluto] = useState('')

    const [faixas, setFaixas] = useState([])
    const [faixa, setFaixa] = useState('')

    const [pesos, setPesos] = useState([])
    const [peso, setPeso] = useState('')

    const [sexo, setSexo] = useState('')

    useEffect(() => {
        servicos.eventos.get().then((snapE) => {
            setEvento(snapE.data())
        })
        servicos.eventos.collection('absolutos').get().then((snap) => {
            const data = snap.docs.map(doc => doc.data())
            setAbsolutos(data)
        })
    }, [])

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function buscaChaveCategoria() {
        servicos.inscricoes
            .where('categoria.categoria.id', '==', categoria)
            .where('categoria.faixa.id', '==', faixa)
            .where('categoria.peso.id', '==', peso)
            .where('categoria.categoria.sexo', '==', sexo)
            .where('status', '==', 2)
            .get().then((snap) => {
                const data = snap.docs.map(doc => doc.data())
                console.log(data)
            })
    }

    const handleFiltro = (event) => {
        event.persist();
        if (event.target.value !== '') {
            setTipoCategoria(event.target.value)
            mensagemLoading('Buscando categoria...')
            servicos.checagem.where('' + event.target.value, '==', true).onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                Swal.close();
                setResultados(data)
            })
        }
    }

    const handleCategoria = (event) => {
        event.persist();
        if (event.target.value !== '') {
            mensagemLoading('Buscando Faixas e Pesos')
            setCategoria(event.target.value)
            servicos.eventos.collection('categorias')
                .doc(event.target.value)
                .collection('faixas')
                .where('sexo', '==', sexo)
                .get().then((snap) => {
                    Swal.close();
                    const data = snap.docs.map(doc => doc.data())
                    setFaixas(data)
                })
            servicos.eventos.collection('categorias')
                .doc(event.target.value)
                .collection('pesos')
                .where('sexo', '==', sexo)
                .get().then((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setPesos(data)
                })
        }
    }
    const handleFaixa = (event) => {
        event.persist();
        if (event.target.value !== '') {
            setFaixa(event.target.value)
        }
    }
    const handlePeso = (event) => {
        event.persist();
        if (event.target.value !== '') {
            setPeso(event.target.value)
        }
    }
    const handleAbsoluto = (event) => {
        event.persist();
        if (event.target.value !== '') {
            setAbsoluto(event.target.value)
        }
    }
    const handleSexo = (event) => {
        event.persist();
        if (event.target.value !== '') {
            mensagemLoading('Buscando Categorias')
            setSexo(event.target.value)
            servicos.eventos.collection('categorias')
                .where('sexo', '==', event.target.value).get().then((snap) => {
                    Swal.close();
                    const data = snap.docs.map(doc => doc.data())
                    setCategorias(data)
                })
        }
    }

    function detalheChave(inscricao) {
        if (inscricao === 'categoria') {
            setLink('https://admin-procompetidor.web.app/pdfall?evento=' + id + '&idCategoria=' + categoria + '&idFaixa=' + faixa + '&idPeso=' + peso + '&sexo=' + sexo + '&categoria=true')
            // window.open(window.location.host+'/pdf?evento=' + evento.id + '&idCategoria=' + inscricao.id + '&categoria=true', '_blank');
            // window.location.href = 'pdf?evento=' + evento.id + '&idCategoria=' + inscricao.id + '&categoria=true'
        } else {
            setLink('https://admin-procompetidor.web.app/pdfallAbsoluto?evento=' + id + '&idAbsoluto=' + absoluto + '&categoria=false')
            // window.open(window.location.host+'/pdf?evento=' + evento.id + '&idCategoria=' + inscricao.id + '&categoria=false', '_blank');
            // window.location.href = 'pdf?evento=' + evento.id + '&idCategoria=' + inscricao.id + '&categoria=false'
        }
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Chaves {evento.nome}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Lista de confrontos </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <main >
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 style={{ marginLeft: 16 }}>Selecione a configuração para buscar a chave  </h3>
                                                <p style={{ marginLeft: 16 }}>Selecione o sexo para inicar a configuração para buscar a chave da categoria configurada</p>
                                                <p style={{ marginLeft: 16, color: "red" }}>* Para melhor visualização da chave, o sistema fará o download do PDF. Veja em sua pasta de Download em seu celular<i class="fa fa-repeat"></i></p>
                                                <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" >
                                                        <select name="select" id="select" class="form-control" onChange={handleSexo}>
                                                            <option value="">Escolha o Sexo </option>
                                                            <option value="masculino">Masculino </option>
                                                            <option value="feminino">Feminino </option>
                                                        </select>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" >
                                                        <select name="select" id="select" class="form-control" onChange={handleCategoria}>
                                                            <option value="" >Escolha a categoria </option>
                                                            {categorias.sort((a, b) => {
                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                            }).map((c) =>
                                                                <option value={c.id} >{c.categoria} - {c.tipoIdade === 0 ? "Até " + c.idade + " anos" : c.tipoIdade === 1 ? "De " + c.minIdade + " até " + c.maxIdade + " anos" : "Acima de " + c.idade}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" >
                                                        <select name="select" id="select" class="form-control" onChange={handleFaixa}>
                                                            <option value="">Escolha o Faixa </option>
                                                            {faixas.sort((a, b) => {
                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                            }).map((f) =>
                                                                <option value={f.id} >{f.faixa}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" >
                                                        <select name="select" id="select" class="form-control" onChange={handlePeso}>
                                                            <option value="">Escolha o Peso </option>
                                                            {pesos.sort((a, b) => {
                                                                return a.peso - b.peso
                                                            }).map((c) =>
                                                                <option value={c.id} >{c.nome} - {c.tipoPeso === 0 ? "Até " + c.peso + " Kg" : "Acima " + c.peso + " Kg"}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" onClick={() => detalheChave("categoria")}>
                                                        <a href={link} target="_blank" class="btn_defoult btn_fill "> Buscar Chaves</a>
                                                    </div>
                                                </div>
                                                <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-6" >
                                                        <select name="select" id="select" class="form-control" onChange={handleAbsoluto}>
                                                            <option value="" >Escolha o Absoluto </option>
                                                            {absolutos.sort((a, b) => {
                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                            }).map((c) =>
                                                                <option value={c.id} >{c.absoluto} {c.sexo}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" onClick={() => detalheChave("absoluto")}>
                                                        <a href={link} target="_blank" class="btn_defoult btn_fill vc_col-lg-12"> Buscar Chaves Absoluto</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
