import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import 'suneditor/dist/css/suneditor.min.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import MediaQuery from 'react-responsive'

export default function Evento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        eventos: firebase.firestore().collection("eventos"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        mail: firebase.firestore().collection('mail'),
    })
    const [inscricoes, setInscricoes] = useState([])
    const [validaInicioInscricao, setValidaInicioInscricao] = useState(0)
    const [validaFimInscricao, setValidaFimInscricao] = useState(0)

    const [id] = useState(window.location.pathname.replace('/listaCompetidores/', ''))
    const [usuario, setUsuario] = useState({})
    const [evento, setEvento] = useState({})

    useEffect(() => {
        async function carregaDados() {
            servicos.auth.onAuthStateChanged((user) => {
                let idUser = JSON.parse(localStorage.getItem('usuarioId'))
                if (user) {
                    mensagemLoading('Aguarde.. Verificando Inscrições')
                    servicos.eventos.doc(id).get().then((doc) => {
                        setEvento(doc.data())
                        var dateInicioInscricao = moment(doc.data().inicioInscricao).format('DD/MM/YYYY')
                        var dataInicioInscricao = moment(dateInicioInscricao, 'DD/MM/YYYY');
                        var dateFimInscricao = moment(doc.data().fimInscricao).format('DD/MM/YYYY')
                        var dataFimInscricao = moment(dateFimInscricao, 'DD/MM/YYYY');

                        var date1 = moment().format('DD/MM/YYYY')
                        var data1 = moment(date1, 'DD/MM/YYYY');

                        setValidaInicioInscricao(dataInicioInscricao.diff(data1, 'days'))
                        setValidaFimInscricao(dataFimInscricao.diff(data1, 'days'))
                    })
                    servicos.usuarios.doc(user.uid).get().then((doc) => {
                        setUsuario(doc.data())
                    })
                    servicos.inscricoes
                        .where('evento.id', '==', id)
                        .where('responsavel.id', '==', user.uid)
                        .onSnapshot((snap) => {
                            const data = snap.docs.map((doc) => doc.data());

                            setInscricoes(data.sort((a,b) => {

                                if (a.usuario.nome < b.usuario.nome) { return -1 }
                                if (a.usuario.nome > b.usuario.nome) { return 1 }

                                if (a.categoria.categoria.categoria < b.categoria.categoria.categoria) { return -1 }
                                if (a.categoria.categoria.categoria > b.categoria.categoria.categoria) { return 1 }

                                if (a.categoria.peso.id > b.categoria.peso.peso) { return 1 }
                                if (a.categoria.peso.id < b.categoria.peso.peso) { return -1 }

                                return 0;
                            }))
                            Swal.close();
                        })
                } else if (idUser) {
                    mensagemLoading('Aguarde.. Verificando Inscrições')
                    servicos.eventos.doc(id).get().then((doc) => {
                        setEvento(doc.data())
                        var dateInicioInscricao = moment(doc.data().inicioInscricao).format('DD/MM/YYYY')
                        var dataInicioInscricao = moment(dateInicioInscricao, 'DD/MM/YYYY');
                        var dateFimInscricao = moment(doc.data().fimInscricao).format('DD/MM/YYYY')
                        var dataFimInscricao = moment(dateFimInscricao, 'DD/MM/YYYY');

                        var date1 = moment().format('DD/MM/YYYY')
                        var data1 = moment(date1, 'DD/MM/YYYY');

                        setValidaInicioInscricao(dataInicioInscricao.diff(data1, 'days'))
                        setValidaFimInscricao(dataFimInscricao.diff(data1, 'days'))
                    })
                    servicos.usuarios.doc(idUser).get().then((doc) => {
                        setUsuario(doc.data())
                    })
                    servicos.inscricoes
                        .where('evento.id', '==', id)
                        .where('responsavel.id', '==', idUser)
                        .onSnapshot((snap) => {
                            const data = snap.docs.map((doc) => doc.data());
                            setInscricoes(data.sort((a,b) => {

                                if (a.usuario.nome < b.usuario.nome) { return -1 }
                                if (a.usuario.nome > b.usuario.nome) { return 1 }

                                if (a.categoria
                                    .idade.categoria < b.categoria.idade.categoria) { return -1 }
                                if (a.categoria.idade.categoria > b.categoria.idade.categoria) { return 1 }

                                if (a.categoria.peso.id > b.categoria.peso.peso) { return -1 }
                                if (a.categoria.peso.id < b.categoria.peso.peso) { return 1 }

                                return 0;
                            }))
                            Swal.close();
                        })
                }
            })
        }
        carregaDados();

    }, [])

    function novoUsuario() {
        window.location = window.location.href.replace('/listaCompetidores/', '/inscricaoAlunos/')
    }
    function editaInscricao(inc) {
        window.location = window.location.href.replace('/listaCompetidores/' + evento.id, '/editaInscricaoAluno/' + inc.id)
    }
    function cancelaInscricao(inc) {
        if (inc.pagamento && inc.pagamento.status === "approved") {
            mensagemAlerta('LEIA SOBRE POLITICA DE REEMBOLSO NA PAGINA DO EVENTO. EGUIDA ENTRE EM CONTATO COM O FALE CONOSCO')
        } else {
            Swal.fire({
                title: 'Aviso',
                text: 'Deseja realizar o cancelamento da inscrição? Ainda não indentifcamos o pagamento , caso tenha realizado, aguarde email ou entre em contato com a administração do Procompetidor ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    mensagemLoading('Aguarde... Cancelando inscrição')
                    servicos.inscricoes.doc(inc.id).delete().then(() => {
                        mensagemSucesso('Inscrição cancelada com sucesso! Caso queira se inscrever o atleta novamente, prossiga com os procedimentos do Procompetidor')
                    })
                }
            });
        }
    }

    function inscricao(evn) {
        if (evn.id) {
            window.location = window.location.href.replace('/listaCompetidores/' + evento.id, '/minhaInscricao/' + evn.id)
            // if (evn.pagamento.pagamento === null) {
            //     window.location = window.location.href.replace('/listaCompetidores/' + evento.id, '/pagamentoAluno/' + evn.id)
            // } else {
            //     mensagemLoading('Carregando dados...')
            //     if (evn.pagamento.status === 'approved' || evn.status === 2) {
            //         window.location = window.location.href.replace('/listaCompetidores/' + id, '/sucesso?external_reference=' + evn.id)
            //     } else if (evn.pagamento.status === 'pending' || evn.pagamento.status === 'in_process') {
            //         getPagamento(evn.pagamento.pagamento).then((res) => {
            //             if (res.status === 'pending') {
            //                 if (res.payment_type_id === 'ticket') {
            //                     var data = moment(res.date_of_expiration).diff(moment(), 'days')
            //                     if (data <= 0) {
            //                         Swal.fire({
            //                             title: 'Aviso',
            //                             text: 'Seu boleto venceu! Será gerado um novo boleto para o inscrito',
            //                             icon: 'warning',
            //                             showCancelButton: true,
            //                             confirmButtonColor: '#3085d6',
            //                             cancelButtonColor: '#d33',
            //                             confirmButtonText: 'Sim',
            //                             cancelButtonText: 'Não'
            //                         }).then((result) => {
            //                             if (result.value) {
            //                                 mensagemLoading('Aguarde... Gerando Boleto..')
            //                                 // servicos.inscricoes.doc(evn.id).delete().then(() => {
            //                                 //     window.location = window.location.href.replace('/evento/', '/inscricao/')
            //                                 // })
            //                                 servicos.eventos.doc(evento.id).collection('lotes')
            //                                     .where('tipoCategoria', '==', 'categoria')
            //                                     .where('sexo', '==', evn.usuario.sexo)
            //                                     .where('categoria.id', '==', evn.categoria.categoria.id)
            //                                     .onSnapshot((snap) => {
            //                                         if (snap.size > 0) {
            //                                             const data = snap.docs.map(doc => doc.data())
            //                                             const listaValor = [];
            //                                             let valAbs = 0
            //                                             let valCat = 0
            //                                             var lote = false
            //                                             data.forEach((doc) => {
            //                                                 var dt1 = new Date(moment().format('YYYY-MM-DD'));
            //                                                 var dt2 = new Date(doc.vencimento);
            //                                                 var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
            //                                                 console.log(total)
            //                                                 if (total > -1) {
            //                                                     listaValor.push(doc.valor)
            //                                                 }
            //                                             })
            //                                             if (listaValor.length > 0) {
            //                                                 valCat = Math.min(...listaValor)
            //                                                 const dado = {
            //                                                     description: evn.categoria.categoria.categoria.toUpperCase(),
            //                                                     payment_method_id: 'bolbradesco',
            //                                                     payer: {
            //                                                         email: usuario.email,
            //                                                         first_name: usuario.nome,
            //                                                         last_name: usuario.sobrenome,
            //                                                         identification: {
            //                                                             type: 'CPF',
            //                                                             number: usuario.cpf
            //                                                         },
            //                                                         address: {
            //                                                             zip_code: usuario.endereco.cep,
            //                                                             street_name: usuario.endereco.endereco,
            //                                                             street_number: usuario.endereco.numero ? usuario.endereco.numero : 0,
            //                                                             neighborhood: usuario.endereco.bairro,
            //                                                             city: usuario.endereco.cidade,
            //                                                             federal_unit: usuario.endereco.uf
            //                                                         }
            //                                                     },
            //                                                     description: "PRO COMMPETIDOR",
            //                                                     external_reference: evn.id,
            //                                                     transaction_amount: parseFloat(evn.valorTotal)
            //                                                 }
            //                                                 criaPagamento(dado).then((res) => {
            //                                                     servicos.inscricoes.doc(evn.id).update({
            //                                                         pagamento: {
            //                                                             status: res.status,
            //                                                             barcode: res.barcode.content,
            //                                                             pagamento: res.id,
            //                                                             tipoPagamento: res.payment_type_id,
            //                                                             vencimento: res.date_of_expiration
            //                                                         },
            //                                                     }).then(() => {
            //                                                         mensagemSucessoBoleto("Segunda Via de boleto gerado com sucesso! Clique em Minha Inscrição para pegar o código de\n barras ou o PDF. \n Vencimento para " + moment(res.date_of_expiration).format("DD/MM/YYYY"))
            //                                                     })
            //                                                 })
            //                                             } else {
            //                                                 alert("Lote vencido! Entre em contato com a administração")
            //                                             }
            //                                         } else {
            //                                             servicos.eventos.doc(evento.id).collection('lotes')
            //                                                 .where('tipoCategoria', '==', 'categoria')
            //                                                 .where('sexo', '==', evn.usuario.sexo)
            //                                                 .where('geral', '==', true)
            //                                                 .onSnapshot((snap) => {
            //                                                     if (snap.size > 0) {
            //                                                         let valAbs = 0
            //                                                         let valCat = 0
            //                                                         var lote = false
            //                                                         const data = snap.docs.map(doc => doc.data())
            //                                                         const listaValor = [];
            //                                                         data.forEach((doc) => {
            //                                                             var dt1 = new Date(moment().format('YYYY-MM-DD'));
            //                                                             var dt2 = new Date(doc.vencimento);
            //                                                             var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
            //                                                             // console.log(total)
            //                                                             if (total > -1) {
            //                                                                 listaValor.push(doc.valor)
            //                                                             }
            //                                                         })
            //                                                         if (listaValor.length > 0) {
            //                                                             valCat = Math.min(...listaValor)
            //                                                             const dado = {
            //                                                                 description: evn.categoria.categoria.categoria.toUpperCase(),
            //                                                                 payment_method_id: 'bolbradesco',
            //                                                                 payer: {
            //                                                                     email: usuario.email,
            //                                                                     first_name: usuario.nome,
            //                                                                     last_name: usuario.sobrenome,
            //                                                                     identification: {
            //                                                                         type: 'CPF',
            //                                                                         number: usuario.cpf
            //                                                                     },
            //                                                                     address: {
            //                                                                         zip_code: usuario.endereco.cep,
            //                                                                         street_name: usuario.endereco.endereco,
            //                                                                         street_number: usuario.endereco.numero ? usuario.endereco.numero : 0,
            //                                                                         neighborhood: usuario.endereco.bairro,
            //                                                                         city: usuario.endereco.cidade,
            //                                                                         federal_unit: usuario.endereco.uf
            //                                                                     }
            //                                                                 },
            //                                                                 description: "PRO COMMPETIDOR",
            //                                                                 external_reference: evn.id,
            //                                                                 transaction_amount: parseFloat(evn.valorTotal)
            //                                                             }
            //                                                             criaPagamento(dado).then((res) => {
            //                                                                 servicos.inscricoes.doc(evn.id).update({
            //                                                                     pagamento: {
            //                                                                         status: res.status,
            //                                                                         barcode: res.barcode.content,
            //                                                                         pagamento: res.id,
            //                                                                         tipoPagamento: res.payment_type_id,
            //                                                                         vencimento: res.date_of_expiration
            //                                                                     },
            //                                                                 }).then(() => {
            //                                                                     mensagemSucessoBoleto("Segunda Via de boleto gerado com sucesso! Clique em Minha Inscrição para pegar o código de\n barras ou o PDF. \n Vencimento para " + moment(res.date_of_expiration).format("DD/MM/YYYY"))
            //                                                                 })
            //                                                             })
            //                                                         } else {
            //                                                             alert("Lote vencido! Entre em contato com a administração")
            //                                                         }
            //                                                     }
            //                                                 })
            //                                         }
            //                                     })
            //                             }
            //                         });
            //                     } else {
            //                         servicos.inscricoes.doc(evn.id).update({
            //                             pagamento: {
            //                                 status: res.status,
            //                                 barcode: res.barcode.content,
            //                                 pagamento: evn.pagamento.pagamento,
            //                                 tipoPagamento: res.payment_type_id,
            //                                 vencimento: res.date_of_expiration
            //                             },
            //                         }).then(() => {
            //                             Swal.close();
            //                             window.location = window.location.href.replace('/listaCompetidores/' + id, '/atencao?external_reference=' + evn.id)
            //                         })
            //                     }
            //                 } else {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             pagamento: evn.pagamento.pagamento,
            //                             tipoPagamento: res.payment_type_id,
            //                         },
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/atencao?external_reference=' + evn.id)
            //                     })
            //                 }
            //             } else if (res.status === 'approved') {
            //                 if (res.payment_type_id === 'ticket') {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             barcode: res.barcode.content,
            //                             pagamento: evn.pagamento.pagamento,
            //                             tipoPagamento: res.payment_type_id,
            //                             vencimento: res.date_of_expiration
            //                         },
            //                         dataPagamento: moment().format('YYYY-MM-DD HH:mm'),
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/sucesso?external_reference=' + evn.id)
            //                     })
            //                 } else if (res.payment_type_id === 'bank_transfer') {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             barcode: res.barcode.content,
            //                             pagamento: evn.pagamento.pagamento,
            //                             tipoPagamento: res.payment_type_id,
            //                         },
            //                         dataPagamento: moment().format('YYYY-MM-DD HH:mm'),
            //                         status: parseInt(2)
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/sucesso?external_reference=' + evn.id)
            //                     })
            //                 } else {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             pagamento: evn.pagamento.pagamento,
            //                             tipoPagamento: res.payment_type_id,
            //                         },
            //                         dataPagamento: moment().format('YYYY-MM-DD HH:mm'),
            //                         status: parseInt(0)
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/sucesso?external_reference=' + evn.id)
            //                     })
            //                 }
            //             } else if (res.status === 'in_process') {
            //                 if (res.payment_type_id === 'ticket') {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             barcode: res.barcode.content,
            //                             pagamento: evn.pagamento.pagamento,
            //                             formaPagamento: res.payment_type_id,
            //                             vencimento: res.date_of_expiration
            //                         },
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/atencao?external_reference=' + evn.id)
            //                     })
            //                 } else if (res.payment_type_id === 'bank_transfer') {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             barcode: res.barcode.content,
            //                             pagamento: evn.pagamento.pagamento,
            //                             tipoPagamento: res.payment_type_id,
            //                         },
            //                         dataPagamento: moment().format('YYYY-MM-DD HH:mm'),
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/sucesso?external_reference=' + evn.id)
            //                     })
            //                 } else {
            //                     servicos.inscricoes.doc(evn.id).update({
            //                         pagamento: {
            //                             status: res.status,
            //                             pagamento: evn.pagamento.pagamento,
            //                             formaPagamento: res.payment_type_id,
            //                         },
            //                     }).then(() => {
            //                         Swal.close();
            //                         window.location = window.location.href.replace('/listaCompetidores/' + id, '/atencao?external_reference=' + evn.id)
            //                     })
            //                 }
            //             }
            //         })
            //     } else {
            //         window.location = window.location.href.replace('/listaCompetidores/' + evento.id, '/pagamentoAluno/' + evn.id)
            //     }
            // }
        } else {
            if (usuario.usuario === 3) {
                window.location = window.location.href.replace('/listaCompetidores/', '/inscricaoAlunos/')
            } else {
                window.location = window.location.href.replace('/listaCompetidores/', '/inscricao/')
            }

        }

    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemSucessoBoleto(msg) {
        Swal.fire('Sucesso', msg, 'success');
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Lista de alunos inscritos no evento, {evento.nome}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Visualize seu perfil e edite</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            {/* <div style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3">
                                <input type="submit" onClick={() => compartilhaPerfil()} value="Compartilhar meu Perfil" class="wpcf7-form-control wpcf7-pnchr_submit" />
                            </div> */}
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <div class="schedule-toggle-title">
                                                    <h3 class="title-section_with-sep" style={{ color: "#121212" }}>Seus competidores</h3>
                                                </div>
                                            </div>
{/* Verificação anterior: (validaInicioInscricao < 0 && validaFimInscricao < 0) */}
                                            {(((validaInicioInscricao > 0 || validaFimInscricao < 0 ) & (!evento.statusInscricao || evento.statusInscricao === 0)) || (evento.statusInscricao === 2 )) ?
                                                <div class="schedule-toggle-nav clearfix">
                                                    <div class="form-submit__btn-wrapp btn_defoult btn_fill " style={{ backgroundColor: "#A4A4A4" }}>
                                                        <input type="submit" value="Inscrição Encerrada" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                    </div>
                                                </div>
                                                :
                                                <div class="schedule-toggle-nav clearfix">
                                                    <div class="form-submit__btn-wrapp btn_defoult btn_fill " >
                                                        <input type="submit" onClick={() => novoUsuario()} value="Nova Inscrição" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                    </div>
                                                </div>
                                            }
                                            <div class="schedule-empty-space" style={{ height: 27 }}></div>
                                            <h5>OBSERVAÇÕES</h5>
                                            <p style={{ marginTop: 20, marginLeft: 20 }}>* Valores apresentados com as cores <b style={{ color: 'green' }}>VERDE</b>, significam que foram aplicados Vouchers</p>
                                            <p style={{ marginLeft: 20 }}>* Caso o(a) atleta não esteja inscrito em categoria absoluto, será apresentada a mensagem  <b >NÁO POSSUI ABSOLUTO</b>. Para atletas com a inscrição em absoluto, será apresentada a categoria inscrita.</p>
                                            <p style={{ marginLeft: 20 }}>* Status como  <b style={{ color: 'green' }}>INSCRITO</b>, signifca que o(a) atleta está inscrito, para status de <b style={{ color: 'orange' }}>PENDENTE</b>, significa que a inscrição do(a) atleta está pendente ou problema no pagamento. Para isso basta clicar no icone <i className="fa  fa-search" aria-hidden="true" ></i> para mais detalhes.</p>
                                            <div class="vc_col-sm-12" style={{ overflow: 'auto', marginTop: 20 }}>
                                                <MediaQuery minWidth={1224}>
                                                    <table width={'100%'} >
                                                        <tr bgcolor="#0B610B">
                                                            <th style={{ color: 'white' }}><b>Nome</b></th>
                                                            <th style={{ color: 'white' }}>Categoria Peso e Absoluto</th>
                                                            <th style={{ color: 'white' }}>Peso</th>
                                                            <th style={{ color: 'white' }}>Valor</th>
                                                            <th style={{ color: 'white' }}>Status</th>
                                                            <th style={{ color: 'white' }}>Detalhe</th>
                                                        </tr>
                                                        <tbody>
                                                            {inscricoes.map((competidor, index) =>
                                                                <tr key={index + 1} >
                                                                    <td>{competidor.usuario.nome} {competidor.usuario.sobrenome}</td>
                                                                    <td>
                                                                        {competidor.categoria.categoria.categoria} - {competidor.categoria.categoria.tipoIdade === 0 ? "ATÉ " + competidor.categoria.categoria.idade + " ANOS" : competidor.categoria.categoria.tipoIdade === 1 ? "DE " + competidor.categoria.categoria.minIdade + " ATÉ " + competidor.categoria.categoria.maxIdade + " ANOS" : "ACIMA DE " + competidor.categoria.categoria.idade} ( {competidor.categoria.faixa.faixa} )<br />
                                                                        {competidor.categoria.absoluto.id ?
                                                                            <p>Absoluto - {competidor.categoria.absoluto.absoluto}</p>
                                                                            :
                                                                            <p style={{ fontSize: 10 }}>Não possui Absoluto</p>
                                                                        }                                                                </td>
                                                                    <td>{competidor.categoria.peso.tipoPeso === 0 ? "ATÉ " + competidor.categoria.peso.peso + " KG" : "ACIMA " + competidor.categoria.peso.peso + " KG"}</td>
                                                                    
                                                                    {/* {competidor.voucher.id ?
                                                                        <>
                                                                            {competidor.voucher.tipo === "desconto" ?
                                                                                <td><h6 style={{ color: "green" }}>R$ {(parseFloat(competidor.valorCategoriaOriginal) + parseFloat(competidor.valorAbsolutoOriginal )) - ((parseFloat(competidor.valorCategoriaOriginal) + parseFloat(competidor.valorAbsolutoOriginal)) * parseFloat(competidor.voucher.porcentagem) / 100)}</h6></td>
                                                                                : competidor.voucher.tipo === "valor" ?
                                                                                    <td><h6 style={{ color: "green" }}>R$ {(parseFloat(competidor.valorCategoriaOriginal) + parseFloat(competidor.valorAbsolutoOriginal)) - parseFloat(competidor.voucher.valor)}</h6></td>
                                                                                    :
                                                                                    <td><h6 style={{ color: "green" }}>GRATUITA</h6></td>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <td><b>R$ {competidor.valorTotal}</b></td>
                                                                    } */}
                                                                    <td><b style={{color: (competidor.voucher.id || competidor.valorTotal === 0 || competidor?.descontoFiliacao?.descontoAplicado) ? 'green' : ''}}> {competidor.valorTotal ? competidor.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'GRATUITA'}</b></td>
                                                                    {competidor.pagamento.pagamento || competidor.status > 0 ?
                                                                        <>
                                                                            {competidor.pagamento.status === "approved" || competidor.status === 2 ?
                                                                                <td><p style={{ color: "green" }}><b>Inscrito</b></p></td>
                                                                                :
                                                                                <td><p style={{ color: "orange" }}><b>Pendente</b></p></td>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <td><p style={{ color: "orange" }}><b>Pendente</b></p></td>
                                                                    }
                                                                    <td>
                                                                        <a href="javascript:void(0)" onClick={() => inscricao(competidor)} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                            <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </MediaQuery>
                                            </div>
                                            <div class="vc_col-sm-12" style={{ overflow: 'auto', marginTop: 20 }}>
                                                <MediaQuery minWidth={300} maxWidth={1000}>
                                                    <Table>
                                                        <Thead>
                                                            <Tr>
                                                                <Th class="schedule-table__day">Nome</Th>
                                                                <Th class="schedule-table__day">Categoria</Th>
                                                                <Th class="schedule-table__day">Peso</Th>
                                                                <Th class="schedule-table__day">Absoluto</Th>
                                                                <Th class="schedule-table__day">Valor</Th>
                                                                <Th class="schedule-table__day">Status</Th>
                                                                <Th class="schedule-table__day">Detalhe</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {inscricoes.map((competidor) =>
                                                                <Tr>
                                                                    <Td><p>{competidor.usuario.nome} {competidor.usuario.sobrenome}</p></Td>
                                                                    <Td><p>{competidor.categoria.categoria.categoria} - {competidor.categoria.categoria.tipoIdade === 0 ? "ATÉ " + competidor.categoria.categoria.idade + " ANOS" : competidor.categoria.categoria.tipoIdade === 1 ? "DE " + competidor.categoria.categoria.minIdade + " ATÉ " + competidor.categoria.categoria.maxIdade + " ANOS" : "ACIMA DE " + competidor.categoria.categoria.idade} ( {competidor.categoria.faixa.faixa} )</p></Td>
                                                                    <Td><p>{competidor.categoria.peso.tipoPeso === 0 ? "ATÉ " + competidor.categoria.peso.peso + " KG" : "ACIMA " + competidor.categoria.peso.peso + " KG"}</p></Td>
                                                                    {competidor.categoria.absoluto.id ?
                                                                        <Td><p>{competidor.categoria.absoluto.absoluto.toUpperCase()}</p></Td>
                                                                        :
                                                                        <Td><p>NÃO</p></Td>
                                                                    }
                                                                    <Td>

<p style={{color: (competidor.voucher.id || competidor.valorTotal === 0 || competidor?.descontoFiliacao?.descontoAplicado) ? 'green' : ''}}> {competidor.valorTotal ? competidor.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'GRATUITA'}</p>

                                                                    </Td>
                                                                    {competidor.pagamento.pagamento || competidor.status > 0 ?
                                                                        <>
                                                                            {competidor.pagamento.status === "approved" || competidor.status === 2 ?
                                                                                <Td><p style={{ color: "green" }}>Inscrito</p></Td>
                                                                                : competidor.pagamento.tipoPagamento === "ticket" ?
                                                                                    <>
                                                                                        {moment(competidor.pagamento.vencimento).diff(moment(), 'days') <= 0 ?
                                                                                            <Td><p style={{ color: "red" }}>Boleto Vencido</p></Td>
                                                                                            :
                                                                                            competidor.pagamento.status === "pending" || competidor.pagamento.status === "in_process" ?
                                                                                                <Td><p style={{ color: "orange" }}>Aguard. Pagamento</p></Td>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    competidor.pagamento.status === "pending" || competidor.pagamento.status === "in_process" ?
                                                                                        <Td><p style={{ color: "orange" }}>Aguard. Pagamento</p></Td>
                                                                                        :
                                                                                        null
                                                                            }
                                                                        </>
                                                                        :
                                                                        <Td><p style={{ color: "orange" }}>Realizar Pagamento</p></Td>
                                                                    }
                                                                    <Td>
                                                                        <a href="javascript:void(0)" onClick={() => inscricao(competidor)} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                            <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                        </a>
                                                                    </Td>
                                                                </Tr>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                </MediaQuery>
                                            </div>

                                            {inscricoes.length === 0 ?
                                                <p style={{ textAlign: 'center' }} ><h6>Sem inscrições</h6></p>
                                                :
                                                null
                                            }

                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
