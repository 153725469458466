import {
    useState, useEffect, Profiler
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import ModalEquipes from '../componentes/ModalEquipes'

export default function Evento() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const [id] = useState(window.location.pathname.replace('/editaInscricao/', ''))
    const [idInscricao, setIdInscricao] = useState(window.location.pathname.replace('/editaInscricao/', ''))
    const [validaLote, setValidaLote] = useState(false)
    const [dataNascimento, setDataNascimento] = useState('')
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [usuarioProfessor, setUsuarioProfessor] = useState({})
    const [competidores, setCompetidores] = useState([])
    const [inscricoes, setInscricoes] = useState([]);

    // const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])
    const [equipe, setEquipe] = useState({})
    const [dadosEquipe, setDadosEquipe] = useState({});
    const [professor, setProfessor] = useState({})
    const [inputProfessor, setInputProfessor] = useState('')

    const [categorias, setCategorias] = useState([])
    const [listaAbsolutos, setListaAbsolutos] = useState([])
    const [validaAbsolutos, setValidaAbsolutos] = useState(false)
    const [absolutos, setAbsolutos] = useState([])
    const [absoluto, setAbsoluto] = useState([])
    const [pesos, setPesos] = useState([])
    const [faixas, setFaixas] = useState([])

    const [categoria, setCategoria] = useState({})
    const [peso, setPeso] = useState({})
    const [faixa, setFaixa] = useState({})

    const [escolheAbsoluto, setEscolheAbsoluto] = useState('')
    const [loading, setLoading] = useState(true)

    const [valorCategoria, setValorCategoria] = useState(0)
    const [valorAbsoluto, setValorAbsoluto] = useState(0)

    const [inscricao, setInscricao] = useState({})
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (Object.keys(dadosEquipe).length > 0) {
            setEquipe({ ...equipe, id: dadosEquipe.id, equipe: dadosEquipe.equipe })
        }
    }, [dadosEquipe])

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            // servicos.equipes.where('status', '==', 1).get().then((eq) => {
            //     const data = eq.docs.map((doc) => doc.data());
            //     const l = []
            //     const lFinal = []
            //     data.forEach((d) => {
            //         l.push(d.nome)
            //     })
            //     const array = [...new Set(l)]
            //     array.forEach((d) => {
            //         lFinal.push(data.filter((a) => a.nome === d)[0])
            //     })
            //     setEquipes(lFinal.sort((a, b) => {
            //         if (a.equipe < b.equipe)
            //             return -1;
            //         if (a.equipe > b.equipe)
            //             return 1;
            //         return 0;
            //     }))
            // })
            if (user) {
                setIdInscricao(id)
                servicos.inscricoes.doc(id).get().then((res) => {
                    servicos.eventos.doc(res.data().evento.id).get().then((snap) => {
                        setEvento(snap.data())
                        servicos.usuarios.doc(user.uid).get().then((doc) => {
                            if (doc.data().tipoUsuario === 'professor') {
                                setUsuarioProfessor(doc.data().id)
                                servicos.usuarios.where('equipe', '==', doc.data().equipe).get().then((res) => {
                                    const data = res.docs.map((d) => d.data());
                                    setCompetidores(data.filter((c) => c.id !== doc.data().id))
                                })
                            } else {
                                setUsuario(doc.data())
                                var date2 = moment(doc.data().dataNascimento).format('YYYY')
                                var date1 = moment(snap.data().inicioEvento).format('YYYY')

                                var data1 = moment(date1, 'YYYY');
                                var data2 = moment(date2, 'YYYY');
                                setDataNascimento(data1.diff(data2, 'years'))

                                // // Carrega Equipe
                                // servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                                //     setEquipe(e.data())
                                // })

                                // // Carrega Professor
                                // servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                                //     setProfessor(e.data())
                                // })

                                // Carrega Categoria
                                const lista = [];
                                servicos.inscricoes.doc(id).get().then(async (snap) => {
                                    setInscricao(snap.data())
                                    if (snap.data()?.equipe?.equipe) {
                                        setEquipe({
                                            id: snap.data()?.equipe?.equipe ? snap.data()?.equipe?.equipe : '',
                                            equipe: snap.data()?.equipe?.nomeEquipe ? snap.data()?.equipe?.nomeEquipe : '',
                                            avatar: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
                                        })

                                        const snapProfessores = await servicos.equipes.doc(snap.data().equipe.equipe).collection('professores').get()
                                        const dataProfessores = snapProfessores.docs.map((p) => p.data())
                                        setProfessores(dataProfessores)

                                        if (dataProfessores.some(prof => prof.id === snap.data()?.equipe?.professor)) {
                                            setProfessor({
                                                id: snap.data()?.equipe?.professor,
                                                professor: dataProfessores.filter((prof) => prof.id === snap.data()?.equipe?.professor)[0].professor
                                            })
                                        }

                                    }



                                    servicos.eventos.doc(snap.data().evento.id).collection('categorias').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                                        const data = snap.docs.map(d => d.data())
                                        data.forEach((c) => {
                                            if (data1.diff(data2, 'years') >= 18) {
                                                if (c.tipoIdade === 0) {
                                                    if (data1.diff(data2, 'years') <= c.idade) {
                                                        lista.push(c)
                                                    }
                                                } else if (c.tipoIdade === 1) {
                                                    if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                                        console.log(c.minIdade)
                                                        lista.push(c)
                                                    }
                                                } else {
                                                    if (data1.diff(data2, 'years') > c.idade) {
                                                        lista.push(c)
                                                    }
                                                }
                                            } else {
                                                if (c.tipoIdade === 0) {
                                                    if (data1.diff(data2, 'years') <= c.idade) {
                                                        lista.push(c)
                                                    }
                                                } else if (c.tipoIdade === 1) {
                                                    if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                                        lista.push(c)
                                                    }
                                                } else {
                                                    if (data1.diff(data2, 'years') > c.idade) {
                                                        lista.push(c)
                                                    }
                                                }
                                            }
                                        })
                                        setCategorias(lista);
                                    })

                                    // Carrega Absoluto
                                    servicos.eventos.doc(snap.data().evento.id).collection('absolutos').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                                        const data = snap.docs.map(d => d.data())
                                        setListaAbsolutos(data);
                                    })
                                    setLoading(false)
                                })
                            }

                        })

                        if (snap.data().inscricoesMultiplas && snap.data()?.inscricoesMultiplas === 1) {
                            servicos.inscricoes
                                .where('evento.id', '==', snap.data().id)
                                .where('usuario.id', '==', user.uid).get().then(snap => {
                                    const dados = snap.docs.map(d => d.data());
                                    setInscricoes(dados);
                                })
                        }

                    })
                })

            } else if (idUser) {
                servicos.inscricoes.doc(id).get().then((res) => {
                    servicos.eventos.doc(res.data().evento.id).get().then((snap) => {
                        setEvento(snap.data())
                        servicos.usuarios.doc(idUser).get().then((doc) => {
                            if (doc.data().tipoUsuario === 'professor') {
                                setUsuarioProfessor(doc.data().id)
                                servicos.usuarios.where('equipe', '==', doc.data().equipe).get().then((res) => {
                                    const data = res.docs.map((d) => d.data());
                                    setCompetidores(data.filter((c) => c.id !== doc.data().id))
                                })
                            } else {
                                setUsuario(doc.data())
                                var date2 = moment(doc.data().dataNascimento).format('YYYY')
                                var date1 = moment(snap.data().inicioEvento).format('YYYY')

                                var data1 = moment(date1, 'YYYY');
                                var data2 = moment(date2, 'YYYY');
                                setDataNascimento(data1.diff(data2, 'years'))

                                // Carrega Equipe
                                // servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                                //     setEquipe(e.data())
                                // })

                                // // Carrega Professor
                                // servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                                //     setProfessor(e.data())
                                // })

                                // Carrega Categoria
                                const lista = [];
                                servicos.inscricoes.doc(id).get().then(async (snap) => {
                                    setInscricao(snap.data())
                                    if (snap.data()?.equipe?.equipe) {
                                        setEquipe({
                                            id: snap.data()?.equipe?.equipe ? snap.data()?.equipe?.equipe : '',
                                            equipe: snap.data()?.equipe?.nomeEquipe ? snap.data()?.equipe?.nomeEquipe : '',
                                            avatar: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
                                        })

                                        const snapProfessores = await servicos.equipes.doc(snap.data().equipe.equipe).collection('professores').get()
                                        const dataProfessores = snapProfessores.docs.map((p) => p.data())
                                        setProfessores(dataProfessores)

                                        if (dataProfessores.some(prof => prof.id === snap.data()?.equipe?.professor)) {
                                            setProfessor({
                                                id: snap.data()?.equipe?.professor,
                                                professor: dataProfessores.filter((prof) => prof.id === snap.data()?.equipe?.professor)[0].professor
                                            })
                                        }

                                    }

                                    servicos.eventos.doc(snap.data().evento.id).collection('categorias').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                                        const data = snap.docs.map(d => d.data())
                                        data.forEach((c) => {
                                            if (data1.diff(data2, 'years') >= 18) {
                                                if (c.tipoIdade === 0) {
                                                    if (data1.diff(data2, 'years') <= c.idade) {
                                                        lista.push(c)
                                                    }
                                                } else if (c.tipoIdade === 1) {
                                                    if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                                        console.log(c.minIdade)
                                                        lista.push(c)
                                                    }
                                                } else {
                                                    if (data1.diff(data2, 'years') > c.idade) {
                                                        lista.push(c)
                                                    }
                                                }
                                            } else {
                                                if (c.tipoIdade === 0) {
                                                    if (data1.diff(data2, 'years') <= c.idade) {
                                                        lista.push(c)
                                                    }
                                                } else if (c.tipoIdade === 1) {
                                                    if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                                        lista.push(c)
                                                    }
                                                } else {
                                                    if (data1.diff(data2, 'years') > c.idade) {
                                                        lista.push(c)
                                                    }
                                                }
                                            }
                                        })
                                        setCategorias(lista);
                                    })

                                    // Carrega Absoluto
                                    servicos.eventos.doc(snap.data().evento.id).collection('absolutos').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                                        const data = snap.docs.map(d => d.data())
                                        setListaAbsolutos(data);
                                    })
                                    setLoading(false)
                                })
                            }

                        })

                        if (snap.data().inscricoesMultiplas && snap.data()?.inscricoesMultiplas === 1) {
                            servicos.inscricoes
                                .where('evento.id', '==', snap.data().id)
                                .where('usuario.id', '==', idUser).get().then(snap => {
                                    const dados = snap.docs.map(d => d.data());
                                    setInscricoes(dados);
                                })
                        }

                    })
                })

            }
        })
    }, [])

    const handleCategoria = (event) => {
        mensagemLoading("Carregando informações")
        setCategoria({})
        setAbsoluto({})
        setPeso({})
        setFaixa({})
        setAbsolutos([])
        setPesos([])
        setFaixas([])
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(inscricao.evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'categoria')
                .where('sexo', '==', inscricao.categoria.categoria.sexo)
                .where('categoria.id', '==', event.target.value)

                .onSnapshot((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValor = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                            if (total > -1) {
                                listaValor.push(doc.valor)
                            }
                        })
                        if (listaValor.length > 0) {
                            setValorAbsoluto(0)
                            setValorCategoria(Math.min(...listaValor))
                            setValidaLote(true)
                        } else {
                            if(!inscricao?.categoria?.absoluto?.id){
                                mensagemAtencao("O Lote de Inscrições já encerrou. Em caso de dúvidas entre em contato com a administração.")
                            }
                            
                        }
                    } else {
                        servicos.eventos.doc(inscricao.evento.id).collection('lotes')
                            .where('tipoCategoria', '==', 'categoria')
                            .where('sexo', '==', inscricao.categoria.categoria.sexo)
                            .where('geral', '==', true)
                            .onSnapshot((snap) => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(doc => doc.data())
                                    const listaValor = [];
                                    data.forEach((doc) => {
                                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                        var dt2 = new Date(doc.vencimento);
                                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                        var date2 = moment(doc.vencimento)
                                        var date1 = moment()
                                        console.log(total)
                                        if (total > -1) {
                                            listaValor.push(doc.valor)
                                        }
                                    })
                                    if (listaValor.length > 0) {
                                        setValorAbsoluto(0)
                                        setValorCategoria(Math.min(...listaValor))
                                        setValidaLote(true)
                                    }
                                    // else {
                                    //     mensagemErro("Lote vencido! Entre em contato com a administração2")
                                    // }
                                }
                            })
                    }
                })
            servicos.eventos.doc(evento.id).collection("categorias").doc(event.target.value).get().then((snap) => {
                Swal.close()
                setCategoria(snap.data())
            })
            servicos.eventos.doc(evento.id).collection("categorias").doc(event.target.value).collection("pesos").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setPesos(data)
            })
            servicos.eventos.doc(evento.id).collection("categorias").doc(event.target.value).collection("faixas").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFaixas(data)
            })
        }
    }

    const handleCompetidor = (event) => {
        setLoading(true)
        setUsuario({})
        event.persist();
        if (event.target.value !== "") {
            setUsuario(competidores[event.target.value])

            var date2 = competidores[event.target.value].dataNascimento
            var date1 = moment().format('DD/MM/YYYY')

            var data1 = moment(date1, 'DD/MM/YYYY');
            var data2 = moment(date2, 'DD/MM/YYYY');
            setDataNascimento(data1.diff(data2, 'years'))

            // // Carrega Equipe
            // servicos.equipes.doc(competidores[event.target.value].equipe).get().then((e) => {
            //     setEquipe(e.data())
            // })

            // // Carrega Professor
            // servicos.equipes.doc(competidores[event.target.value].equipe).collection('professores').doc(competidores[event.target.value].professor).get().then((e) => {
            //     setProfessor(e.data())
            // })

            // Carrega Categoria
            const lista = [];
            servicos.eventos.doc(evento.id).collection('categorias').where('sexo', '==', competidores[event.target.value].sexo).onSnapshot((snap) => {
                const data = snap.docs.map(d => d.data())
                data.forEach((c) => {
                    if (data1.diff(data2, 'years') >= 18) {
                        if (c.tipoIdade === 0) {
                            if (data1.diff(data2, 'years') <= c.idade) {
                                lista.push(c)
                            }
                        } else if (c.tipoIdade === 1) {
                            if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                console.log(c.minIdade)
                                lista.push(c)
                            }
                        } else {
                            if (data1.diff(data2, 'years') > c.idade) {
                                lista.push(c)
                            }
                        }
                    } else {
                        if (c.tipoIdade === 0) {
                            if (data1.diff(data2, 'years') <= c.idade) {
                                lista.push(c)
                            }
                        } else if (c.tipoIdade === 1) {
                            if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                lista.push(c)
                            }
                        } else {
                            if (data1.diff(data2, 'years') > c.idade) {
                                lista.push(c)
                            }
                        }
                    }
                })
                setCategorias(lista);
            })

            // Carrega Absoluto
            servicos.eventos.doc(evento.id).collection('absolutos').where('sexo', '==', competidores[event.target.value].sexo).onSnapshot((snap) => {
                const data = snap.docs.map(d => d.data())
                setListaAbsolutos(data);
            })
            setLoading(false)
        }
    }

    const handlePeso = (event) => {
        setPeso({})
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(evento.id).collection("categorias").doc(categoria.id).collection("pesos").doc(event.target.value).get().then((snap) => {
                setPeso(snap.data())
            })
        }
    }

    const handleFaixa = (event) => {
        mensagemLoading("Carregando informações")
        setFaixa({})
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(evento.id).collection("categorias").doc(categoria.id).collection("faixas").doc(event.target.value).get().then((snap) => {
                Swal.close()
                setFaixa(snap.data())
            })

            const lista = []
            listaAbsolutos.forEach((a) => {
                servicos.eventos.doc(inscricao.evento.id).collection("absolutos").doc(a.id)
                    .collection("idades")
                    .where("id", "==", categoria.id)
                    .get().then((i) => {
                        if (i.size > 0) {
                            servicos.eventos.doc(inscricao.evento.id).collection("absolutos").doc(a.id)
                                .collection("faixas")
                                .where("idCategoria", "==", categoria.id)
                                .where("idFaixa", "==", event.target.value)
                                .get().then((i) => {
                                    if (i.size > 0) {
                                        lista.push(a)
                                        setValidaAbsolutos(true)
                                    }
                                })
                        }
                    })
            })
            setAbsolutos(lista)
        }
    }

    const handleEscolheAbsoluto = (event) => {
        event.persist();
        if (inscricao.status < 2) {
            mensagemAlerta("Vocẽ só pode adicionar um absoluto após confirmação da sua inscrição na categoria Peso")
        } else {
            if (event.target.value !== "") {
                setEscolheAbsoluto(event.target.value)
                if (event.target.value === "0") {
                    setValorAbsoluto(0)
                    setAbsoluto({})
                }
            }
        }
    }

    const handleAbsoluto = (event) => {
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(inscricao.evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'absoluto')
                .where('sexo', '==', usuario.sexo)
                .where('categoria.id', '==', event.target.value)
                .onSnapshot((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValor = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                            if (total > -1) {
                                listaValor.push(doc.valor)
                            }
                        })
                        if (listaValor.length > 0) {
                            setValorAbsoluto(Math.min(...listaValor))
                            setValidaLote(true)
                        } else {
                            if(!inscricao?.categoria?.absoluto?.id){
                                mensagemAtencao("O Lote de Inscrições já encerrou. Em caso de dúvidas entre em contato com a administração.")
                            }
                        }
                    } else {
                        servicos.eventos.doc(inscricao.evento.id).collection('lotes')
                            .where('tipoCategoria', '==', 'absoluto')
                            .where('sexo', '==', usuario.sexo)
                            .where('geral', '==', true)
                            .onSnapshot((snap) => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(doc => doc.data())
                                    const listaValor = [];
                                    data.forEach((doc) => {
                                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                        var dt2 = new Date(doc.vencimento);
                                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                        if (total > -1) {
                                            listaValor.push(doc.valor)
                                        }
                                    })
                                    if (listaValor.length > 0) {
                                        setValorAbsoluto(Math.min(...listaValor))
                                        setValidaLote(true)
                                    } else {
                                        if(!inscricao?.categoria?.absoluto?.id){
                                            mensagemAtencao("O Lote de Inscrições já encerrou. Em caso de dúvidas entre em contato com a administração.")
                                        }
                                    }
                                }
                            })
                    }
                })
            servicos.eventos.doc(evento.id).collection("absolutos").doc(event.target.value).get().then((doc) => {
                setAbsoluto(doc.data())
            })
        }
    }



    function verificaInscricao() {

        if (!equipe.id) {
            mensagemAlerta('Por favor escolha a equipe que você irá lutar neste evento')
            return
        }

        if (equipe.id && !professor.id && inputProfessor.trim() === '') {
            mensagemAlerta('Por favor informe seu professor')
            return
        }

        if (equipe.id && professores.length === 0) {
            if (!inputProfessor) {
                mensagemAlerta('Por favor digite o nome do seu professor')
                return
            }
        }
        if (equipe.id && professores.length > 0) {
            if (!professor.id) {
                mensagemAlerta('Por favor informe seu professor')
                return
            } else if (professor.id === "nao" && !inputProfessor) {
                mensagemAlerta('Por favor digite o nome do seu professor')
                return
            }
        }


        if (inscricoes.length > 1 && Object.keys(peso).length > 0) {

            // posso alterar se o peso na lista de inscrições for o peso mesmo selecionado

            const outrosPesosInscritos = inscricoes.filter(insc => insc.categoria.peso.id !== inscricao.categoria.peso.id);

            const pesoInscrito = outrosPesosInscritos.some(insc => insc.categoria.peso.id === peso.id);
            
            const outrasIdadesInscritos = inscricoes.filter(insc => insc.categoria.idade.id !== inscricao.categoria.idade.id);

            const idadeInscrito = outrasIdadesInscritos.some(insc => insc.categoria.idade.id === categoria.id);

            if (pesoInscrito) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria de peso.');
                return;
            }
            if (idadeInscrito) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria de idade.');
                return;
            }


            const outrosAbsolutosInscritos = inscricoes
                .filter(insc =>
                    Object.keys(insc.categoria.absoluto).length > 0
                    && insc.categoria.absoluto.id !== inscricao.categoria.absoluto.id)
                .map(insc => insc.categoria.absoluto);

            if (outrosAbsolutosInscritos.some(abs => abs.id === absoluto.id)) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria Absoluto');
                return;
            }

        }

        if (!categoria.id) {
            mensagemAlerta('Favor escolha sua categoria')
        } else if (!peso.id) {
            mensagemAlerta('Favor escolha a categoria de pesos')
        } else if (!faixa.id) {
            mensagemAlerta('Favor escolha a categoria de faixas')
        } else {
            if (escolheAbsoluto === '1' && !inscricao.categoria.absoluto.id) {
                if (!absoluto.id) {
                    mensagemAlerta('Favor escolha a categoria absoluto')
                } else {
                    Swal.fire({
                        title: 'Deseja gravar seus dados?',
                        text: 'Ao confirmar você estará ciente que sua inscrição será alterada e você terá que realizar o pagagmento para o ABSOLUTO',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            verificaEquipe()
                        }
                    })
                }
            } else {
                Swal.fire({
                    title: 'Deseja gravar seus dados?',
                    text: 'Ao confirmar você estará ciente que sua inscrição será alterada ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Continuar Editando'
                }).then((result) => {
                    if (result.value) {
                        verificaEquipe()
                    }
                })
            }
        }

    }

    function verificaEquipe() {
        // if (!equipe.id) {
        //     mensagemAlerta('Por favor escolha a equipe que você irá lutar neste evento')
        //     return
        // }
        // if (equipe.id && professores.length === 0) {
        //     if (!inputProfessor) {
        //         mensagemAlerta('Por favor digite o nome do seu professor')
        //         return
        //     }
        // }
        // if (equipe.id && professores.length > 0) {
        //     if (!professor.id) {
        //         mensagemAlerta('Por favor selecione seu professor')
        //         return
        //     } else if (professor.id === "nao" && !inputProfessor) {
        //         mensagemAlerta('Por favor digite o nome do seu professor')
        //         return
        //     }
        // }
        mensagemLoading('Verificando Equipe e Professor')
        if (inputProfessor) {
            servicos.equipes.doc(equipe.id).collection('professores').add({
                professor: inputProfessor
            }).then((docRef) => {
                servicos.equipes.doc(equipe.id).collection('professores').doc(docRef.id).update({
                    id: docRef.id
                }).then(() => {
                    cadastraInscricao(equipe.id, equipe.equipe, docRef.id, inputProfessor)
                })
            })
        } else {
            cadastraInscricao(equipe.id, equipe.equipe, professor.id, professor.professor)
        }
    }

    async function cadastraInscricao(idEquipe, nomeEquipe, idProfessor, nomeProfessor) {

        // Atualizar  Equipe/Professor do Competidor
        if (usuario.equipe !== dadosEquipe.id || usuario.professor !== professor.id) {
            let update = {}
            if (usuario.equipe !== dadosEquipe.id) {
                update = { ...update, equipe: idEquipe }
            }
            if (usuario.professor !== professor.id) {
                update = { ...update, professor: idProfessor }
            }
            await servicos.usuarios.doc(usuario.id).update(update);
        }

        mensagemLoading('Aguarde salvando dados da inscrição...')
        const idChecagem = categoria.sexo + categoria.id + peso.id + faixa.id
        servicos.inscricoes.doc(id).update({
            categoria: {
                categoria: categoria,
                peso: peso,
                faixa: faixa,
                idade: categoria,
                absoluto: absoluto.id ? absoluto : inscricao.categoria.absoluto,
            },
            equipe: {
                equipe: idEquipe,
                professor: idProfessor,
                nomeEquipe: nomeEquipe,
                nomeProfessor: nomeProfessor,
                avatar: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
            },
            dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
            posicaoChave: null,
            checagem: idChecagem,
            pagamentoAbsoluto: {
                pedido: null,
                pagamento: null,
                status: absoluto.id && !inscricao.categoria.absoluto.id ? 'pending' : null,
                tipoPagamento: null,
                desconto: 0,
            },
            valorAbsoluto: absoluto.id ? valorAbsoluto : inscricao.valorAbsoluto,
            statusAbsoluto: absoluto.id && !inscricao.categoria.absoluto.id ? parseInt(1) : inscricao.statusAbsoluto,
            // valorTotal: absoluto.id ? parseFloat(inscricao.valorCategoria) + parseFloat(valorAbsoluto) : inscricao.valorTotal
        }).then(() => {
            mensagemSucesso('Inscrição atualizada com sucesso!!')
        }).catch(() => {
            mensagemErro('Erro ao atualizar a inscrição! Tente novamente mais tarde')
        })
    }

    function mensagemAtencao(msg) {
        Swal.fire('Atenção', msg, 'warning')
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            if (absoluto.id && !inscricao.categoria.absoluto.id) {
                // window.location = window.location.href.replace('/editaInscricao/', '/pagamentoAbsoluto/')
                window.location = window.location.href = `/pagamentoAbsoluto?e=${evento.id}&i=${inscricao.id}`
            } else {
                window.location = window.location.href.replace('/editaInscricao/' + inscricao.id, '/minhaInscricao/' + inscricao.id)
            }

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    // const handleEquipe = (event) => {
    //     setInputProfessor("")
    //     setProfessor({})
    //     setProfessores([])
    //     event.persist();
    //     setEquipe(equipes[event.target.value]);
    //     mensagemLoading('Buscando professores')
    //     servicos.equipes.doc(equipes[event.target.value].id).collection('professores').get().then((snap) => {
    //         Swal.close()
    //         const data = snap.docs.map((d) => d.data())
    //         setProfessores(data)
    //     })
    // }
    const handleProfessor = (event) => {
        setInputProfessor("")
        event.persist();
        if (event.target.value === "nao") {
            setProfessor({
                id: "nao"
            })
        } else {
            setProfessor(professores[event.target.value])
        }

    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value)
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Edite sua Inscrição</h1>
                        </div>
                        <div class="headline__description">
                            <span>Abaixo edite sua inscrição</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class=" vc_col-sm-6">
                                            <div class="wpb_single_image wpb_content_element vc_align_left">
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                        <img width="1920" height="1401"
                                                            src={evento.avatar}
                                                            class="vc_single_image-img attachment-full" alt=""
                                                            srcset={evento.avatar}
                                                            sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                                </figure>
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={usuario.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                        <div class="package-style-1__text">
                                                            <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                            <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                        </div>
                                                    </div>
                                                </figure>
                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                    <h3>{evento.nome}</h3>
                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                        <h3>Inscrição atual</h3>
                                                        {inscricao.categoria ?
                                                            <table style={{ width: "100%" }}>
                                                                <thead>
                                                                    <tr >
                                                                        <th class="schedule-table__day">Categoria</th>
                                                                        <th class="schedule-table__day">Peso</th>
                                                                        <th class="schedule-table__day">Faixa</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                        <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                        <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            :
                                                            null
                                                        }
                                                        {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                            <table style={{ width: "100%", marginTop: 20 }}>
                                                                <thead>
                                                                    <tr >
                                                                        <th class="schedule-table__day">Absoluto</th>
                                                                        <th class="schedule-table__day">Idade</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                        <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            :
                                                            null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                <h3>Dados Pessoais</h3>
                                                            </div>
                                                            {usuarioProfessor.id ?
                                                                <p class="contact-form-name vc_col-sm-12">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Escolha seu commpetidor</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleCompetidor} >
                                                                            <option value="">Escolha seu aluno </option>
                                                                            {competidores.map((c, index) =>
                                                                                <option value={index} >{c.nome} - {c.cpf}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                :
                                                                null
                                                            }
                                                            <div role="form" class="row " >
                                                                <p class="contact-form-name vc_col-sm-5">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Nome</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.nome + " " + usuario.sobrenome}
                                                                            disabled
                                                                            placeholder="Nome *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>CPF</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.cpf}
                                                                            disabled
                                                                            placeholder="CPF *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sexo</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.sexo}
                                                                            disabled
                                                                            placeholder="Sexo *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Idade</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={dataNascimento + " anos"}
                                                                            disabled
                                                                            placeholder="Idade *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-2">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>DDD</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.ddd}
                                                                            disabled
                                                                            placeholder="DDD *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Celular</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.celular}
                                                                            disabled
                                                                            placeholder="Celular *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Tipo</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            // value={usuario.tipoUsuario.toUpperCase()}
                                                                            disabled
                                                                            placeholder="Tipo *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Equipe</h6>
                                                                        <input type='text' name="select"
                                                                            class="form-control"
                                                                            value={equipe?.equipe ? equipe.equipe : ''}
                                                                            placeholder='Selecione uma equipe'
                                                                            autoComplete='no-fill'
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => setModalShow(true)}
                                                                        />
                                                                        {/* <select name="select" id="select" class="form-control" onChange={handleEquipe} >
                                                                            <option value="">Escolha a Equipe </option>
                                                                            {equipes.sort((a, b) => {
                                                                                if (a.equipe < b.equipe)
                                                                                    return -1;
                                                                                if (a.equipe > b.equipe)
                                                                                    return 1;
                                                                                return 0;
                                                                            }).map((eq, index) =>
                                                                                <option value={index} selected={equipe && equipe.id === eq.id ? "selected" : null} >{eq.equipe.toUpperCase()}</option>
                                                                            )}
                                                                        </select> */}
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Seu Professor</h6>
                                                                        {equipe.id && professores.length === 0 ?
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                value={inputProfessor}
                                                                                onChange={handleInputProfessor}
                                                                                placeholder="Nome do Professor *" />
                                                                            :
                                                                            <select name="select" id="select" class="form-control" onChange={handleProfessor} >
                                                                                <option value="">Escolha seu Professor </option>
                                                                                <option value="nao">Não está na lista </option>
                                                                                {professores.sort((a, b) => {
                                                                                    if (a.professor < b.professor)
                                                                                        return -1;
                                                                                    if (a.professor > b.professor)
                                                                                        return 1;
                                                                                }).map((eq, index) =>
                                                                                    <option value={index} selected={professor && professor.id === eq.id ? "selected" : null}>{eq.professor.toUpperCase()}</option>
                                                                                )}
                                                                            </select>
                                                                        }
                                                                    </span>
                                                                </p>
                                                                {professor.id === "nao" ?
                                                                    <p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Nome Professor</h6>
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                value={inputProfessor}
                                                                                onChange={handleInputProfessor}
                                                                                placeholder="Nome do Professor *" />
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="vc_column-inner">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                <h3>Dados da Inscrição</h3>
                                                                <p><b>Selecione abaixo a Categoria, Peso, Faixa ou absoluto para editar sua inscrição</b></p>
                                                            </div>
                                                            <div role="form" class="row " >
                                                                <p class="contact-form-name vc_col-sm-12">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Categoria</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleCategoria} >
                                                                            <option value="">Escolha a categoria </option>
                                                                            {categorias.sort((a, b) => {
                                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                                            }).map((c) =>
                                                                                <option value={c.id} >CATEGORIA {c.categoria.toUpperCase()} - {c.tipoIdade === 0 ? "ATÉ " + c.idade + " ANOS" : c.tipoIdade === 1 ? "DE " + c.minIdade + " ATÉ " + c.maxIdade + " ANOS" : "ACIMA DE " + c.idade}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Peso</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handlePeso} >
                                                                            <option value="">Escolha o peso </option>
                                                                            {pesos.sort((a, b) => {
                                                                                return a.peso - b.peso
                                                                            }).map((c) =>
                                                                                <option value={c.id} >{c.nome.toUpperCase()} - {c.tipoPeso === 0 ? "ATÉ " + c.peso + " KG" : "ACIMA " + c.peso + " KG"}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Faixa</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleFaixa} >
                                                                            <option value="">Escolha a faixa </option>
                                                                            {faixas.sort((a, b) => {
                                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                                            }).map((f) =>
                                                                                <option value={f.id} >{f.faixa.toUpperCase()}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                {faixa.id && validaAbsolutos ?
                                                                    <p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Deseja a Categoria Absoluto?</h6>
                                                                            <select name="select" id="select" class="form-control" onChange={handleEscolheAbsoluto} >
                                                                                <option value="">Escolha abaixo </option>
                                                                                <option value="1">SIM </option>
                                                                                <option value="0">NÃO </option>
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {escolheAbsoluto === "1" ?
                                                                    <p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Escolha o Absoluto</h6>
                                                                            <select name="select" id="select" class="form-control" onChange={handleAbsoluto} >
                                                                                <option value="">Escolha abaixo </option>
                                                                                {absolutos.map((f) =>
                                                                                    <option value={f.id} >{f.absoluto.toUpperCase()}</option>
                                                                                )}
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                <table style={{ width: "100%" }}>
                                                                    <thead>
                                                                        <tr >
                                                                            <th class="schedule-table__day">Categoria</th>
                                                                            <th class="schedule-table__day">Peso</th>
                                                                            <th class="schedule-table__day">Faixa</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ height: 50 }}><h6>{categoria ? categoria.categoria : null}</h6></td>
                                                                            {peso.id ?
                                                                                <td><h6>{peso.tipoPeso === 0 ? "Até " + peso.peso + " Kg" : "Acima " + peso.peso + " Kg"}</h6></td>
                                                                                :
                                                                                <td></td>
                                                                            }
                                                                            {faixa.id ?
                                                                                <td><h6>{faixa.faixa}</h6></td>
                                                                                :
                                                                                <td></td>
                                                                            }
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                {absoluto.id ?
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Absoluto</th>
                                                                                <th class="schedule-table__day">Idade</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6>{absoluto.absoluto.toUpperCase()}</h6></td>
                                                                                <td><h6>{categoria.tipoIdade === 0 ? "Até " + categoria.idade + " anos" : categoria.tipoIdade === 1 ? "De " + categoria.minIdade + " até " + categoria.maxIdade + " anos" : "Acima de " + categoria.idade}</h6></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    null
                                                                }
                                                                {inscricao.id && !inscricao.categoria.absoluto.id && absoluto.id ?
                                                                    <>
                                                                        <p style={{ marginTop: 20 }}><b>Você está adicionando uma categoria absoluto. Com isso será cobrado o valor referente ao absoluto. </b></p>
                                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Extrato</th>
                                                                                </tr>
                                                                            </thead>
                                                                        </table>
                                                                        <table style={{ width: "100%" }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                                    <td>R$ {valorAbsoluto}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        {validaLote ?
                                                                            <div onClick={() => verificaInscricao()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                <input type="submit" value="Salvar e ir para pagamento" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {categoria.id && peso.id && faixa.id ?
                                                                            <div onClick={() => verificaInscricao()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                <input type="submit" value="Atualizar Inscrição" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
            {modalShow && (
                <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={() => { }} setDadosEquipe={setDadosEquipe} setProfessores={setProfessores}
                    setDadosProfessor={setProfessor}
                />
            )}
        </div>
    );
}
