import moment from "moment";
import firebase from 'firebase'
import Swal from "sweetalert2";


export const useUtilitarios = () => {

  const atualizarStatusFiliacao = async (idFederacao, usuarios) => {

    const mensagemLoading = (msg) => {
      Swal.fire({
        title: 'Aguarde',
        html: msg,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        },
      })
    }

    mensagemLoading('carregando dados...')

    // Atualizar o status da filiação quando passado do vencimeto

    const aprovado = 2;
    const vencido = 4;
    const filiacaoPaga = '2'
    const anuidade = 2

    const firebaseCollectionsRefferences = {
      federacoes: firebase.firestore().collection('federacoes'),
      filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes')
    }

    let federacao = {}

    const snapFederacao = await firebaseCollectionsRefferences.federacoes.doc(idFederacao).get()

    if (snapFederacao.exists) {
      federacao = snapFederacao.data()
    }

    const converterData = (data) => {
      return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('DD-MM-YYYY HH:mm')
    }

    if (federacao?.regrasFiliacao?.tipoPagamento === filiacaoPaga) {
      if (federacao?.regrasFiliacao?.tipoFiliacao === anuidade) {

        let usuariosAlterados = 0

        for (let usuario of usuarios) {

          if (usuario?.filiacoes?.length > 0) {
            if (usuario?.filiacoes.includes(idFederacao)) {

              const snapFiliacao = await firebaseCollectionsRefferences.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', usuario.id).where('tipoUsuario', '==', usuario.tipoUsuario).get();

              if (!snapFiliacao.empty) {
                const filiacao = snapFiliacao.docs.map(d => d.data())[0];

                if (filiacao?.statusFiliacao === aprovado) {

                  const snapPagamentos = await firebaseCollectionsRefferences.filiacoes.doc(filiacao.id).collection('pagamentos').get()

                  const pagamentos = snapPagamentos.docs.map(d => d.data())

                  if (pagamentos.length > 0) {

                    const ultimoPagamento = pagamentos.sort((a, b) => b.dtAnexo - a.dtAnexo)[0]

                    if (ultimoPagamento?.status === aprovado) {

                      const diasPassados = moment(new Date()).diff(moment(converterData(ultimoPagamento.dtAprovado), 'DD-MM-YYYY HH:mm'), 'days')

                      if (diasPassados > 365) {

                        let dados = {
                          statusFiliacao: vencido,
                          statusImagemFoto: vencido,
                          statusImagemRg: vencido
                        }

                        if (filiacao.opcaoEntregaSelecionado) {
                          dados = {
                            ...dados,
                            opcaoEntregaSelecionado: ''
                          }
                        }

                        await firebaseCollectionsRefferences.filiacoes.doc(filiacao.id).update(dados)

                        usuariosAlterados++;

                      }
                    }
                  }

                }

              }

            }
          }

        };

        // se houver altum usuário alterado, atualizar a página para que a informação seja atualizada.

        if (usuariosAlterados > 0) {
          window.location.reload()
        }

      }
    }

  Swal.close()

  }

  const verificaCampoDisponivel = async (item, comparacao, collectionRefference) => {

    // validar se um dado campo (ex: CPF ou Email) está disponível ou já existe cadastrado.

    try {
      const snap = await collectionRefference.where(item, '==', comparacao).get();
      if (snap.empty) {
        return true
      } else if (!snap.empty) {
        return false
      }
    } catch (error) {
      console.log(error);
    }
  }

  return { atualizarStatusFiliacao, verificaCampoDisponivel }
}