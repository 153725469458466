import {
    useState, useEffect
} from 'react'
import base64 from 'react-native-base64'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { SettingsApplications } from '@material-ui/icons'

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios")
    })

    const [usuario, setUsuario] = useState({})
    const [cpf, setCpf] = useState('')
    const [validaSenha, setValidaSenha] = useState(false)
    const [validaEmail, setValidaEmail] = useState(false)

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')
    const [dica, setDica] = useState('')

    function buscaUsuario() {
        if (!cpf) {
            mensagemAlerta('Favor digite seu CPF')
            return
        }
        if (cpf.length < 11) {
            mensagemAlerta('CPF incompleto')
            return
        }
        mensagemLoading('Buscando Email...')
        servicos.usuarios.where('cpf', '==', cpf.replace(/[^a-z0-9]/gi, '')).get().then((snap) => {
            if (snap.size === 0) {
                servicos.usuarios.where('cpf', '==', cpf).get().then((snapCpf) => {
                    if (snapCpf.size === 0) {
                        Swal.close();
                        mensagemAlerta('CPF não consta em nosso sistema! Verifique novamente ou entre em contato com a Administração')
                    } else {
                        const email = snapCpf.docs.map((d) => d.data())[0].email
                        const pass = snapCpf.docs.map((d) => d.data())[0].pass
                        const id = snapCpf.docs.map((d) => d.data())[0].id
                        if (pass) {
                            // login(email, base64.decode(pass).replace(id, ''))
                            localStorage.setItem('usuarioId', JSON.stringify(id))
                            window.location = '/'
                        } else {
                            localStorage.setItem('usuarioId', JSON.stringify(id))
                            window.location = '/'
                        }
                        // login(email, base64.decode(pass).replace(id,''))
                    }
                })
            } else {
                const email = snap.docs.map((d) => d.data())[0].email
                const pass = snap.docs.map((d) => d.data())[0].pass
                const id = snap.docs.map((d) => d.data())[0].id
                if (pass) {
                    // login(email, base64.decode(pass).replace(id, ''))
                    localStorage.setItem('usuarioId', JSON.stringify(id))
                    window.location = '/'
                } else {
                    localStorage.setItem('usuarioId', JSON.stringify(id))
                    window.location = '/'
                }
                // login(email, base64.decode(pass).replace(id,''))
            }
        })
    }

    function login(email, senha) {
        servicos.auth.signInWithEmailAndPassword(email, senha).then(() => {
            servicos.auth.onAuthStateChanged((usuario) => {
                servicos.usuarios.doc(usuario.uid).get().then((doc) => {
                    setValidaSenha(true)
                    setUsuario(doc.data())
                    Swal.close();
                })
            });
        }).catch((error) => {
            var errorCode = error.code;
            if (error) {
                console.log(errorCode)
                Swal.close();
                if (errorCode === 'auth/invalid-email') {
                    mensagemAlerta('Email incompleto ou não é válido')
                } else if (errorCode === 'auth/user-not-found') {
                    mensagemAlerta('Email não cadastrado em nosso Sistema! Cadastre-se ou verifique novamente')
                } else if (errorCode === 'auth/wrong-password') {
                    mensagemAlerta('Senha Incorreta')
                }
            }
        });
    }

    function recuperaEmail() {
        if (!email) {
            mensagemAlerta('Digite o email')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite a Senha')
            return
        }
        if (!confirmaSenha) {
            mensagemAlerta('Confirme sua Senha')
            return
        }
        if (confirmaSenha !== senha) {
            mensagemAlerta('As sehas não são iguais')
            return
        }
        if (email.toLocaleLowerCase() === usuario.email.toLocaleLowerCase()) {
            mudaSenha()
        } else {
            servicos.usuarios.where('email', '==', email).get().then((snap) => {
                if (snap.size > 0) {
                    mensagemAlerta('Email já consta em nossa base')
                } else {
                    mensagemLoading('Alterando seu Email...')
                    servicos.auth.currentUser.updateEmail(email).then((res) => {
                        servicos.usuarios.doc(usuario.id).update({
                            email: email
                        }).then(() => {
                            mudaSenha();
                        }).catch(() => {
                            mensagemErro('Erro ao atualizar perfil')
                        })
                    }).catch((erro) => {
                        Swal.close();
                        var errorCode = erro.code;
                        if (errorCode == "auth/invalid-email") {
                            mensagemAlerta('O formato do Email é inválido!')
                        } else if (errorCode == "auth/email-already-in-use") {
                            mensagemAlerta('O email já está em uso pelo Procompetidor')
                        } else if (errorCode == "auth/requires-recent-login") {
                            mensagemAlerta('Por questões de segurança para alterar o email é preciso realizar novamente o Login!')
                        }
                    })
                }
            })
        }
    }

    function mudaSenha() {
        mensagemLoading('Alterando sua senha...')
        servicos.auth.currentUser.updatePassword(confirmaSenha).then(() => {
            servicos.usuarios.doc(usuario.id).update({
                pass: base64.encode(confirmaSenha + usuario.id)
            }).then(() => {
                mensagemSucesso('Suas credenciais foram atualizadas com sucesso!')
            }).catch(() => {
                mensagemErro('Erro ao alterar a senha')
            })
        }).catch((erro) => {
            Swal.close();
            var errorCode = erro.code;
            if (errorCode == "auth/requires-recent-login") {
                mensagemAlerta('Por questões de segurança para alterar a senha é preciso realizar novamente o Login!')
            }
        })
    }

    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/'
        })
    }
    function mensagemSucessoEmail(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />

                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class=" vc_col-sm-8">
                                                <div class="wpb_single_image wpb_content_element vc_align_left">

                                                    <figure class="wpb_wrapper vc_figure">
                                                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                            <img width="1920" height="1401"
                                                                src="https://www.kindpng.com/picc/m/88-885090_brazilian-jiu-jitsu-png-transparent-png.png"
                                                                class="vc_single_image-img attachment-full" alt=""
                                                                srcset="https://www.kindpng.com/picc/m/88-885090_brazilian-jiu-jitsu-png-transparent-png.png 1920w, https://www.kindpng.com/picc/m/88-885090_brazilian-jiu-jitsu-png-transparent-png.png 300w, https://www.kindpng.com/picc/m/88-885090_brazilian-jiu-jitsu-png-transparent-png.png 1024w, https://www.kindpng.com/picc/m/88-885090_brazilian-jiu-jitsu-png-transparent-png.png 768w, https://www.kindpng.com/picc/m/88-885090_brazilian-jiu-jitsu-png-transparent-png.png 1536w"
                                                                sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                                    </figure>
                                                </div>
                                            </div>
                                            {validaSenha ?
                                                <div class="wpb_column vc_column_container  vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="wpb_widgetised_column wpb_content_element">
                                                                <div class="wpb_wrapper">
                                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                        <h2>Olá {usuario.nome}</h2>
                                                                        <p>Seu email atual é <b>{usuario.email}</b></p>
                                                                    </div>
                                                                    <p>Por segurança, realize os passos abaixo</p>
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleEmail}
                                                                        value={email}
                                                                        placeholder="Confirme ou mude seu Email" />
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleSenha}
                                                                        value={senha}
                                                                        placeholder="Nova senha" />
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleConfirmaSenha}
                                                                        value={confirmaSenha}
                                                                        placeholder="Confirma a nova Senha" />
                                                                    <div onClick={() => recuperaEmail()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                        <input type="submit" value="Alterar minhas credenciais" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div class="wpb_column vc_column_container  vc_col-sm-4">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="wpb_widgetised_column wpb_content_element">
                                                                <div class="wpb_wrapper">
                                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                        <h2>login por CPF</h2>
                                                                    </div>
                                                                    <p>Digite seu seu CPF para que seja realizado login</p>
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleCpf}
                                                                        value={cpf}
                                                                        placeholder="CPF" />
                                                                    <div onClick={() => buscaUsuario()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                        <input type="submit" value="Login" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                    </div>
                                                                    <a href="/" onClick={() => setValidaEmail(false)} style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Ir para tela Inicial</h6>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
